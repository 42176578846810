import { useTranslation } from 'react-i18next';

import {
  MemoryRetrievalStatusType,
  MicrobotMemoryInfoFragment,
  MicrobotMemoryProcessingStatus,
} from '@advisor/api/generated/graphqlTypes';
import {
  isUrlMemory,
  isFileMemory,
  isCrawlMemory,
} from '../components/MemoryListItem';

const useMemoryFailedMessage = (memory: MicrobotMemoryInfoFragment) => {
  const { t } = useTranslation('microbot');

  if (!isFileMemory(memory) && !isUrlMemory(memory)) {
    return null;
  }

  const isFailedMemory = (() => {
    if (isUrlMemory(memory) || isCrawlMemory(memory)) {
      return (
        memory.lastRetrieval?.status === MemoryRetrievalStatusType.Error ||
        memory.status === MicrobotMemoryProcessingStatus.Failed
      );
    }

    return memory.status === MicrobotMemoryProcessingStatus.Failed;
  })();

  if (!isFailedMemory) {
    return null;
  }

  if (isFileMemory(memory)) {
    return t('upload-failed-please-check-the-file-format-and-try-again');
  }

  if (isUrlMemory(memory) || isCrawlMemory(memory)) {
    return t('oops-the-link-seems-to-be-broken');
  }

  return null;
};

export default useMemoryFailedMessage;

import React from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { useEvent } from '@advisor/utils/hooks';
import { useMemorySets } from '@advisor/microbots/api';
import withSeparators from '@advisor/utils/withSeparators';
import { Layout, SegmentedGroup } from '@advisor/design/components';
import { useUpdateMemorySetMutation } from '@advisor/api/generated/graphql';

import SideModal from '../SideModal';

const MemorySetsSubscriberModal: React.FC = () => {
  const { t } = useTranslation('microbot');

  const { agencyName: urlEncodedAgencyName } = useParams<{
    agencyName: string;
  }>();

  const agencyName = decodeURIComponent(urlEncodedAgencyName ?? '');

  const { memorySets } = useMemorySets();

  const [editMemorySet] = useUpdateMemorySetMutation();

  const onToggleMemorySet = useEvent(
    (memorySetId: string) => async (enableSet: boolean) => {
      const memorySet = memorySets.memorySetMap[memorySetId];
      let subscribedAgencies: string[] = [...memorySet.subscribedAgencies];

      if (enableSet) {
        if (!subscribedAgencies.includes(agencyName)) {
          subscribedAgencies = [...subscribedAgencies, agencyName];
        }
      } else {
        subscribedAgencies = memorySet.subscribedAgencies.filter(
          (agency) => agency !== agencyName,
        );
      }

      await editMemorySet({
        variables: {
          ...memorySet,
          subscribedAgencies,
        },
      });
    },
  );

  const renderMemorySetToggle = (memorySetId: string) => {
    const memorySet = memorySets.memorySetMap[memorySetId];

    const isSubscribed = memorySet.subscribedAgencies.includes(agencyName);

    return (
      <SegmentedGroup.ToggleButton
        variant="dark"
        key={memorySetId}
        enabled={isSubscribed}
        title={memorySet.title}
        onToggle={onToggleMemorySet(memorySetId)}
      />
    );
  };

  return (
    <SideModal.Default
      scroll
      backButton
      title={t('agency-subscription', { agency: agencyName })}
    >
      <p className="text-sm font-medium text-dark-grey-03">
        {t('enable-subscription-access-to-the-following-memory-sets')}
      </p>
      <Layout.Spacer.Vertical size="small01" />
      <Layout.Heading title={t('memory-set-subscriptions')} />
      <Layout.Spacer.Vertical size="micro02" />
      <SegmentedGroup.Root>
        {withSeparators(
          memorySets.activeSetIds,
          (index) => (
            <SegmentedGroup.Separator key={index} size="micro" />
          ),
          renderMemorySetToggle,
        )}
      </SegmentedGroup.Root>
    </SideModal.Default>
  );
};

export default MemorySetsSubscriberModal;

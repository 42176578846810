import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import cs from 'classnames';

import { useTheme } from '@advisor/design/Theme';
import { formatBytes } from '@advisor/api/files';
import { DeprecatedButton, Icon, Skeleton } from '@advisor/design/components';
import { MemoryRetrievalType } from '@advisor/api/generated/graphqlTypes';
import { useIsMemoryProcessing, useMemoryFailedMessage } from '../../hooks';
import {
  UrlMemoryProps,
  FileMemoryProps,
  TextMemoryProps,
  CrawlMemoryProps,
  UrlMemoryContentWithTitleProps,
  UrlMemoryContentWithoutTitleProps,
} from './types';
import { truncate } from './utils';

const MemoryProcessingContent: React.FC = () => {
  const theme = useTheme();

  return (
    <>
      <Skeleton.Ping>
        <Skeleton.Text
          fontSize={14}
          lineHeight={20}
          size={84}
          color={theme.colors.primary}
        />
      </Skeleton.Ping>
      <Skeleton.Ping>
        <Skeleton.Text
          fontSize={14}
          lineHeight={20}
          color={theme.colors.primary}
        />
      </Skeleton.Ping>
    </>
  );
};

export const TextMemory: React.FC<TextMemoryProps> = (props) => {
  const { memory } = props;

  const { t } = useTranslation('common');

  const [isExpanded, setIsExpanded] = useState(false);
  const { showButton, truncated } = truncate(memory.value);
  const isProcessing = useIsMemoryProcessing(memory);

  if (isProcessing) {
    return <MemoryProcessingContent />;
  }

  return (
    <div className="font-outfit text-xs text-dark-grey-025 break-words">
      {showButton && !isExpanded ? truncated : memory.value}
      {showButton && (
        <>
          &nbsp; {/* inline spacing between the ellipsis and the button */}
          <DeprecatedButton.Text
            underline
            size="xs"
            onPress={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? t('show-less') : t('show-more')}
          </DeprecatedButton.Text>
        </>
      )}
    </div>
  );
};

const UrlMemoryContentWithTitle: React.FC<UrlMemoryContentWithTitleProps> = (
  props,
) => {
  const { url, previewTitle, previewDescription, isFailedMemory } = props;

  return (
    <>
      <h5 className="font-outfit text-sm leading-4-5 font-normal">
        <a
          className={cs(
            'line-clamp-1 break-words',
            isFailedMemory ? 'text-dark-grey-01' : 'text-primary',
          )}
          href={url}
          rel="noreferrer noopener"
          target="_blank"
        >
          {previewTitle}
        </a>
        {!!previewDescription && (
          <p
            className={cs(
              'line-clamp-1 break-words',
              isFailedMemory ? 'text-dark-grey-01' : 'text-dark-grey-025',
            )}
          >
            {previewDescription}
          </p>
        )}
      </h5>
    </>
  );
};

const UrlMemoryContentWithoutTitle: React.FC<
  UrlMemoryContentWithoutTitleProps
> = (props) => {
  const { url, isFailedMemory } = props;

  return (
    <h5>
      <a
        className={cs(
          'font-outfit font-medium text-sm leading-4-5 line-clamp-2 break-all',
          isFailedMemory ? 'text-dark-grey-01' : 'text-primary',
        )}
        href={url}
        rel="noreferrer noopener"
        target="_blank"
      >
        {url}
      </a>
    </h5>
  );
};

export const UrlMemory: React.FC<UrlMemoryProps> = (props) => {
  const { memory } = props;

  const { t } = useTranslation('microbot');

  const isProcessing = useIsMemoryProcessing(memory);
  const isFailedMemory = !!useMemoryFailedMessage(memory);

  const memoryContent = useMemo(() => {
    if (isProcessing) {
      return <MemoryProcessingContent />;
    }

    if (memory.previewTitle) {
      return (
        <UrlMemoryContentWithTitle
          url={memory.url}
          previewTitle={memory.previewTitle}
          previewDescription={memory.previewDescription}
          isFailedMemory={isFailedMemory}
        />
      );
    }

    return (
      <UrlMemoryContentWithoutTitle
        url={memory.url}
        isFailedMemory={isFailedMemory}
      />
    );
  }, [memory, isProcessing, isFailedMemory]);

  return (
    <div className="flex flex-col">
      <div
        className={cs(
          'max-w-[90%] flex items-center rounded-lg gap-4',
          isProcessing && 'opacity-50',
        )}
      >
        <div
          className={cs(
            'flex items-center justify-center w-10 h-10 rounded-lg border bg-white shrink-0',
            isFailedMemory ? 'border-dark-grey-01' : 'border-primary-light',
          )}
        >
          {isFailedMemory ? (
            <Icon name="LinkBroken" size={24} color="darkGrey01" />
          ) : (
            <Icon name="LinkTriple" size={24} color="primary" />
          )}
        </div>
        <div className="flex-1">{memoryContent}</div>
      </div>
      <p className="text-xs text-dark-grey-01 mt-1">
        {memory.retrievalType === MemoryRetrievalType.Once
          ? t('one-time-retrieval')
          : t('continuous-refresh')}
      </p>
    </div>
  );
};

export const CrawlMemory: React.FC<CrawlMemoryProps> = (props) => {
  const { memory } = props;

  const { t } = useTranslation('microbot');

  const isProcessing = useIsMemoryProcessing(memory);
  const isFailedMemory = !!useMemoryFailedMessage(memory);

  const memoryContent = useMemo(() => {
    if (isProcessing) {
      return <MemoryProcessingContent />;
    }

    if (memory.previewTitle) {
      return (
        <UrlMemoryContentWithTitle
          url={memory.url}
          isFailedMemory={isFailedMemory}
          previewTitle={memory.previewTitle}
          previewDescription={memory.previewDescription}
        />
      );
    }

    return <UrlMemoryContentWithoutTitle url={memory.url} />;
  }, [memory, isProcessing, isFailedMemory]);

  return (
    <div className="flex flex-col">
      <div
        className={cs(
          'max-w-[90%] flex items-center rounded-lg gap-4',
          isProcessing && 'opacity-50',
        )}
      >
        <div
          className={cs(
            'flex items-center justify-center w-10 h-10 rounded-lg border bg-white shrink-0',
            isFailedMemory ? 'border-dark-grey-01' : 'border-primary-light',
          )}
        >
          {isFailedMemory ? (
            <Icon name="LinkBroken" size={24} color="darkGrey01" />
          ) : (
            <Icon name="LinkTriple" size={24} color="primary" />
          )}
        </div>
        <div className="flex-1">{memoryContent}</div>
      </div>
      <p className="text-xs text-dark-grey-01 mt-1">
        {t('continuous-refresh-crawling-enabled')}
      </p>
    </div>
  );
};

const FileMemoryContent: React.FC<FileMemoryProps> = (props) => {
  const { memory } = props;

  const isFailedMemory = !!useMemoryFailedMessage(memory);

  return (
    <>
      <a
        className={cs(
          'text-sm leading-4-5 font-outfit font-normal text-ellipsis overflow-hidden w-[90%] break-words line-clamp-1',
          isFailedMemory ? 'text-dark-grey-01' : 'text-primary',
        )}
        href={memory.file.url}
        download
        rel="noreferrer noopener"
        target="_blank"
      >
        {memory.file.displayName}
      </a>
      <h6 className="text-dark-grey-025 text-xs font-outfit font-medium text-ellipsis overflow-hidden w-[90%] break-words line-clamp-1">
        {formatBytes(memory.file.fileSize)}
      </h6>
    </>
  );
};

export const FileMemory: React.FC<FileMemoryProps> = (props) => {
  const { memory } = props;

  const isProcessing = useIsMemoryProcessing(memory);
  const isFailedMemory = !!useMemoryFailedMessage(memory);

  const memoryIcon = useMemo(() => {
    if (isProcessing) {
      return <Icon name="Attachments" color="primary" size={24} />;
    }

    if (isFailedMemory || !memory.thumbnailUrl) {
      return <Icon name="AttachmentBroken" color="darkGrey01" size={24} />;
    }

    return (
      <img
        className="w-full h-full block object-fill"
        src={memory.thumbnailUrl}
        alt={memory.file.displayName}
      />
    );
  }, [isProcessing, isFailedMemory, memory]);

  return (
    <div
      className={cs(
        'max-w-[90%] flex items-center gap-4',
        isProcessing && 'opacity-50',
      )}
    >
      <div
        className={cs(
          'flex items-center justify-center w-10 h-10 rounded-lg border overflow-hidden shrink-0',
          isFailedMemory ? 'border-dark-grey-01' : 'border-primary-light',
        )}
      >
        {memoryIcon}
      </div>
      <div className="w-full">
        {isProcessing ? (
          <MemoryProcessingContent />
        ) : (
          <FileMemoryContent memory={memory} />
        )}
      </div>
    </div>
  );
};

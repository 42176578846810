import { atom } from 'jotai';

import type { OpenItemInfo } from './types';

/**
 * ID of the bulk upload or crawl memory that is being show in the details modal.
 * Setting a non-null value will automatically open the modal.
 */
export const openItemAtom = atom<OpenItemInfo | null>(null);

/**
 * Index of the processing item that is highlighted in the widget/banner.
 * If multiple items are in progress or queue,
 * the widget will allow the user to move between them.
 */
export const highlightedItemIndexAtom = atom<number | null>(null);

import { atom } from 'jotai';
import { unwrap } from 'jotai/utils';

import { clientAtom } from '@advisor/api/apollo';
import { uploadAgencyFile } from '@advisor/api/files';
import type { FileData } from '@advisor/api/files/types';
import { Feature, featureEnabledAtoms } from '@advisor/api/feature';
import {
  FileSource,
  DeleteFileDocument,
} from '@advisor/api/generated/graphqlTypes';

import {
  isUploaded,
  isBeingUploaded,
} from '@advisor/design/components/FileAttachment';
import { showToast } from '@advisor/design/components/Toast';

import Sentry from '@advisor/utils/Sentry';
import { soloActionAtoms } from '@advisor/utils/atoms';

import { MicrobotMemoryFileSizeThresholdMB } from '../uploadMisc';
import { getUploadType } from '../utils';
import { EditMemoryStateAtom, EditorStateAtom } from './types';

export default function createAttachmentAtoms(
  editorStateAtom: EditorStateAtom,
  editMemoryStateAtom: EditMemoryStateAtom,
) {
  const bulkUploadsEnabledAtom = featureEnabledAtoms(Feature.BulkUpload);
  const filesEnabledAtom = featureEnabledAtoms(Feature.DataHarvestingFile);

  const canUploadFilesAtom = atom((get) => {
    const state = get(editorStateAtom);
    const { id: editingMemoryId } = get(editMemoryStateAtom);

    return (
      !editingMemoryId &&
      (get(unwrap(filesEnabledAtom)) || get(unwrap(bulkUploadsEnabledAtom))) &&
      state &&
      state.mode !== 'file' &&
      (state.mode !== 'text' || state.value.length === 0)
    );
  });

  const [, uploadFileAtom] = soloActionAtoms(
    async ({ get, set }, file: FileData) => {
      const state = get(editorStateAtom);
      const canBulkUpload = get(unwrap(bulkUploadsEnabledAtom));

      if (state?.mode === 'file') {
        // Already a file uploaded.
        return;
      }

      const uploadType = await getUploadType(file, canBulkUpload);

      const client = await get(clientAtom);

      const upload = uploadAgencyFile(client, file, {
        fileSource: FileSource.MicrobotMemory,
        sizeThreshold: MicrobotMemoryFileSizeThresholdMB,
      });

      set(editorStateAtom, {
        mode: 'file',
        uploadType,
        value: upload,
      });

      const result = await upload.promise;

      if (result.ok === true) {
        set(editorStateAtom, {
          mode: 'file',
          uploadType,
          value: {
            file: result.data,
          },
        });
      } else {
        set(editorStateAtom, null);
      }
    },
  );

  const [, removeFileAtom] = soloActionAtoms(async ({ get }) => {
    const state = get(editorStateAtom);

    if (state?.mode !== 'file') {
      return;
    }

    if (isUploaded(state.value)) {
      const client = await get(clientAtom);

      try {
        await client.mutate({
          mutation: DeleteFileDocument,
          variables: { fileId: state.value.file.id },
        });
      } catch (e) {
        Sentry.captureException(e);
        showToast({
          messageI18Key: 'oops-something-went-wrong',
          variant: 'rose',
          iconName: 'X',
        });
      }
    } else if (isBeingUploaded(state.value)) {
      state.value.cancel();
    }
  });

  const attachmentAtom = atom(
    (get) => {
      const state = get(editorStateAtom);
      if (state?.mode !== 'file' && state?.mode !== 'confirm') {
        return null;
      }

      return { value: state.value, uploadType: state.uploadType };
    },
    async (_get, set, file: FileData | null) => {
      if (file === null) {
        await set(removeFileAtom);

        set(editorStateAtom, {
          mode: 'text',
          value: '',
        });
      } else {
        await set(uploadFileAtom, file);
      }
    },
  );

  return {
    canUploadFilesAtom,
    uploadFileAtom,
    removeFileAtom,
    attachmentAtom,
  };
}

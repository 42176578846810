import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  BulkUploadProcessingStatus,
  MicrobotMemoryProcessingStatus,
} from '@advisor/api/generated/graphqlTypes';
import { ItemType } from '../types';

function isInProgress(item: ItemType) {
  if (item.__typename === 'MicrobotBulkUpload') {
    return item.status === BulkUploadProcessingStatus.InProgress;
  }

  return item.status === MicrobotMemoryProcessingStatus.InProgress;
}

export default function useTitle(
  currentItem: ItemType | undefined,
  currentIndex: number,
  totalCount: number,
) {
  const { t } = useTranslation('microbot');

  return useMemo(() => {
    if (!currentItem) {
      return '';
    }

    if (totalCount < 2) {
      return isInProgress(currentItem)
        ? t('upload-in-progress')
        : t('upload-in-queue');
    }

    if (isInProgress(currentItem)) {
      return t('count-of-total-uploads-in-progress', {
        count: currentIndex + 1,
        total: totalCount,
      });
    }

    return t('count-of-total-uploads-in-queue', {
      count: currentIndex + 1,
      total: totalCount,
    });
  }, [t, currentItem, currentIndex, totalCount]);
}

import type {
  MemoryRetrievalType,
  CreateBulkUploadMutationVariables,
  CreateCrawlMemoryMutationVariables,
  CreateMicrobotMemoryMutationVariables,
} from '@advisor/api/generated/graphqlTypes';
import type { Attachment } from '@advisor/design/components/FileAttachment';
import { ParseKeys } from 'i18next';

export type UploadType = 'file' | 'csv' | 'bulk';

export type AddTextMemoryState = {
  mode: 'text';
  value: string;
  crawl?: boolean;
};

export type AddUrlMemoryState = {
  mode: 'url';
  value: string;
  retrievalType: MemoryRetrievalType;
};

export type AddFileMemoryState = {
  mode: 'file';
  uploadType: UploadType;
  value: Attachment;
};

export type ConfirmUploadState = {
  mode: 'confirm';
  uploadType: UploadType;
  value: Attachment;
};

export type AddMemoryState =
  | AddTextMemoryState
  | AddUrlMemoryState
  | AddFileMemoryState
  | ConfirmUploadState
  | null;

export type EditMemoryState = {
  id?: string | null;
  retrievalType?: MemoryRetrievalType | null;
};

export type VariablesState =
  | undefined
  | {
      type: 'single';
      variables: CreateMicrobotMemoryMutationVariables;
    }
  | {
      type: 'bulk';
      variables: CreateBulkUploadMutationVariables;
    }
  | {
      type: 'crawl';
      variables: CreateCrawlMemoryMutationVariables;
    };

export enum ModalStep {
  MemorySource,
  ChooseRetrieval,
  ConfirmUpload,
}

export type ErrorMessageKey = ParseKeys<['common', 'microbot']> | null;

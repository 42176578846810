// import { useEffect } from 'react';
// import { values } from 'lodash-es';

// import Logger from '@advisor/utils/Logger';
// import { useVideoRoomAPI, useCallState } from '../VideoRoomAPI';
// import useOnUserLeftVideoChat from './useOnUserLeftVideoChat';

const ParticipantListener = () => {
  // const callState = useCallState();
  // const onUserLeftVideoChat = useOnUserLeftVideoChat(callState?.chatRoomId);
  // const { getSessions, onSessionLeft } = useVideoRoomAPI() ?? {};

  // useEffect(() => {
  //   if (!callState || !onSessionLeft) {
  //     return undefined;
  //   }

  //   const subscription = onSessionLeft((session) => {
  //     const sessions = values(getSessions?.() ?? []);
  //     const localSessionId = sessions.find((s) => s.isLocal)?.sessionId;

  //     if (!localSessionId) {
  //       return;
  //     }

  //     const areWeLeaderOfPropagation =
  //       Object.values(sessions)
  //         .map((p) => p.sessionId)
  //         .sort()
  //         .indexOf(localSessionId) === 0;

  //     // if (areWeLeaderOfPropagation) {
  //     //   // onUserLeftVideoChat(session.metadata.userId);

  //     //   Logger.log(
  //     //     `Propagating that a user (#${session.metadata.userId}) left`,
  //     //   );
  //     // }
  //   });

  //   return () => {
  //     subscription?.unsubscribe();
  //   };
  // }, [callState, onSessionLeft, getSessions, onUserLeftVideoChat]);

  return null;
};

export default ParticipantListener;

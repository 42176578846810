import cs from 'classnames';
import React, { memo } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { Icon, Layout } from '@advisor/design/components';
import { getFinishedPercentage } from '../utils';
import { BulkUploadContentProps } from './types';
import BulkUploadEntry from './BulkUploadEntry';

const BulkUploadContent: React.FC<BulkUploadContentProps> = ({
  bulkUpload,
  failedEntries,
}) => {
  const { t } = useTranslation('microbot');

  if (!bulkUpload) {
    return null;
  }

  const finishedPercentage = Math.floor(
    getFinishedPercentage(bulkUpload) * 100,
  );

  return (
    <>
      <div className="flex flex-col gap-4 py-4 border border-grey rounded-xl">
        <div className="flex items-center justify-center gap-3">
          <Icon name="CSVFile" size={32} className="text-primary" />
          <h5 className="font-outfit font-medium text-sm leading-4-5 text-primary">
            {bulkUpload.file.displayName}
          </h5>
        </div>
        <div className="h-[1px] bg-light-grey" />
        <div className="flex flex-col gap-2 px-4">
          <div className="flex items-center gap-4">
            <div className="overflow-hidden grow h-2 rounded-sm bg-primary-light">
              <div
                className="h-full w-full bg-primary transition-all duration-100"
                style={{
                  transform: `translate(-${100 - finishedPercentage}%)`,
                }}
              />
            </div>
            <span className="font-outfit font-bold text-sm text-primary">
              {`${finishedPercentage}%`}
            </span>
          </div>
          <p className="font-outfit text-sm text-dark-grey-02 text-center">
            (
            {t('count-rows-of-total-uploaded', {
              count: bulkUpload.processed + bulkUpload.failed,
              total: bulkUpload.total,
            })}
            )
          </p>
          <div className="font-outfit text-sm text-center text-dark-grey-02">
            <Trans
              t={t}
              i18nKey="count-errors"
              values={{ count: bulkUpload.failed }}
              components={{
                bold: (
                  <b
                    className={cs(
                      'font-bold',
                      bulkUpload.failed > 0 ? 'text-negative' : 'text-primary',
                    )}
                  />
                ),
              }}
            />
          </div>
        </div>
      </div>
      {bulkUpload.failed > 0 && (
        <>
          <Layout.Spacer.Vertical size="small01" />
          <div className="border border-grey rounded-e rounded-s overflow-hidden">
            <div className="flex items-center justify-between px-4 py-3 rounded-e rounded-s bg-negative-light">
              <h5 className="font-outfit font-medium text-sm leading-4-5 text-negative-dark">
                {t('failed-error-entries')}
              </h5>
              <span className="font-outfit font-medium text-sm leading-4-5 text-negative-dark">
                {String(bulkUpload.failed).padStart(2, '0')}
              </span>
            </div>
            <div className="max-h-40 overflow-y-scroll">
              {failedEntries.map((entry) => (
                <BulkUploadEntry key={entry.row} entry={entry} />
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default memo(BulkUploadContent);

import { atom } from 'jotai';

import { MemoryRetrievalType } from '@advisor/api/generated/graphqlTypes';
import { EditorStateAtom } from './types';

export default function createRetrievalTypeAtom(
  editorStateAtom: EditorStateAtom,
) {
  return atom(
    (get) => {
      const state = get(editorStateAtom);
      return state?.mode === 'url' ? state.retrievalType : undefined;
    },
    (get, set, value: MemoryRetrievalType) => {
      const state = get(editorStateAtom);
      if (state?.mode !== 'url') {
        // Not in url input mode
        return;
      }

      set(editorStateAtom, {
        ...state,
        retrievalType: value,
      });
    },
  );
}

import { useSetAtom } from 'jotai';
import { useApolloClient } from '@apollo/client';

import {
  useCancelCrawlMemoryMutation,
  MicrobotMemoryProcessingStatus,
  MicrobotCrawlMemoryInfoFragment,
  MicrobotCrawlMemoryInfoFragmentDoc,
} from '@advisor/api/generated/graphql';
import { useEvent } from '@advisor/utils/hooks';
import { openItemAtom } from './atoms';

export default function useCancelCrawlMemory() {
  const [cancelCrawlMemory] = useCancelCrawlMemoryMutation();
  const setOpenItem = useSetAtom(openItemAtom);
  const client = useApolloClient();

  return useEvent(async (crawlMemoryId?: string) => {
    if (!crawlMemoryId) {
      return;
    }

    const results = await cancelCrawlMemory({
      variables: {
        id: crawlMemoryId,
      },
    });

    if (results.data?.cancelCrawlMemory === 'OK') {
      client.cache.updateFragment(
        {
          fragment: MicrobotCrawlMemoryInfoFragmentDoc,
          fragmentName: 'MicrobotCrawlMemoryInfo',
          id: client.cache.identify({
            __typename: 'MicrobotCrawlMemory',
            id: crawlMemoryId,
          }),
        },
        (fragment: MicrobotCrawlMemoryInfoFragment | null) => {
          if (!fragment) {
            return null;
          }

          setOpenItem({
            ...fragment,
            status: MicrobotMemoryProcessingStatus.Completed,
          });

          return {
            ...fragment,
            // TODO: change to canceled once implemented
            status: MicrobotMemoryProcessingStatus.Completed,
          };
        },
      );
    }
  });
}

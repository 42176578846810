import { atomFamily } from 'jotai/utils';
import { atom, useAtomValue } from 'jotai';

import Sentry from '@advisor/utils/Sentry';
import { nullAtom } from '@advisor/utils/atoms';
import unauthorized from '@advisor/api/unauthorized';
import { InvitationDetailsDocument } from '@advisor/api/generated/graphql';
import useInvitation from './useInvitation';

async function fetchInvitationDetails(invitationId: string) {
  try {
    const json = await unauthorized.query({
      query: InvitationDetailsDocument,
      variables: {
        invitationId,
      },
    });

    const { name, country, email, phoneNumber } =
      json.data?.invitationDetails ?? {};

    return {
      name,
      country,
      email,
      phoneNumber,
    };
  } catch (e) {
    Sentry.captureException(e);
    return undefined;
  }
}

export const invitationDetailsAtoms = atomFamily((invitationId: string) => {
  return atom(() => fetchInvitationDetails(invitationId));
});

/**
 * Used to fetch a preassigned name, country, email and phone number for a specific
 * invitation ID. They get copied into a writable atom later down the road so the
 * invited used can tweak the details.
 */
export default function useInvitationDetails() {
  const { inviteId } = useInvitation();

  const invitationDetailsAtom =
    inviteId !== undefined ? invitationDetailsAtoms(inviteId) : nullAtom;

  return useAtomValue(invitationDetailsAtom) ?? undefined;
}

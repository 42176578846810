import dayjs from 'dayjs';
import { useMemo } from 'react';
import { sortBy } from 'lodash-es';
import { useAtom, useSetAtom } from 'jotai';

import {
  useCrawlMemoriesQuery,
  useMemoryBankBulkUploadsQuery,
  MicrobotMemoryProcessingStatus,
} from '@advisor/api/generated/graphql';
import { useEvent } from '@advisor/utils/hooks';
import { Feature, useIsFeatureEnabled } from '@advisor/api/feature';
import { useActiveMemorySetId } from '../../api';
import { openItemAtom, highlightedItemIndexAtom } from './atoms';
import { getFistItem, getNextIndex } from './utils';
import { ItemType } from './types';

export default function useProcessingUploads() {
  const isBulkUploadEnabled = useIsFeatureEnabled(Feature.BulkUpload);
  const isDirectCrawlingEnabled = useIsFeatureEnabled(Feature.DirectCrawling);
  const memorySetId = useActiveMemorySetId();

  const setOpenItemInfo = useSetAtom(openItemAtom);
  const [highlightedItemIndex, setHighlightedItemIndex] = useAtom(
    highlightedItemIndexAtom,
  );

  const { data: bulkUploads, loading: loadingBulkUploads } =
    useMemoryBankBulkUploadsQuery({
      skip: !isBulkUploadEnabled,
      variables: {
        memorySetId,
      },
      fetchPolicy: 'cache-and-network',
    });

  const { data: inProgressCrawls, loading: loadingInProgressCrawls } =
    useCrawlMemoriesQuery({
      variables: {
        status: MicrobotMemoryProcessingStatus.InProgress,
        memorySetId,
      },
      skip: !isDirectCrawlingEnabled,
      fetchPolicy: 'cache-and-network',
    });

  const { data: queuedCrawls, loading: loadingQueuedCrawls } =
    useCrawlMemoriesQuery({
      variables: {
        status: MicrobotMemoryProcessingStatus.InQueue,
        memorySetId,
      },
      skip: !isDirectCrawlingEnabled,
      fetchPolicy: 'cache-and-network',
    });

  // Merge to simple array
  const uploadItems = useMemo(() => {
    const uploads = bulkUploads?.memoryBankBulkUploads ?? [];

    const crawlMemories = [
      ...(inProgressCrawls?.crawlMemories ?? []),
      ...(queuedCrawls?.crawlMemories ?? []),
    ];

    return sortBy(
      [...uploads, ...crawlMemories],
      (item) => -dayjs(item.updatedAt).valueOf(),
    );
  }, [bulkUploads, inProgressCrawls, queuedCrawls]);

  const currentItem: ItemType | undefined = useMemo(() => {
    if (highlightedItemIndex === null) {
      return getFistItem(uploadItems);
    }

    return uploadItems[highlightedItemIndex];
  }, [highlightedItemIndex, uploadItems]);

  const currentIndex = useMemo(() => {
    if (highlightedItemIndex) {
      return highlightedItemIndex;
    }

    return uploadItems.findIndex((item) => item === currentItem) ?? 0;
  }, [uploadItems, highlightedItemIndex, currentItem]);

  const openItem = useEvent(() => {
    if (!currentItem) {
      return;
    }

    setOpenItemInfo(currentItem);
  });

  const showNextItem = useEvent(() => {
    setHighlightedItemIndex(() => getNextIndex(uploadItems, currentIndex));
  });

  return {
    currentItem,
    isLoading:
      loadingBulkUploads || loadingInProgressCrawls || loadingQueuedCrawls,

    currentIndex,
    totalCount: uploadItems.length,

    openItem,
    showNextItem,
  };
}

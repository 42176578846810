import React from 'react';
import { useAtomValue } from 'jotai';

import { Modal, SizeAdaptingWrapper } from '@advisor/design/components';
import useEditMilestoneModal from './useEditMilestoneModal';
import { milestoneEditStateAtom } from './atoms';
import DeleteMilestoneTab from './DeleteMilestoneTab';
import EditMilestoneTab from './EditMilestoneTab';
import DatePickerTab from './DatePickerTab';
import {
  ModalTab,
  EditTabProps,
  DeleteTabProps,
  DatePickerTabProps,
} from './types';

type TabProps = EditTabProps & DeleteTabProps & DatePickerTabProps;

const TabMap = {
  [ModalTab.Root]: EditMilestoneTab,
  [ModalTab.DatePicker]: DatePickerTab,
  [ModalTab.DeleteConsent]: DeleteMilestoneTab,
} as const;

function Inner() {
  const milestoneEditState = useAtomValue(milestoneEditStateAtom);
  const {
    inputs,
    activeTab,
    canDelete,
    minDueDate,
    submit,
    closeModal,
    openRootTab,
    handleDelete,
    openDatePickerTab,
    openDeleteConsentTab,
  } = useEditMilestoneModal();

  return (
    <Modal.Base
      appear
      maxWidth={406}
      visible={!!milestoneEditState}
      onClose={closeModal}
    >
      {milestoneEditState && (
        <form onSubmit={submit}>
          <SizeAdaptingWrapper<TabProps>
            durationMs={200}
            lockX
            inner={TabMap[activeTab]}
            commonProps={{
              canDelete,
              activeTab,
              minDueDate,
              inputProps: inputs,
              chatRoomId: milestoneEditState.milestone.chatRoomId,
              onSubmit: submit,
              onClose: closeModal,
              onDelete: handleDelete,
              onOpenRoot: openRootTab,
              onOpenDatePicker: openDatePickerTab,
              onOpenDeleteConsent: openDeleteConsentTab,
            }}
          />
        </form>
      )}
    </Modal.Base>
  );
}

function EditMilestoneModal() {
  const milestoneEditState = useAtomValue(milestoneEditStateAtom);

  return (
    <Inner
      // Remounting on milestone change to reset derived state
      key={milestoneEditState?.milestone.id}
    />
  );
}

export default EditMilestoneModal;

import React from 'react';
import { useNavigate } from 'react-router';
import { Trans, useTranslation } from 'react-i18next';

import { Layout } from '@advisor/design/components';
import { Button } from '@advisor/design/Button';

const AdminHeader: React.FC = () => {
  const { t } = useTranslation('microbot');
  const navigate = useNavigate();

  return (
    <div className="border border-grey mx-6 p-6 mt-6 flex flex-col items-center rounded-md">
      <h3 className="font-sora text-sm font-bold text-dark-grey-03">
        {t('subscription-memory-sets')}
      </h3>
      <Layout.Spacer.Vertical size="micro02" />
      <p className="font-outfit text-sm text-dark-grey-03">
        <Trans
          t={t}
          i18nKey="please-note-that-you-have-edit-access-to-the-following-subscription-sets"
          components={{ bold: <span className="font-bold" /> }}
        />
      </p>
      <Layout.Spacer.Vertical size="micro02" />
      <Button
        size="medium"
        color="primary"
        variant="outlined"
        label={t('manage-subscribers')}
        onPress={() => {
          navigate('../preferences/memory-sets/subscribers');
        }}
      />
    </div>
  );
};

export default AdminHeader;

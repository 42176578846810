import { actionAtom } from '@advisor/utils/atoms';

import { AddTextMemoryState, EditMemoryState } from '../types';
import {
  EditorStateAtom,
  ErrorMessageKeyAtom,
  EditMemoryStateAtom,
} from './types';

export default function createOpenEditMemoryAtom(
  editorStateAtom: EditorStateAtom,
  editMemoryStateAtom: EditMemoryStateAtom,
  errorMessageKeyAtom: ErrorMessageKeyAtom,
) {
  return actionAtom(
    (
      { get, set },
      state: AddTextMemoryState,
      editMemoryState: EditMemoryState,
    ) => {
      if (get(editorStateAtom) !== null) {
        return;
      }

      set(errorMessageKeyAtom, null);
      set(editMemoryStateAtom, editMemoryState);
      set(editorStateAtom, { ...state });
    },
  );
}

import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  MemorySetList,
  AddMemorySetButton,
  MemorySetActionModal,
} from '@advisor/microbots/components';
import SideModal from '../SideModal';

const MemorySetsModal: React.FC = () => {
  const { t } = useTranslation('microbot');

  return (
    <SideModal.Default
      scroll
      backButton
      padding={false}
      title={t('manage-subscriptions')}
    >
      <MemorySetList />
      <AddMemorySetButton />
      <MemorySetActionModal />
    </SideModal.Default>
  );
};

export default MemorySetsModal;

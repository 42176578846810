import React, { memo, useMemo } from 'react';
import cs from 'classnames';

import Env from '@advisor/api/env';
import { Spacing } from '../../styles';
import { AvatarProps } from './types';

const AvatarPlaceholder = ({
  className,
  size = 40,
}: {
  className?: string;
  size?: number;
}) => {
  return (
    <div
      className={cs('bg-light-grey rounded-full border border-grey', className)}
      style={{ width: `${size}px`, height: `${size}px` }}
    />
  );
};

function Avatar({
  avatarUrl,
  className,
  size = Spacing.small01,
  bordered = true,
}: AvatarProps) {
  const compressedImageUrl = useMemo(() => {
    if (typeof avatarUrl === 'number') {
      // eslint-disable-next-line no-console
      console.warn("avatar url can't be a number on the web version");
      return undefined;
    }

    if (avatarUrl?.startsWith('http') && Env.api.imageCompressionUrl) {
      const imagePath = new URL(avatarUrl).pathname;
      return `${Env.api.imageCompressionUrl}/fit-in/350x350${imagePath}`;
    }

    return avatarUrl;
  }, [avatarUrl]);

  if (!compressedImageUrl) {
    return <AvatarPlaceholder size={size} className={className} />;
  }

  return (
    <img
      src={compressedImageUrl}
      alt="User Avatar"
      style={{
        width: `${size}px`,
        height: `${size}px`,
      }}
      className={cs(
        'rounded-full shadow-drop-01 group-hover:opacity-75 object-cover',
        bordered && 'border-4 border-white',
        className,
      )}
    />
  );
}

export default memo(Avatar);

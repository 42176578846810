import { atom } from 'jotai';
import { pipe } from 'remeda';
import { atomFamily } from 'jotai/utils';

import { derive } from '@advisor/utils/atoms';
import soon, { soonAll } from '@advisor/utils/soon';
import { UserInfoFragment } from '../generated/graphql';
import { Feature, featureEnabledAtoms } from '../feature';
import { roleRequestAtoms } from '../roleRequest';
import { chatRoomAtoms, Member } from '../chatRoom';
import { Role } from '../user';
import { meAtom } from '../me';
import env from '../env';
import scopeUtils from './scopeUtils';

const studentScopeAtom = derive([meAtom], Role.isStudent);
const pendingStudentScopeAtom = derive([meAtom], Role.isPendingStudent);
const familyMemberScopeAtom = derive([meAtom], Role.isFamilyMember);
const serviceProviderScopeAtom = derive([meAtom], Role.isServiceProvider);
const advisorScopeAtom = derive([meAtom], Role.isAdvisor);
const verifiedAdvisorScopeAtom = derive([meAtom], Role.isVerifiedAdvisor);
const primaryAdvisorScopeAtom = derive([meAtom], Role.isPrimaryAdvisor);
const aspiringAdvisorScopeAtom = derive([meAtom], Role.isAspiringAdvisor);

const approvingAdvisorScopeAtom = derive(
  [meAtom, featureEnabledAtoms(Feature.ApprovingAdvisors)],
  (me, areApprovingAdvisorsEnabled) =>
    Role.isApprovingAdvisor(me) && areApprovingAdvisorsEnabled,
);

// Non approving advisor - an advisor who is verified but not approving
const nonApprovingAdvisorScopeAtom = derive(
  [meAtom],
  (me) => Role.isVerifiedAdvisor(me) && !Role.isApprovingAdvisor(me),
);

const singleChatUserScopeAtom = derive([meAtom], Role.isSingleChatUser);

const canEditCustomBrandingAtom = derive(
  [meAtom, featureEnabledAtoms(Feature.GreyLabeling)],
  (me, isGreyLabelingEnabled) =>
    Role.isPrimaryAdvisor(me) && isGreyLabelingEnabled,
);

/**
 * A student's chat-room, where everything revolves around them.
 * For now, synonymous with `studentConversationScopeAtoms`, but
 * this can change when students can join rooms other than their
 * own.
 */
const studentSpaceScopeAtoms = atomFamily((chatRoomId: string) => {
  const { memberOfOwnerAtom } = chatRoomAtoms(chatRoomId);

  return derive([memberOfOwnerAtom], (owner) => Role.isStudent(owner?.member));
});

/**
 * Scope for chat rooms that have a student in them.
 */
const studentConversationScopeAtoms = atomFamily((chatRoomId: string) => {
  const { membersAtom } = chatRoomAtoms(chatRoomId);

  return derive([membersAtom], (members) =>
    members.some(({ member }) => Role.isStudent(member)),
  );
});

/**
 * Scope for chat rooms that have aspiring advisor in them.
 */
const aspiringAdvisorConversationScopeAtoms = atomFamily(
  (chatRoomId: string) => {
    const { membersAtom } = chatRoomAtoms(chatRoomId);

    return derive([membersAtom], (members) =>
      members.some(({ member }) => Role.isAspiringAdvisor(member)),
    );
  },
);

/**
 * Scope for Microbot chat rooms.
 */
const microbotConversationScopeAtoms = atomFamily((chatRoomId: string) => {
  const { chatRoomAtom } = chatRoomAtoms(chatRoomId);

  return derive(
    [chatRoomAtom],
    (chatRoom) => chatRoom?.__typename === 'MicrobotChatRoom',
  );
});

/**
 * Scope for chat rooms of pending students
 */
const pendingStudentConversationScopeAtom = atomFamily((chatRoomId: string) => {
  const { chatRoomAtom } = chatRoomAtoms(chatRoomId);

  const studentsAmongUsAtom = studentConversationScopeAtoms(chatRoomId);

  return derive(
    [chatRoomAtom, studentsAmongUsAtom],
    (chatRoom, studentsAmongUs) => {
      return (
        !!chatRoom &&
        studentsAmongUs &&
        Role.isPendingStudent(Member.student(chatRoom))
      );
    },
  );
});

const externalAgencyScopeAtom = derive(
  [meAtom],
  (me) => Role.isAdvisor(me) && me.agency !== env.defaultAgencyName,
);

const allowedToTalkScopeAtom = atomFamily((chatRoomId: string) => {
  const { chatRoomAtom } = chatRoomAtoms(chatRoomId);

  const inquirerAllowedToTalkAtom = derive(
    [roleRequestAtoms(chatRoomId)],
    (roleRequest) => roleRequest?.inquirerAllowedToTalk || false,
  );

  return atom((get) =>
    pipe(
      soonAll([get(meAtom), get(chatRoomAtom)]),
      soon(([me, chatRoom]) => {
        if (Role.isStudent(me)) {
          return true;
        }

        if (Role.isVerifiedAdvisor(me)) {
          return true;
        }

        if (!chatRoom) {
          return !Role.isAspiringAdvisor(me);
        }

        if (Role.isFamilyMember(me)) {
          return chatRoom.members.length > 1;
        }

        if (!Role.isAspiringAdvisor(me)) {
          return true;
        }

        return get(inquirerAllowedToTalkAtom);
      }),
    ),
  );
});

const canSeeMembersListScopeAtom = allowedToTalkScopeAtom;

const canViewUserProfileScopeAtom = atomFamily(
  (user: UserInfoFragment) => {
    return derive([meAtom], (me) => {
      if (Role.isAdvisor(me)) {
        return Role.isStudent(user) || Role.isMicrobot(user);
      }

      if (Role.isStudent(me)) {
        return Role.isAdvisor(user);
      }

      return false;
    });
  },
  (a, b) => a.id === b.id,
);

const hasStudentProfileInUserMenuScopeAtom = atomFamily(
  (user: UserInfoFragment) => {
    return derive([meAtom], (me) => {
      if (Role.isFamilyMember(me) || Role.isServiceProvider(me)) {
        return Role.isStudent(user);
      }

      return false;
    });
  },
  (a, b) => a.id === b.id,
);

/**
 * Scope for advisor who is the leading advisor of a chat room
 */
const leadingAdvisorScopeAtom = atomFamily((chatRoomId: string) => {
  const { chatRoomAtom } = chatRoomAtoms(chatRoomId);

  return derive([meAtom, chatRoomAtom], (me, chatRoom) => {
    if (!chatRoom) {
      return false;
    }

    return (
      Role.isVerifiedAdvisor(me) &&
      !!chatRoom.leadingAdvisorId &&
      chatRoom.leadingAdvisorId === me.id
    );
  });
});

/**
 * Determine if user can see the video call button in the chat room header
 */
const canSeeHeaderVideoCallButtonScopeAtom = atomFamily((chatRoomId: string) =>
  videoChatAccessScopeAtom(chatRoomId),
);

const doesChatHaveLeadingAdvisorScopeAtom = atomFamily((chatRoomId: string) => {
  const { hasLeadingAdvisorAtom } = chatRoomAtoms(chatRoomId);
  return hasLeadingAdvisorAtom;
});

const videoChatEnabledScopeAtom = atomFamily((chatRoomId: string) => {
  return derive(
    [
      featureEnabledAtoms(Feature.VideoChat),
      doesChatHaveLeadingAdvisorScopeAtom(chatRoomId),
      microbotConversationScopeAtoms(chatRoomId),
      aspiringAdvisorConversationScopeAtoms(chatRoomId),
    ],
    (
      isVideoChatEnabled,
      hasLeadingAdvisor,
      isMicrobotConversation,
      isAspiringAdvisorConversation,
    ) =>
      isVideoChatEnabled &&
      hasLeadingAdvisor &&
      !isMicrobotConversation &&
      !isAspiringAdvisorConversation,
  );
});

const videoChatAccessScopeAtom = atomFamily((chatRoomId: string) => {
  return derive(
    [videoChatEnabledScopeAtom(chatRoomId), allowedToTalkScopeAtom(chatRoomId)],
    (isVideoChatEnabled, isAllowedToTalk) =>
      isVideoChatEnabled && isAllowedToTalk,
  );
});

const processingUploadsAccessScopeAtom = scopeUtils.some([
  featureEnabledAtoms(Feature.BulkUpload),
  featureEnabledAtoms(Feature.DirectCrawling),
]);

const videoChatHistoryEnabledScopeAtom = videoChatEnabledScopeAtom;

const videoChatHistoryAccessScopeAtom = videoChatAccessScopeAtom;

/**
 * Scope Atoms for memory set related privileges
 */

const memorySetAdminScopeAtom = derive([meAtom], (me) =>
  Role.isMemorySetAdmin(me),
);

/**
 * ScopeAtoms are used to determine if a user has access to a certain feature.
 */
const ScopeAtoms = {
  student: studentScopeAtom,
  pendingStudent: pendingStudentScopeAtom,
  familyMember: familyMemberScopeAtom,
  serviceProvider: serviceProviderScopeAtom,
  advisor: advisorScopeAtom,
  verifiedAdvisor: verifiedAdvisorScopeAtom,
  primaryAdvisor: primaryAdvisorScopeAtom,
  aspiringAdvisor: aspiringAdvisorScopeAtom,
  approvingAdvisor: approvingAdvisorScopeAtom,
  nonApprovingAdvisor: nonApprovingAdvisorScopeAtom,
  externalAgency: externalAgencyScopeAtom,
  singleChatUser: singleChatUserScopeAtom,
  studentSpace: studentSpaceScopeAtoms,
  studentConversation: studentConversationScopeAtoms,
  aspiringAdvisorConversation: aspiringAdvisorConversationScopeAtoms,
  microbotConversation: microbotConversationScopeAtoms,
  pendingStudentConversation: pendingStudentConversationScopeAtom,
  feature: featureEnabledAtoms,
  allowedToTalk: allowedToTalkScopeAtom,
  canSeeMembersListScope: canSeeMembersListScopeAtom,
  canViewUserProfileScope: canViewUserProfileScopeAtom,
  hasStudentProfileInUserMenuScope: hasStudentProfileInUserMenuScopeAtom,
  leadingAdvisor: leadingAdvisorScopeAtom,
  canSeeHeaderVideoCallButtonScope: canSeeHeaderVideoCallButtonScopeAtom,
  canEditCustomBrandingScope: canEditCustomBrandingAtom,
  doesChatHaveLeadingAdvisor: doesChatHaveLeadingAdvisorScopeAtom,
  processingUploadsAccess: processingUploadsAccessScopeAtom,

  // Memory sets
  memorySetAdminScopeAtom,

  // Miscellaneous
  videoChatEnabled: videoChatEnabledScopeAtom,
  videoChatAccess: videoChatAccessScopeAtom,
  videoChatHistoryEnabled: videoChatHistoryEnabledScopeAtom,
  videoChatHistoryAccess: videoChatHistoryAccessScopeAtom,
  ...scopeUtils,
};

export default ScopeAtoms;

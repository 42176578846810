import cs from 'classnames';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import type { ListRenderItem } from 'react-native';
import React, { memo, useCallback, useState } from 'react';

import {
  useChatRoomQuery,
  AdvisorInfoFragment,
} from '@advisor/api/generated/graphql';
import {
  Modal,
  Layout,
  Loader,
  Avatar,
  FlatList,
  SearchInput,
  DeprecatedButton,
} from '@advisor/design/components';
import suspenseHOC from '@advisor/utils/suspenseHOC';
import { showToast } from '@advisor/design/components/Toast';
import useAdvisorList from '@advisor/api/hooks/useAdvisorList';
import { ReassignStudentModalProps } from './types';
import useReassignUser from './useReassignUser';
import { useReassignChatRoomMolecule } from './reassignChatRoomMolecule';

const keyExtractor = (item: AdvisorInfoFragment) => item.id;

const ReassignStudentModal: React.FC<ReassignStudentModalProps> = ({
  scope,
}) => {
  const { reassignChatRoomIdAtom } = useReassignChatRoomMolecule(scope);
  const [reassignChatRoomId, setReassignChatRoomId] = useAtom(
    reassignChatRoomIdAtom,
  );
  const [selectedAdvisorId, setSelectedAdvisorId] = useState('');
  const [searchText, setSearchText] = useState('');
  const navigate = useNavigate();

  const { advisors, loading, hasMore, loadMore } = useAdvisorList(
    searchText,
    !reassignChatRoomId,
  );

  const { data: { chatRoom } = {} } = useChatRoomQuery({
    variables: { chatRoomId: reassignChatRoomId ?? '' },
    skip: !reassignChatRoomId,
  });

  const reassignUser = useReassignUser();
  const { t } = useTranslation('common');

  const onSearchAdvisors = useCallback((newValue: string) => {
    setSearchText(newValue);
  }, []);

  const onLoadMore = useCallback(() => {
    if (hasMore) {
      loadMore();
    }
  }, [hasMore, loadMore]);

  const onCancel = useCallback(() => {
    setReassignChatRoomId(null);
    setSelectedAdvisorId('');
    setSearchText('');
  }, [setReassignChatRoomId]);

  const onConfirm = useCallback(async () => {
    const studentId = reassignChatRoomId;
    const advisorId = selectedAdvisorId;

    setReassignChatRoomId(null);
    setSelectedAdvisorId('');

    if (!studentId || !advisorId) {
      return;
    }

    const studentName =
      chatRoom?.members?.find(
        (memberInfo) => memberInfo.member.__typename === 'User',
      )?.member.name ?? '';

    const advisorName =
      advisors.find((adv) => adv.id === advisorId)?.name ?? '';

    const success = await reassignUser(
      advisorId,
      studentId,
      studentName,
      advisorName,
    );

    if (success) {
      navigate('/chat');

      showToast({
        messageI18Key: 'selected-conversation-was-re-assigned',
        variant: 'blue',
        iconName: 'UserChange',
      });
    }
  }, [
    navigate,
    advisors,
    chatRoom,
    reassignUser,
    selectedAdvisorId,
    reassignChatRoomId,
    setReassignChatRoomId,
  ]);

  const renderAdvisor: ListRenderItem<AdvisorInfoFragment> = ({ item }) => {
    const isSelected = selectedAdvisorId === item.id;

    return (
      <button
        key={item.id}
        type="button"
        className="bg-white"
        onClick={() => {
          setSelectedAdvisorId(item.id);
        }}
      >
        <div className="flex items-center">
          <Avatar avatarUrl={item.avatarUrl} bordered={false} />
          <Layout.Spacer.Horizontal size="tiny" />
          <div className="flex flex-1 justify-between py-4 items-center border-b border-b-light-grey overflow-hidden text-ellipsis">
            <span className="font-sora text-sm ltr:text-left rtl:text-right font-semibold">
              {item.name}
            </span>
          </div>
          <Layout.Spacer.Horizontal size="tiny" />
          <div
            className={cs(
              'flex w-6 h-6 rounded-full border-2 justify-center items-center',
              isSelected ? 'border-primary' : 'border-light-grey',
            )}
          >
            <div
              className={cs('w-3 h-3 rounded-full', isSelected && 'bg-primary')}
            />
          </div>
        </div>
      </button>
    );
  };

  return (
    <Modal.Base
      onClose={onCancel}
      visible={reassignChatRoomId !== null}
      maxWidth={400}
    >
      <Modal.Header
        title={t('pick-new-host')}
        onClose={onCancel}
        subtitle={t('to-re-assign-the-customer-profile')}
      />
      <Layout.Spacer.Vertical size={16} />
      <div className="px-8">
        <SearchInput
          value={searchText}
          onChange={onSearchAdvisors}
          placeholder={t('search-hosts')}
        />
      </div>
      {loading ? (
        <div className="h-72 pt-3 flex justify-center align-center">
          <Loader />
        </div>
      ) : (
        <FlatList
          data={advisors}
          scrollEnabled
          className="h-72"
          contentContainerClassName="px-8 pb-8 pt-3"
          onEndReached={onLoadMore}
          renderItem={renderAdvisor}
          keyExtractor={keyExtractor}
          ListEmptyComponent={
            <div className="text-center font-outfit text-sm text-dark-grey-01">
              {t('hosts-not-found')}
            </div>
          }
        />
      )}
      <div className="flex justify-center px-8">
        <DeprecatedButton.Large
          label={t('confirm')}
          disabled={!selectedAdvisorId}
          onPress={onConfirm}
        />
      </div>
      <Layout.Spacer.Vertical size={32} />
    </Modal.Base>
  );
};

export default memo(suspenseHOC(ReassignStudentModal));

import { ExactTypeGuard, assertType } from '@advisor/utils/typeAssertions';
import {
  AdvisorRole,
  UserInfoFragment,
  AdvisorInfoFragment,
  StudentInfoFragment,
  FamilyMemberInfoFragment,
  ServiceProviderInfoFragment,
  MicrobotInfoFragment,
} from '../generated/graphql';

export const UserTypeNames = [
  'User',
  'Advisor',
  'FamilyMember',
  'ServiceProvider',
  'Microbot',
] as const;

assertType<
  ExactTypeGuard<
    // actual
    (typeof UserTypeNames)[number],
    // expected
    UserInfoFragment['__typename']
  >
>();

type VerifiedAdvisorInfo = AdvisorInfoFragment & {
  advisorRole: AdvisorRole.PrimaryAdvisor | AdvisorRole.AssistantAdvisor;
};

type AspiringAdvisorInfo = AdvisorInfoFragment & {
  advisorRole: AdvisorRole.AspiringAdvisor;
};

const isStudent = (
  user?: UserInfoFragment | null | undefined,
): user is StudentInfoFragment => user?.__typename === 'User';

const isPendingStudent = (
  user: UserInfoFragment | null | undefined,
): user is StudentInfoFragment => isStudent(user) && user.isPending;

const isAdvisor = (
  user?: UserInfoFragment | null | undefined,
): user is AdvisorInfoFragment => user?.__typename === 'Advisor';

const isFamilyMember = (
  user?: UserInfoFragment | null | undefined,
): user is FamilyMemberInfoFragment => user?.__typename === 'FamilyMember';

const isServiceProvider = (
  user?: UserInfoFragment | null | undefined,
): user is ServiceProviderInfoFragment =>
  user?.__typename === 'ServiceProvider';

const isMicrobot = (
  user?: UserInfoFragment | null | undefined,
): user is MicrobotInfoFragment => user?.__typename === 'Microbot';

const isAspiringAdvisor = (
  user?: UserInfoFragment | null | undefined,
): user is AspiringAdvisorInfo =>
  isAdvisor(user) && user.advisorRole === AdvisorRole.AspiringAdvisor;

const isVerifiedAdvisor = (
  user?: UserInfoFragment | null | undefined,
): user is VerifiedAdvisorInfo =>
  isAdvisor(user) &&
  [AdvisorRole.PrimaryAdvisor, AdvisorRole.AssistantAdvisor].includes(
    user.advisorRole,
  );

const isPrimaryAdvisor = (
  user?: UserInfoFragment | null | undefined,
): user is VerifiedAdvisorInfo =>
  isAdvisor(user) && user.advisorRole === AdvisorRole.PrimaryAdvisor;

const isApprovingAdvisor = (
  user?: UserInfoFragment | null | undefined,
): user is VerifiedAdvisorInfo => isAdvisor(user) && !!user.isApproving;

const isAssistantAdvisor = (
  user?: UserInfoFragment | null | undefined,
): user is VerifiedAdvisorInfo =>
  isAdvisor(user) && user.advisorRole === AdvisorRole.AssistantAdvisor;

const isMemorySetAdmin = (
  user?: UserInfoFragment | null | undefined,
): user is VerifiedAdvisorInfo => isAdvisor(user) && !!user.isMemorySetAdmin;

/**
 * Role for a user which can be a member of only a single chatRoom at a time.
 * For now this is only a Student, a Family Member or an unverified Advisor.
 */
const isSingleChatUser = (
  user?: UserInfoFragment | null | undefined,
): user is
  | StudentInfoFragment
  | AspiringAdvisorInfo
  | FamilyMemberInfoFragment =>
  isStudent(user) || isAspiringAdvisor(user) || isFamilyMember(user);

const Role = {
  /* Students */
  isStudent,
  isPendingStudent,

  /* Advisors */
  isAdvisor,
  isMemorySetAdmin,
  isPrimaryAdvisor,
  isAspiringAdvisor,
  isVerifiedAdvisor,
  isApprovingAdvisor,
  isAssistantAdvisor,

  /* Others */
  isMicrobot,
  isFamilyMember,
  isSingleChatUser,
  isServiceProvider,
};

export default Role;

import cs from 'classnames';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import React, { Suspense, memo, useCallback, useEffect } from 'react';

import {
  useAuth,
  LoginType,
  loginAtom,
  LoginState,
  FlowType,
} from '@advisor/api/auth';
import { Icon } from '@advisor/design/components';
import { FormContainer } from '@advisor/design/components/LoginComponents';
import VerificationForm from '@advisor/ui/components/VerificationForm';
import { useInvitationDetails } from '@advisor/onboarding';
import { useEvent } from '@advisor/utils/hooks';
import EmailForm from './EmailForm';
import PhoneForm from './PhoneForm';
import ConfirmDeleteForm from './ConfirmDeleteForm';

const LoginForm: React.FC<{ insideModal?: boolean; onClose?: () => void }> = ({
  insideModal,
  onClose,
}) => {
  const { t } = useTranslation('onboarding');
  const [login, setLogin] = useAtom(loginAtom);
  const { authStart, authVerify } = useAuth();
  const navigate = useNavigate();

  const invitationDetails = useInvitationDetails();

  const onBackClicked = useEvent(() => {
    navigate('/');
  });

  const onChangeFirstStep = useEvent(() => {
    setLogin((prev) => ({ ...prev, state: LoginState.Initial }));
  });

  const onResendOTP = useEvent(async () => {
    await authStart(login.phoneNumber ?? login.emailAddress ?? '');
  });

  const onChangeVerificationType = useEvent(() => {
    setLogin((prev) => {
      if (prev.type === LoginType.PhoneNumber) {
        return {
          type: LoginType.EmailAddress,
          state: LoginState.Initial,
          flowType: prev.flowType,
        };
      }

      return {
        type: LoginType.PhoneNumber,
        state: LoginState.Initial,
        flowType: prev.flowType,
      };
    });
  });

  useEffect(() => {
    if (invitationDetails?.email || invitationDetails?.phoneNumber) {
      setLogin((prev) => ({
        ...prev,
        type: invitationDetails.phoneNumber
          ? LoginType.PhoneNumber
          : LoginType.EmailAddress,
      }));
    }
  }, [invitationDetails, setLogin]);

  const renderForm = useCallback(() => {
    if (login.state === LoginState.Verification) {
      return (
        <VerificationForm
          onGoBack={onChangeFirstStep}
          onVerify={authVerify}
          onResendOTP={onResendOTP}
          verificationType={login.type}
          onChangeVerificationType={
            login.type === LoginType.PhoneNumber
              ? onChangeVerificationType
              : undefined
          }
          identity={login.emailAddress ?? login.phoneNumber ?? ''}
        />
      );
    }

    if (login.state === LoginState.ConfirmDelete) {
      return <ConfirmDeleteForm onClose={onClose} />;
    }

    if (login.type === LoginType.PhoneNumber) {
      return <PhoneForm />;
    }

    return <EmailForm />;
  }, [
    login,
    onResendOTP,
    authVerify,
    onChangeFirstStep,
    onChangeVerificationType,
    onClose,
  ]);

  return (
    <FormContainer
      insideModal={insideModal}
      className={cs(
        'flex flex-col',
        login.state === LoginState.ConfirmDelete && '!min-h-0',
        login.flowType === FlowType.Delete && '!pt-0',
      )}
    >
      {!insideModal && (
        <div className="flex w-[360px] py-6 md:hidden">
          <button type="button" onClick={onBackClicked}>
            <Icon name="X" className="w-6 h-6 text-dark-grey-01" />
          </button>
        </div>
      )}
      {login.state !== LoginState.ConfirmDelete && (
        <div className="flex flex-row gap-5 mb-6">
          <h2 className="text-lg md:text-base font-semibold font-sora">
            {login.flowType === FlowType.Delete
              ? t('delete-account').toUpperCase()
              : t('sign-in-join').toUpperCase()}
          </h2>
        </div>
      )}
      <Suspense fallback={null}>{renderForm()}</Suspense>
    </FormContainer>
  );
};

export default memo(LoginForm);

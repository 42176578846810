import { useMemo } from 'react';
import { produce } from 'immer';
import { reduce } from 'lodash-es';

import { useMe } from '@advisor/api/me';
import { Role } from '@advisor/api/user';
import {
  MemorySetStatus,
  MemorySetsQuery,
  useMemorySetsQuery,
  MemorySetEventType,
  MemorySetInfoFragment,
  MemorySetEventDocument,
  MemorySetEventInfoFragment,
} from '@advisor/api/generated/graphql';
import useMoreSubscription from '@advisor/utils/hooks/useMoreSubscription';

export interface MemorySetGroups {
  memorySetMap: Record<string, MemorySetInfoFragment>;
  draftSetIds: string[];
  activeSetIds: string[];
  availableSetIds: string[];
  subscribedSetIds: string[];
}

function useGroupMemorySets(
  memorySets: MemorySetInfoFragment[],
): MemorySetGroups {
  const me = useMe();

  return useMemo(() => {
    const memorySetMap: Record<string, MemorySetInfoFragment> = reduce(
      memorySets,
      (acc, memorySet) => ({
        ...acc,
        [memorySet.id]: { ...memorySet },
      }),
      {},
    );

    if (!Role.isVerifiedAdvisor(me)) {
      return {
        memorySetMap,

        draftSetIds: [],
        activeSetIds: [],
        availableSetIds: [],
        subscribedSetIds: [],
      };
    }

    const activeSetIds = memorySets
      .filter((mSet) => mSet.status === MemorySetStatus.Active)
      .map((mSet) => mSet.id);

    const draftSetIds = memorySets
      .filter((mSet) => mSet.status === MemorySetStatus.Draft)
      .map((mSet) => mSet.id);

    const subscribedSetIds = memorySets
      .filter((mSet) => mSet.subscribedAgencies.includes(me.agency))
      .map((mSet) => mSet.id);

    const availableSetIds = memorySets
      .filter(
        (mSet) =>
          mSet.status === MemorySetStatus.Active &&
          !mSet.subscribedAgencies.includes(me.agency),
      )
      .map((mSet) => mSet.id);

    return {
      memorySetMap,

      draftSetIds,
      activeSetIds,
      availableSetIds,
      subscribedSetIds,
    };
  }, [memorySets, me]);
}

export default function useMemorySets() {
  const { data, loading, error, refetch, subscribeToMore } = useMemorySetsQuery(
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  const memorySets = useGroupMemorySets(data?.memorySets ?? []);

  useMoreSubscription(
    MemorySetEventDocument,
    subscribeToMore,
    (prev, { subscriptionData }) => {
      const { memorySetEvent } = subscriptionData.data;

      if (!memorySetEvent?.memorySet?.id || !prev) {
        return prev;
      }

      return updateMemorySets(prev, memorySetEvent);
    },
  );

  return {
    isLoading: loading,
    hasError: !!error,
    memorySets,
    totalCount: data?.memorySets.length ?? 0,

    refetch,
  };
}

function updateMemorySets(
  query: MemorySetsQuery,
  event: MemorySetEventInfoFragment,
): MemorySetsQuery {
  return produce(query, (draft) => {
    if (event.memorySet && event.eventType === MemorySetEventType.SetCreated) {
      draft.memorySets.unshift(event.memorySet);
    }

    if (event.eventType === MemorySetEventType.SetUpdated) {
      draft.memorySets = draft.memorySets.map((mSet) =>
        mSet.id === event.memorySet?.id ? event.memorySet : mSet,
      );
    }

    if (event.eventType === MemorySetEventType.SetDeleted) {
      draft.memorySets = draft.memorySets.filter(
        (mSet) => mSet.id !== event.memorySet?.id,
      );
    }
  });
}

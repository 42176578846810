import React, { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import { useMe } from '@advisor/api/me';
import { Role } from '@advisor/api/user';
import { MemorySetStatus } from '@advisor/api/generated/graphqlTypes';

import { ContextMenu, Layout } from '@advisor/design/components';

import MemorySetOptions from './MemorySetOptions';
import type { MemorySetListItemProps } from './types';
import useMemorySetOptions from './useMemorySetOptions';

const MemorySetListItem: React.FC<MemorySetListItemProps> = (props) => {
  const { memorySet } = props;
  const memorySetOptions = useMemorySetOptions(memorySet);
  const navigate = useNavigate();

  const { t } = useTranslation('microbot');
  const me = useMe();

  const openMemorySet = () => {
    navigate(memorySet.id);
  };

  const isAdmin = useMemo(() => Role.isMemorySetAdmin(me), [me]);

  const renderContent = () => {
    return (
      <span className="block bg-light-grey p-4 rounded-md border border-grey">
        <h5 className="text-sm font-semibold font-sora text-dark-grey-03">
          {memorySet.title}
        </h5>
        <p className="text-xs font-outfit font-normal text-dark-grey-02">
          {memorySet.description}
        </p>
        <Layout.Spacer.Vertical size="atomic" />
        {isAdmin && (
          <p className="text-[10px] font-outfit text-dark-grey-025 font-normal tracking-tight">
            {`${memorySet.subscribedAgencies.length} ${t('subscribers')}`}
          </p>
        )}
        {!isAdmin && memorySet.status === MemorySetStatus.Draft && (
          <>
            <Layout.Spacer.Vertical size="atomic" />
            <p className="text-[10px] font-outfit text-negative font-normal">
              {t('temporarily-disabled-by-admin-please-check-back-later')}
            </p>
          </>
        )}
      </span>
    );
  };

  if (!isAdmin) {
    return <div className="px-6">{renderContent()}</div>;
  }

  return (
    <div className="px-6">
      <ContextMenu.ManagedOwner>
        <button
          type="button"
          className="text-start w-full"
          onClick={openMemorySet}
        >
          {renderContent()}
        </button>
        <div className="absolute top-0 ltr:right-0 rtl:left-0">
          <MemorySetOptions {...memorySetOptions} status={memorySet.status} />
        </div>
      </ContextMenu.ManagedOwner>
    </div>
  );
};

export default MemorySetListItem;

import { useSetAtom } from 'jotai';
import { useCallback, useState } from 'react';
import { ParseKeys } from 'i18next';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { loginAtom, LoginState, LoginType, useAuth } from '@advisor/api/auth';
import { useInvitationDetails } from '@advisor/onboarding';

export enum EmailFormState {
  Init = 'Init',
  Valid = 'Valid',
  Invalid = 'Invalid',
  Loading = 'Loading',
}

const Auth0ErrorShape = z.object({
  code: z.string().optional().nullable(), // Web return error with 'code' key
  name: z.string().optional().nullable(), // Mobile return error with 'name' key
});

const Auth0BadResponses = {
  'bad.email': 'bad-email',
} as const satisfies Record<string, ParseKeys<'onboarding'>>;

type Auth0BadResponsesKeys = keyof typeof Auth0BadResponses;

function isAuth0BadResponseKey(key: string): key is Auth0BadResponsesKeys {
  return key in Auth0BadResponses;
}

function getCodeError(error: unknown) {
  const result = Auth0ErrorShape.safeParse(error);

  if (result.success) {
    const key = result.data.code ?? result.data.name;

    if (key && isAuth0BadResponseKey(key)) {
      return Auth0BadResponses[key];
    }
  }

  return undefined;
}

export interface EmailInputProps {
  emailAddress: string;
  onEmailChange: (emailAddress: string) => void;
  disabled?: boolean;
  errorMessage?: string;
}

const initialState = {
  state: EmailFormState.Init,
  emailAddress: '',
};

export function useEmailForm() {
  const { t } = useTranslation('onboarding');
  const { authStart } = useAuth();
  const setLoginAtom = useSetAtom(loginAtom);

  const invitationDetails = useInvitationDetails();

  const [state, setState] = useState(
    invitationDetails?.email
      ? {
          emailAddress: invitationDetails.email,
          state: EmailFormState.Valid,
        }
      : initialState,
  );
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const onEmailChange = useCallback((emailAddress: string) => {
    setState({
      emailAddress,
      state: emailAddress.match(/\S+@\S+\.\S+/)
        ? EmailFormState.Valid
        : EmailFormState.Invalid,
    });
    setErrorMessage(undefined);
  }, []);

  const onSubmit = useCallback(async () => {
    setState((prev) => ({
      ...prev,
      state: EmailFormState.Loading,
    }));

    const emailAddress = state.emailAddress.toLocaleLowerCase();

    const result = await authStart(emailAddress);

    if (!result.success) {
      const errorCode = getCodeError(result.error);
      if (errorCode) {
        setErrorMessage(t(errorCode));
      }

      setState((prev) => ({
        ...prev,
        state: EmailFormState.Invalid,
      }));

      return;
    }

    setLoginAtom((prev) => ({
      ...prev,
      state: LoginState.Verification,
      type: LoginType.EmailAddress,
      emailAddress,
    }));
  }, [state, authStart, setLoginAtom, t]);

  return {
    ...state,
    errorMessage,
    onEmailChange,
    onSubmit,
  };
}

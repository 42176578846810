import React, { useMemo } from 'react';
import { useMolecule } from 'bunshi/react';
import { useSetAtom } from 'jotai';

import { MicrobotMemoryInfoFragment } from '@advisor/api/generated/graphqlTypes';
import { useEvent } from '@advisor/utils/hooks';
import { warnExhaustive } from '@advisor/utils/typeAssertions';
import AddMicrobotMemoryMolecule from '../../api/addMicrobotMemoryMolecule';
import { TextMemory, UrlMemory, FileMemory, CrawlMemory } from './components';
import {
  isUrlMemory,
  isFileMemory,
  isTextMemory,
  isCrawlMemory,
} from './utils';

const useMemoryListItem = (
  memory: MicrobotMemoryInfoFragment,
  hideMenu?: () => void,
) => {
  const { openEditMemoryAtom } = useMolecule(AddMicrobotMemoryMolecule);
  const startEditing = useSetAtom(openEditMemoryAtom);

  const editMemory = useEvent(() => {
    hideMenu?.();

    if (isTextMemory(memory)) {
      return startEditing(
        { mode: 'text', value: memory.value },
        { id: memory.id, retrievalType: null },
      );
    }

    if (isUrlMemory(memory)) {
      return startEditing(
        { mode: 'text', value: memory.url },
        { id: memory.id, retrievalType: memory.retrievalType },
      );
    }

    if (isFileMemory(memory)) {
      return null;
    }

    if (isCrawlMemory(memory)) {
      return null;
    }

    warnExhaustive(
      memory,
      '@advisor/client/microbots/components/MemoryListItem',
    );
    return null;
  });

  const memoryContent = useMemo(() => {
    if (isTextMemory(memory)) {
      return <TextMemory memory={memory} />;
    }

    if (isUrlMemory(memory)) {
      return <UrlMemory memory={memory} />;
    }

    if (isFileMemory(memory)) {
      return <FileMemory memory={memory} />;
    }

    if (isCrawlMemory(memory)) {
      return <CrawlMemory memory={memory} />;
    }

    warnExhaustive(
      memory,
      '@advisor/client/microbots/components/MemoryListItem',
    );
    return null;
  }, [memory]);

  return { editMemory, memoryContent };
};

export default useMemoryListItem;

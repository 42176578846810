import { useCancelBulkUploadMutation } from '@advisor/api/generated/graphql';
import { useEvent } from '@advisor/utils/hooks';

export default function useCancelBulkUpload() {
  const [cancelBulkUpload] = useCancelBulkUploadMutation();

  return useEvent(async (bulkUploadId?: string) => {
    if (!bulkUploadId) {
      return;
    }

    await cancelBulkUpload({
      variables: {
        id: bulkUploadId,
      },
    });
  });
}

import { atom } from 'jotai';

import { MemorySetStatus } from '@advisor/api/generated/graphqlTypes';

export interface CreateMemorySetState {
  type: 'create';
  title: string;
  description: string;
  status: MemorySetStatus;
}

export interface UpdateMemorySetState {
  type: 'update';
  title: string;
  description: string;
  memorySetId: string;
  status: MemorySetStatus;
  subscribedAgencies: Array<string>;
}

export type MemorySetActionSate = CreateMemorySetState | UpdateMemorySetState;

export const memorySetActionStateAtom = atom<MemorySetActionSate | null>(null);

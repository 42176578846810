import { atom, useAtomValue } from 'jotai';

import { useMe } from '@advisor/api/me';
import { Role } from '@advisor/api/user';
import { MemorySetInfoFragment } from '@advisor/api/generated/graphqlTypes';
import type { MemorySetTab } from './types';

export const activeTabAtom = atom<MemorySetTab | null>(null);

export function useActiveTab(): MemorySetTab {
  const activeTabAtomValue = useAtomValue(activeTabAtom);
  const me = useMe();

  if (activeTabAtomValue) {
    return activeTabAtomValue;
  }

  return Role.isMemorySetAdmin(me) ? 'published' : 'subscribed';
}

export const selectedMemorySetIdAtom = atom<MemorySetInfoFragment | null>(null);

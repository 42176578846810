import React from 'react';
import { useTranslation } from 'react-i18next';

import { MemorySetSubscriberList } from '@advisor/microbots/components';
import SideModal from '../SideModal';

const MemorySetsSubscribersModal: React.FC = () => {
  const { t } = useTranslation('microbot');

  return (
    <SideModal.Default
      scroll
      backButton
      padding={false}
      title={t('manage-subscribers')}
    >
      <MemorySetSubscriberList />
    </SideModal.Default>
  );
};

export default MemorySetsSubscribersModal;

import React from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import type { ListRenderItem } from 'react-native';

import { AgencyInfoFragment } from '@advisor/api/generated/graphqlTypes';

import { Button } from '@advisor/design/Button';
import {
  Layout,
  Header,
  FlatList,
  ErrorView,
} from '@advisor/design/components';

import useSubscribersList from './useSubscribersList';
import ListLoader from './ListLoader';

const keyExtractor = (item: AgencyInfoFragment) => item.name;

const MemorySetSubscriberList: React.FC = () => {
  const { isLoading, hasError, agencies, refetch } = useSubscribersList();
  const { t } = useTranslation('microbot');
  const navigate = useNavigate();

  const renderItem: ListRenderItem<AgencyInfoFragment> = ({ item }) => (
    <div className="p-4 border border-grey rounded-md">
      <h5 className="text-sm font-outfit font-semibold">{item.name}</h5>
      <Layout.Spacer.Vertical size="atomic" />
      <p className="text-xs font-outfit">
        {t('number-subscriptions', {
          number: String(item.subscribedMemorySetCount),
        })}
      </p>
      <Layout.Spacer.Vertical size="micro02" />
      <div>
        <Button
          variant="outlined"
          label={t('manage-subscription')}
          onPress={() => {
            navigate(
              `../preferences/memory-sets/subscribers/${encodeURIComponent(
                item.name,
              )}`,
            );
          }}
        />
      </div>
    </div>
  );

  const renderListHeader = () => (
    <Header.WithCount label={t('subscribers')} count={agencies.length} />
  );

  if (hasError) {
    return <ErrorView onTryAgain={refetch} />;
  }

  if (isLoading && agencies.length === 0) {
    return <ListLoader />;
  }

  return (
    <FlatList<AgencyInfoFragment>
      data={agencies}
      onRefresh={refetch}
      refreshing={isLoading}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      ListHeaderComponent={renderListHeader}
      className="px-6"
      ItemSeparatorComponent={() => <Layout.Spacer.Vertical size="small01" />}
    />
  );
};

export default MemorySetSubscriberList;

import { useAtom } from 'jotai';
import { useMemo, useState } from 'react';

import { useEvent } from '@advisor/utils/hooks';
import { showToast } from '@advisor/design/components/Toast';
import { milestoneEditStateAtom } from './atoms';
import useUpdateMilestone from './useUpdateMilestone';
import useMilestoneForm from './useMilestoneForm';
import { ModalTab } from './types';
import useDeleteMilestone from './useDeleteMilestone';
import useOpenJourneyCategory from './useOpenJourneyCategory';

function useEditMilestoneModal() {
  const [milestoneEditState, setMilestoneEditState] = useAtom(
    milestoneEditStateAtom,
  );

  const [activeTab, setActiveTab] = useState(ModalTab.Root);

  const updateMilestone = useUpdateMilestone();
  const deleteMilestone = useDeleteMilestone();

  const {
    formData: { validateAll, inputs },
    minDueDate,
  } = useMilestoneForm();
  const openCategory = useOpenJourneyCategory();

  const submit = useEvent(async (e?: React.FormEvent) => {
    e?.preventDefault();

    const validData = validateAll();

    if (!validData || !milestoneEditState) {
      return;
    }

    const success = await updateMilestone(
      milestoneEditState.milestone.chatRoomId,
      milestoneEditState.milestone,
      validData,
    );

    if (success) {
      showToast({
        messageI18Key: 'selected-task-was-edited',
        namespace: 'task-organiser',
        variant: 'blue',
        iconName: 'CircleCheck',
      });

      setMilestoneEditState(null);
    } else {
      showToast({
        messageI18Key: 'oops-something-went-wrong',
        variant: 'rose',
        iconName: 'ErrorCircle',
      });
    }
  });

  const handleDelete = useEvent(async () => {
    if (!milestoneEditState) {
      return;
    }

    const success = await deleteMilestone(milestoneEditState.milestone);

    if (success) {
      showToast({
        messageI18Key: 'selected-task-was-deleted',
        namespace: 'task-organiser',
        variant: 'rose',
        iconName: 'Delete',
      });

      setMilestoneEditState(null);
      openCategory(milestoneEditState.milestone);
    } else {
      showToast({
        messageI18Key: 'oops-something-went-wrong',
        variant: 'rose',
        iconName: 'ErrorCircle',
      });
    }
  });

  const closeModal = useEvent(() => {
    setMilestoneEditState(null);
  });

  const openRootTab = useEvent(() => {
    setActiveTab(ModalTab.Root);
  });

  const openDatePickerTab = useEvent(() => {
    setActiveTab(ModalTab.DatePicker);
  });

  const openDeleteConsentTab = useEvent(() => {
    setActiveTab(ModalTab.DeleteConsent);
  });

  return useMemo(
    () => ({
      minDueDate,
      activeTab,
      inputs,
      canDelete:
        !milestoneEditState?.milestone.advertisementKey ||
        !milestoneEditState?.milestone.isLocked,

      submit,
      closeModal,
      openRootTab,
      handleDelete,
      openDatePickerTab,
      openDeleteConsentTab,
    }),
    [
      inputs,
      activeTab,
      minDueDate,
      milestoneEditState?.milestone.advertisementKey,
      milestoneEditState?.milestone.isLocked,

      submit,
      closeModal,
      openRootTab,
      handleDelete,
      openDatePickerTab,
      openDeleteConsentTab,
    ],
  );
}

export default useEditMilestoneModal;

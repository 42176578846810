import { useAtom } from 'jotai';
import { useCallback, useMemo } from 'react';
import { ApolloCache, FetchResult } from '@apollo/client';

import { LoginType } from '@advisor/api/auth';
import {
  MeDocument,
  IdentityType,
  useCreateUserMutation,
  useOauthTokenMutation,
  CreateUserMutationVariables,
  usePasswordlessStartMutation,
  useUpdateUserIdentityMutation,
  useCreateFamilyMemberMutation,
  useCreateAdvisorProfileMutation,
  CreateAdvisorProfileMutationVariables,
  useCreateServiceProviderMutation,
  useUpdateUserMutation,
  MeFullInfoFragment,
  UpdateUserMutation,
  CreateUserMutation,
  CreateAdvisorProfileMutation,
  CreateFamilyMemberMutation,
  CreateServiceProviderMutation,
} from '@advisor/api/generated/graphql';
import Sentry from '@advisor/utils/Sentry';
import { parseGQLError } from '@advisor/utils/errorParsers';
import { useInvitation, useInvitationDetails } from '@advisor/onboarding';
import { useLanguage } from '@advisor/language';
import {
  errorsAtom,
  userProfileAtom,
  initialUserProfile,
  profileCreationStepAtom,
} from './atoms';
import {
  ProfileCreationStep,
  UserRole,
  VerificationState,
  VerifyError,
} from './types';

export type MeExtractor<TMutation> = (
  results: Omit<FetchResult<TMutation>, 'context'>,
) => MeFullInfoFragment | undefined | null;

const makeUpdateMeOptions = <TMutation>(extractMe: MeExtractor<TMutation>) => {
  return {
    update(
      cache: ApolloCache<unknown>,
      results: Omit<FetchResult<TMutation>, 'context'>,
    ) {
      cache.updateQuery(
        {
          query: MeDocument,
        },
        () => {
          const me = extractMe(results);

          if (!me) {
            return null;
          }

          return {
            __typename: 'Query' as const,
            me,
          };
        },
      );
    },
  };
};

const updateUserOptions = makeUpdateMeOptions<UpdateUserMutation>(
  ({ data }) => data?.updateUser,
);

const createUserOptions = makeUpdateMeOptions<CreateUserMutation>(
  ({ data }) => data?.createUser,
);

const createAdvisorProfileOptions =
  makeUpdateMeOptions<CreateAdvisorProfileMutation>(
    ({ data }) => data?.createAdvisorProfile,
  );

const createFamilyMemberOptions =
  makeUpdateMeOptions<CreateFamilyMemberMutation>(
    ({ data }) => data?.createFamilyMember,
  );

const createServiceProviderOptions =
  makeUpdateMeOptions<CreateServiceProviderMutation>(
    ({ data }) => data?.createServiceProvider,
  );

export default function useProfileCreation() {
  const { inviteType, clearInvitation } = useInvitation();
  const invitationDetails = useInvitationDetails();

  const [userProfile, setUserProfile] = useAtom(userProfileAtom);
  const [step, setStep] = useAtom(
    profileCreationStepAtom(
      invitationDetails?.email,
      invitationDetails?.phoneNumber,
    ),
  );

  const [errors, setErrors] = useAtom(errorsAtom);
  const { uiLanguage } = useLanguage();

  const [oauthTokenMutation] = useOauthTokenMutation();
  const [passwordlessStartMutation] = usePasswordlessStartMutation();
  const [updateUserIdentityMutation] = useUpdateUserIdentityMutation();

  const [updateUser] = useUpdateUserMutation(updateUserOptions);
  const [createUserMutation] = useCreateUserMutation(createUserOptions);
  const [createAdvisorProfileMutation] = useCreateAdvisorProfileMutation(
    createAdvisorProfileOptions,
  );
  const [createFamilyMemberMutation] = useCreateFamilyMemberMutation(
    createFamilyMemberOptions,
  );
  const [createServiceProviderMutation] = useCreateServiceProviderMutation(
    createServiceProviderOptions,
  );

  const needsVerification = useMemo(() => {
    if (
      userProfile.email.length &&
      userProfile.emailState === VerificationState.Unverified
    ) {
      return 'email';
    }

    if (
      userProfile.phoneNumber.length &&
      userProfile.phoneNumberState === VerificationState.Unverified
    ) {
      return 'phone';
    }

    return null;
  }, [userProfile]);

  const onOpenDetails = useCallback(
    (loginType: LoginType, login: string) => {
      setUserProfile((profile) => {
        if (loginType === LoginType.EmailAddress) {
          return {
            ...profile,
            email: login,
            emailState: VerificationState.Verified,
          };
        }

        return {
          ...profile,
          phoneNumber: login,
          phoneNumberState: VerificationState.Verified,
        };
      });

      setStep(ProfileCreationStep.Details);
    },
    [setStep, setUserProfile],
  );

  const onCreateProfile = useCallback(async () => {
    // If the user is completing sign in in via the 'student' type invite link
    // we update the already existing profile
    if (inviteType === 'student') {
      await updateUser({
        variables: {
          country: userProfile.country,
          timezone: userProfile.timezone,
        },
      });

      clearInvitation();
      return;
    }

    const commonVariables: CreateUserMutationVariables = {
      name: userProfile.name,
      country: userProfile.country,
      avatarUrl: userProfile.avatarUrl,
      language: uiLanguage,
      timezone: userProfile.timezone,
    };

    if (userProfile.role !== UserRole.ServiceProvider) {
      commonVariables.gender = userProfile.gender;
    }

    // Only one of the access methods can be used in createProfile
    // the other type will have to go through verification process.
    // ~~~~ breathe ~~~
    // `onCreateProfile` is called before verification start, so we can
    // assume that the field with verified flag is the one that
    // user has used in login form.
    if (userProfile.phoneNumberState === VerificationState.Verified) {
      commonVariables.phoneNumber = userProfile.phoneNumber;
    } else if (userProfile.emailState === VerificationState.Verified) {
      commonVariables.email = userProfile.email;
    }

    if (userProfile.role === UserRole.Advisor) {
      const variables: CreateAdvisorProfileMutationVariables = {
        ...commonVariables,
        region: userProfile.country,
        agency: userProfile.agency,
      };
      await createAdvisorProfileMutation({ variables });
    } else if (userProfile.role === UserRole.Student) {
      await createUserMutation({ variables: commonVariables });
    } else if (userProfile.role === UserRole.FamilyMember) {
      await createFamilyMemberMutation({ variables: commonVariables });
    } else {
      await createServiceProviderMutation({ variables: commonVariables });
    }

    // Reset form values
    setUserProfile(initialUserProfile);
  }, [
    inviteType,
    updateUser,
    uiLanguage,
    userProfile,
    setUserProfile,
    clearInvitation,
    createUserMutation,
    createFamilyMemberMutation,
    createAdvisorProfileMutation,
    createServiceProviderMutation,
  ]);

  const onOpenVerification = useCallback(async () => {
    const isPhone = needsVerification === 'phone';

    const credential = isPhone
      ? userProfile.phoneNumber.replace(/\s/g, '')
      : userProfile.email.toLocaleLowerCase();

    try {
      await passwordlessStartMutation({
        variables: {
          identityType: isPhone ? IdentityType.PhoneNumber : IdentityType.Email,
          credential,
          language: uiLanguage,
        },
      });
    } catch (e) {
      Sentry.captureException(e);
    }

    setStep(ProfileCreationStep.Verification);
  }, [
    setStep,
    uiLanguage,
    userProfile,
    needsVerification,
    passwordlessStartMutation,
  ]);

  const onVerify = useCallback(
    async (verificationCode: string) => {
      const isPhone = needsVerification === 'phone';

      const credential = isPhone
        ? userProfile.phoneNumber.replace(/\s/g, '')
        : userProfile.email.toLocaleLowerCase();

      try {
        const results = await oauthTokenMutation({
          variables: {
            identityType: isPhone
              ? IdentityType.PhoneNumber
              : IdentityType.Email,
            credential,
            otp: verificationCode,
          },
        });

        if (results.data?.oauthToken.idToken) {
          await updateUserIdentityMutation({
            variables: isPhone
              ? {
                  phoneNumber: credential,
                  isPhoneNumberVerified: true,
                  linkWith: results.data.oauthToken.idToken,
                }
              : {
                  email: credential,
                  isEmailVerified: true,
                  linkWith: results.data.oauthToken.idToken,
                },
          });

          if (isPhone) {
            setUserProfile({
              ...userProfile,
              phoneNumberState: VerificationState.Verified,
            });
          } else {
            setUserProfile({
              ...userProfile,
              emailState: VerificationState.Verified,
            });
          }

          // Happy path: successfully linked second auth0 profile
          setStep(ProfileCreationStep.Details);
          return { success: true };
        }

        await updateUserIdentityMutation({
          variables: isPhone
            ? {
                phoneNumber: credential,
                isPhoneNumberVerified: false,
              }
            : {
                email: credential,
                isEmailVerified: false,
              },
        });

        // Shouldn't really happen path
        // We didn't received the auth0 tokens
        // but requests finished without errors
        setStep(ProfileCreationStep.Details);
        return { success: false };
      } catch (e) {
        const errorType = parseGQLError(e);

        if (
          errorType === VerifyError.WrongCredentailsEmail ||
          errorType === VerifyError.WrongCredentialsPhone
        ) {
          // User made errors while filling the form
          // Stay at verification step
          return { success: false };
        }

        // Credential already taken
        // Go to details step and show the error to the user
        if (errorType === VerifyError.PhoneNumberAlreadyTakenError) {
          setErrors({ phoneNumber: true });
        } else if (errorType === VerifyError.EmailAddressAlreadyTakenError) {
          setErrors({ email: true });
        } else {
          Sentry.captureException(e);
        }

        setStep(ProfileCreationStep.Details);
        return {
          success: false,
        };
      }
    },
    [
      setStep,
      setErrors,
      userProfile,
      setUserProfile,
      needsVerification,
      oauthTokenMutation,
      updateUserIdentityMutation,
    ],
  );

  const onSkipVerification = useCallback(async () => {
    const isPhone = needsVerification === 'phone';

    const credential = isPhone
      ? userProfile.phoneNumber.replace(/\s/g, '')
      : userProfile.email.toLocaleLowerCase();

    try {
      await updateUserIdentityMutation({
        variables: isPhone
          ? {
              phoneNumber: credential,
              isPhoneNumberVerified: false,
            }
          : {
              email: credential,
              isEmailVerified: false,
            },
      });
    } catch (e) {
      const errorType = parseGQLError(e);

      if (errorType === VerifyError.PhoneNumberAlreadyTakenError) {
        setErrors({ phoneNumber: true });
      } else if (errorType === VerifyError.EmailAddressAlreadyTakenError) {
        setErrors({ email: true });
      } else {
        Sentry.captureException(e);
      }
    }
  }, [needsVerification, userProfile, setErrors, updateUserIdentityMutation]);

  return {
    step,
    errors,
    userProfile,
    needsVerification,

    setStep,
    onVerify,
    setErrors,
    onOpenDetails,
    setUserProfile,
    onCreateProfile,
    onSkipVerification,
    onOpenVerification,
  } as const;
}

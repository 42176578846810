import React from 'react';
import { useTranslation } from 'react-i18next';

import { MemorySetStatus } from '@advisor/api/generated/graphqlTypes';
import { ContextMenu } from '@advisor/design/components';

interface MemorySetOptionsProps {
  status: MemorySetStatus;
  editMemorySet: () => void;
  deleteMemorySet: () => void;
  toggleVisibility: () => void;
}

const MemorySetOptions: React.FC<MemorySetOptionsProps> = (props) => {
  const { status, editMemorySet, deleteMemorySet, toggleVisibility } = props;
  const isActive = status === MemorySetStatus.Active;

  const { t } = useTranslation(['microbot', 'common']);

  return (
    <ContextMenu.Root triggerTestID="memorySetOptions">
      <ContextMenu.Item
        iconName={isActive ? 'Draft' : 'Publish'}
        onPress={toggleVisibility}
        label={isActive ? t('microbot:move-to-draft') : t('microbot:publish')}
        color="darkGrey02"
        testID="toggleMemorySetVisibility"
      />
      <ContextMenu.Item
        iconName="Edit"
        onPress={editMemorySet}
        label={t('microbot:edit-memory-set')}
        color="darkGrey02"
        testID="editMemorySet"
      />
      <ContextMenu.Item
        destructive
        color="negative"
        iconName="Delete"
        disabled={isActive}
        testID="deleteMemorySet"
        onPress={deleteMemorySet}
        label={t('microbot:remove-memory-set')}
      />
    </ContextMenu.Root>
  );
};

export default MemorySetOptions;

import cs from 'classnames';
import React, { memo, useMemo } from 'react';

import { warnExhaustive } from '@advisor/utils/typeAssertions';
import Icon, { SpinnerIcon } from '../components/Icon';
import {
  Size,
  Color,
  Rounded,
  IconButtonProps,
  IconButtonVariant,
} from './types';
import {
  toTWColor,
  useIsLoading,
  getTWContrast,
  useStableIconButtonProps,
} from './utils';

/* eslint-disable react/button-has-type */

const IconButton: React.FC<IconButtonProps> = (props) => {
  const { isLoading, onPress } = useIsLoading(props);

  const { size, type, name, color, testID, variant, rounded, disabled } =
    useStableIconButtonProps(props);

  const iconSize = useMemo(() => withIconSize(size), [size]);

  const className = useMemo(
    () =>
      cs(
        'relative flex items-center justify-center transition-all',
        disabled && 'opacity-40',
        withSizeClassNames(variant, rounded, size),
        withVariantClassNames(variant, color, disabled),
      ),
    [color, disabled, rounded, variant, size],
  );

  return (
    <button
      type={type}
      data-cy={testID}
      onClick={onPress}
      disabled={disabled || isLoading}
      className={className}
    >
      {isLoading && (
        <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center">
          <SpinnerIcon className={iconSize} />
        </div>
      )}
      <Icon
        name={name}
        className={cs(iconSize, isLoading ? 'opacity-0' : '')}
      />
    </button>
  );
};

export default memo(IconButton);

function withVariantClassNames(
  variant: IconButtonVariant,
  color: Color,
  disabled: boolean,
): string {
  const colorName = toTWColor(color);
  const contrastName = getTWContrast(color);

  if (variant === 'contained') {
    return cs(
      `bg-${colorName} ${contrastName}`,
      !disabled &&
        'before:content-[""] before:absolute before:inset-0 before:bg-black before:opacity-0 before:rounded-lg before:transition-all hover:before:opacity-5',
    );
  }

  if (variant === 'outlined') {
    return cs(
      'border bg-white transition-all',
      `text-${colorName}`,
      `border-${colorName}`,
      !disabled && `hover:bg-${colorName} hover:${contrastName}`,
    );
  }

  if (variant === 'bare') {
    return `text-${colorName}`;
  }

  warnExhaustive(variant, '@design/Button/IconButton.tsx');
  return '';
}

function withSizeClassNames(
  variant: IconButtonVariant,
  rounded: Rounded,
  size: Size,
): string {
  if (variant === 'bare') {
    return '';
  }

  const cornerRadius = {
    small: 'rounded-sm',
    medium: 'rounded',
    large: 'rounded-md',
    flex: 'rounded',
  }[size];

  const roundedClass = {
    none: '',
    default: cornerRadius,
    full: 'rounded-full',
  }[rounded];

  return cs(roundedClass);
}

function withIconSize(size: Size): string {
  return {
    small: 'w-4 h-4',
    medium: 'w-6 h-6',
    large: 'w-8 h-8',
    flex: 'w-6 h-6',
  }[size];
}

/*
Tailwind clases that are dynamically produced by this component.
This comment is included in order to enforce tailwind parser to generate them.

  bg-primary
  bg-primary-light
  bg-primary-light-05
  bg-primary-dark
  bg-secondary
  bg-secondary-light
  bg-secondary-dark

  bg-positive
  bg-negative
  bg-dark-grey-01
  bg-dark-grey-02
  bg-cyan
  bg-purple
  bg-purple-dark

  text-primary
  text-primary-light
  text-primary-light-05
  text-primary-dark
  text-secondary
  text-secondary-light
  text-secondary-dark

  text-positive
  text-negative
  text-dark-grey-01
  text-dark-grey-02
  text-cyan
  text-purple
  text-purple-dark

  text-white
  text-dark-grey-03

  border-primary
  border-primary-light
  border-primary-light-05
  border-primary-dark
  border-secondary
  border-secondary-light
  border-secondary-dark

  border-positive
  border-negative
  border-dark-grey-01
  border-dark-grey-02
  border-cyan
  border-purple
  border-purple-dark
 */

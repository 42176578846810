import React from 'react';
import { useTranslation } from 'react-i18next';

import { DeprecatedButton, Layout } from '@advisor/design/components';
import SwipeTransition from './SwipeTransition';
import { DeleteTabProps, ModalTab } from './types';

function DeleteMilestoneTab(props: DeleteTabProps) {
  const { activeTab, onOpenRoot, onDelete } = props;
  const { t } = useTranslation(['common', 'task-organiser']);

  return (
    <SwipeTransition
      direction="right"
      show={activeTab === ModalTab.DeleteConsent}
    >
      <div className="py-8 px-10">
        <h4 className="font-sora text-center font-bold mb-3">
          {t('task-organiser:are-you-sure-you-want-to-delete-this-task')}
        </h4>
        <p className="text-center mb-6">
          {t('common:this-action-cannot-be-reversed')}
        </p>
        <DeprecatedButton.Large variant="negative" onPress={onDelete}>
          {t('common:delete')}
        </DeprecatedButton.Large>
        <Layout.Spacer.Vertical size={16} />
        <DeprecatedButton.Large
          outline
          variant="darkGrey03"
          onPress={onOpenRoot}
        >
          {t('common:cancel')}
        </DeprecatedButton.Large>
      </div>
    </SwipeTransition>
  );
}

export default DeleteMilestoneTab;

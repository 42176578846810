import React from 'react';

import { Layout, Skeleton } from '@advisor/design/components';

const ListLoader: React.FC = () => (
  <>
    <Layout.Spacer.Vertical size="small01" />
    <LoaderItem />
    <Layout.Spacer.Vertical size="small01" />
    <LoaderItem />
    <Layout.Spacer.Vertical size="small01" />
    <LoaderItem />
  </>
);

export default ListLoader;

const LoaderItem: React.FC = () => (
  <div className="border border-grey p-4 rounded-md mx-6">
    <Skeleton.Text fontSize={14} lineHeight={20} size={140} />
    <Layout.Spacer.Vertical size="atomic" />
    <Skeleton.Text fontSize={12} lineHeight={16} size={80} />
    <Layout.Spacer.Vertical size="micro02" />
    <Skeleton.Ping>
      <div className="w-[100px] h-[24px] border border-primary-light bg-primary-light rounded-md" />
    </Skeleton.Ping>
  </div>
);

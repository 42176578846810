import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useLanguage } from '@advisor/language';
import color from '@advisor/design/Theme/color';
import { useTheme } from '@advisor/design/Theme';
import { DeprecatedButton, Icon, Layout } from '@advisor/design/components';
import CircularProgressBar from '@advisor/design/components/CircularProgressBar';
import useProcessingUploads from '../useProcessingUploads';
import { getFinishedPercentage } from '../utils';
import useTitle from './useTitle';

const ProcessingUploadsWidget: React.FC = () => {
  const theme = useTheme();
  const { isRTL } = useLanguage();
  const { t } = useTranslation('microbot');

  const { openItem, showNextItem, currentIndex, currentItem, totalCount } =
    useProcessingUploads();

  const title = useTitle(currentItem, currentIndex, totalCount);

  const containerStyle = useMemo(
    () => ({
      backgroundColor: color(theme.colors.primary)
        .alphaBlend('#FFFFFF', 0.15)
        .stringify(),
    }),
    [theme.colors.primary],
  );

  if (!currentItem) {
    return null;
  }

  return (
    <div
      style={containerStyle}
      className="px-6 py-4 flex flex-row items-center"
    >
      <button
        type="button"
        className="flex ltr:text-left rtl:text-right ltr:flex-row rtl:flex-row-reverse items-center w-full"
        onClick={openItem}
      >
        {currentItem.__typename === 'MicrobotBulkUpload' ? (
          <CircularProgressBar
            size={40}
            progress={getFinishedPercentage(currentItem)}
          />
        ) : (
          <div className="w-10 h-10 flex items-center justify-center border-2 border-primary-light rounded-full">
            <Icon name="LinkTriple" size={24} color="primary" />
          </div>
        )}
        <Layout.Spacer.Horizontal size="small01" />
        <div className="font-outfit text-primary text-sm max-w-[220px]">
          {title}
          <br />
          {t('tap-here-to-view-details')}
        </div>
      </button>
      {totalCount > 1 && (
        <DeprecatedButton.Icon
          icon={isRTL ? 'ChevronLeft' : 'ChevronRight'}
          className="text-primary"
          onPress={showNextItem}
          size={24}
        />
      )}
    </div>
  );
};

export default ProcessingUploadsWidget;

import Papa from 'papaparse';
import { produce } from 'immer';
import { createScope } from 'bunshi';
import { ApolloCache } from '@apollo/client';

import type { FileData } from '@advisor/api/files/types';
import { readTextFileContent } from '@advisor/api/files';
import {
  CrawlMemoriesQuery,
  MicrobotMemoriesQuery,
  CrawlMemoriesDocument,
  MicrobotMemoriesDocument,
  MemoryBankBulkUploadsQuery,
  MemoryBankBulkUploadsDocument,
  MicrobotMemoryEdgeInfoFragment,
  MicrobotBulkUploadInfoFragment,
  MicrobotCrawlMemoryInfoFragment,
} from '@advisor/api/generated/graphqlTypes';

import Sentry from '@advisor/utils/Sentry';

import type { EditMemoryState } from './types';

export const initialEditMemoryState: EditMemoryState = {
  id: null,
  retrievalType: null,
};

export const UrlTextContentRegex = /^https?:\/\/\S+/;

export const MemorySetScope = createScope<string | null>(null);

export const AddMicrobotMemoryScope = createScope<
  'chat-room' | 'preferences' | 'invalid'
>('invalid');

export function isUrlContent(content: string) {
  return UrlTextContentRegex.test(content);
}

export function isValidBulkUpload(contents: unknown[]): boolean {
  if (contents.length < 2) {
    return false;
  }

  const headerRow = contents[0];

  if (headerRow instanceof Array === false) {
    return false;
  }

  if (typeof headerRow[0] !== 'string' || typeof headerRow[1] !== 'string') {
    return false;
  }

  return (
    headerRow[0].toLocaleLowerCase() === 'url/text' &&
    headerRow[1].toLocaleLowerCase() === 'continuous retrieval'
  );
}

export async function getUploadType(file: FileData, canBulkUpload?: boolean) {
  if (file.type !== 'text/csv') {
    return 'file';
  }

  try {
    const fileContent = await readTextFileContent(file);

    const csv = Papa.parse(fileContent);

    if (isValidBulkUpload(csv.data) && canBulkUpload) {
      return 'bulk';
    }

    return 'csv';
  } catch (e) {
    Sentry.captureException(e);
    return 'file';
  }
}

function withDefault<Query>(defaultValue: Query, query?: Query | null): Query {
  if (!query) {
    return defaultValue;
  }

  return query;
}

const defaultMemoriesQuery: MicrobotMemoriesQuery = {
  __typename: 'Query',
  microbotMemories: {
    __typename: 'MicrobotMemoryConnection',
    count: 0,
    edges: [],
    pageInfo: {
      __typename: 'PageInfo',
      hasNextPage: false,
      hasPreviousPage: false,
      startCursor: null,
      endCursor: null,
    },
  },
};

const defaultBulkUploadsQuery: MemoryBankBulkUploadsQuery = {
  __typename: 'Query',
  memoryBankBulkUploads: [],
};

const defaultCrawlMemoriesQuery: CrawlMemoriesQuery = {
  __typename: 'Query',
  crawlMemories: [],
};

export function updateMemoriesQuery(
  cache: ApolloCache<unknown>,
  edge: MicrobotMemoryEdgeInfoFragment,
  memorySetId?: string | null,
) {
  cache.updateQuery(
    {
      query: MicrobotMemoriesDocument,
      variables: { searchQuery: '', memorySetId: memorySetId ?? undefined },
    },
    (current) => {
      return produce(withDefault(defaultMemoriesQuery, current), (draft) => {
        const edgeIndex = draft.microbotMemories.edges.findIndex(
          ({ node }) => node.id === edge.node.id,
        );

        if (edgeIndex === -1) {
          draft.microbotMemories.edges.unshift(edge);
          draft.microbotMemories.count += 1;
          draft.microbotMemories.pageInfo.startCursor = edge.cursor;
        } else {
          draft.microbotMemories.edges[edgeIndex] = edge;
        }
      });
    },
  );
}

export function updateBulkUploadsQuery(
  cache: ApolloCache<unknown>,
  memory: MicrobotBulkUploadInfoFragment,
  memorySetId?: string | null,
) {
  cache.updateQuery(
    {
      query: MemoryBankBulkUploadsDocument,
      variables: { memorySetId: memorySetId ?? undefined },
    },
    (current) => {
      return produce(withDefault(defaultBulkUploadsQuery, current), (draft) => {
        const memoryIndex = draft.memoryBankBulkUploads.findIndex(
          ({ id }) => id === memory.id,
        );

        if (memoryIndex === -1) {
          draft.memoryBankBulkUploads.unshift(memory);
        } else {
          draft.memoryBankBulkUploads[memoryIndex] = memory;
        }
      });
    },
  );
}

export function updateCrawlMemoriesQuery(
  cache: ApolloCache<unknown>,
  memory: MicrobotCrawlMemoryInfoFragment,
  memorySetId?: string | null,
) {
  cache.updateQuery(
    {
      query: CrawlMemoriesDocument,
      variables: {
        status: memory.status,
        memorySetId: memorySetId ?? null,
      },
    },
    (current) => {
      return produce(
        withDefault(defaultCrawlMemoriesQuery, current),
        (draft) => {
          const memoryIndex = draft.crawlMemories.findIndex(
            ({ id }) => id === memory.id,
          );

          if (memoryIndex === -1) {
            draft.crawlMemories.unshift(memory);
          } else {
            draft.crawlMemories[memoryIndex] = memory;
          }
        },
      );
    },
  );
}

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@advisor/design/Button';
import { MemorySetStatus } from '@advisor/api/generated/graphqlTypes';
import useMemorySetOptions from '../MemorySetList/useMemorySetOptions';
import { DraftMemorySetBannerProps } from './types';

const DraftMemorySetBanner: React.FC<DraftMemorySetBannerProps> = (props) => {
  const { memorySet } = props;
  const { t } = useTranslation('microbot');

  const { toggleVisibility } = useMemorySetOptions(memorySet);

  if (!memorySet || memorySet.status !== MemorySetStatus.Draft) {
    return null;
  }

  return (
    <div className="p-6 bg-primary-light border border-light-grey flex flex-row">
      <div className="flex-1">
        <p className="font-outfit text-sm font-semibold">
          {t('this-memory-set-is-in-draft-mode')}
        </p>
        <p className="font-outfit text-sm font-normal">
          {t('publish-it-to-enable-subscriber-access')}
        </p>
      </div>
      <div>
        <Button
          color="white"
          variant="contained"
          label={t('publish')}
          onPress={toggleVisibility}
        />
      </div>
    </div>
  );
};

export default DraftMemorySetBanner;

import { unwrap } from 'jotai/utils';
import { isApolloError } from '@apollo/client';

import {
  MemoryRetrievalType,
  CheckIsUrlValidDocument,
} from '@advisor/api/generated/graphqlTypes';
import { clientAtom } from '@advisor/api/apollo';
import { Feature, featureEnabledAtoms } from '@advisor/api/feature';

import Sentry from '@advisor/utils/Sentry';
import { soloActionAtoms } from '@advisor/utils/atoms';

import { UrlTextContentRegex } from '../utils';
import createSubmitAtom from './createSubmitAtom';
import {
  EditorStateAtom,
  ErrorMessageKeyAtom,
  EditMemoryStateAtom,
} from './types';

export default function createAdvanceAtom(
  editorStateAtom: EditorStateAtom,
  editMemoryStateAtom: EditMemoryStateAtom,
  errorMessageKeyAtom: ErrorMessageKeyAtom,
) {
  const bulkUploadsEnabledAtom = featureEnabledAtoms(Feature.BulkUpload);
  const urlsEnabledAtom = featureEnabledAtoms(Feature.DataHarvestingUrl);

  const submitAtom = createSubmitAtom(editorStateAtom, editMemoryStateAtom);

  const [, advanceAtom] = soloActionAtoms(async ({ get, set }) => {
    const state = get(editorStateAtom);
    const client = await get(clientAtom);
    const editMemoryState = get(editMemoryStateAtom);
    const canBulkUpload = get(unwrap(bulkUploadsEnabledAtom));

    const { retrievalType } = editMemoryState;

    if (!state) {
      return;
    }

    // require user confirmation for bulk upload
    if (state.mode === 'file' && state.uploadType !== 'file' && canBulkUpload) {
      set(editorStateAtom, {
        mode: 'confirm',
        value: state.value,
        uploadType: state.uploadType,
      });
      return;
    }

    if (state.mode !== 'text') {
      await set(submitAtom);
      return;
    }

    // Are we sending text? Perhaps it is a URL
    const isUrlsEnabled = await get(urlsEnabledAtom);
    const urlRegexResult = UrlTextContentRegex.exec(state.value.trim());

    if (urlRegexResult == null) {
      await set(submitAtom);
      return;
    }

    // It is a url
    if (!isUrlsEnabled) {
      set(errorMessageKeyAtom, 'microbot:urls-are-not-supported');
      return;
    }

    let valid = false;

    try {
      const result = await client.mutate({
        mutation: CheckIsUrlValidDocument,
        variables: {
          url: urlRegexResult[0],
        },
      });

      valid = result.data?.checkIsUrlValid ?? false;
    } catch (e) {
      if (!(e instanceof Error) || !isApolloError(e)) {
        Sentry.captureException(e);
      }
    }

    if (!valid) {
      set(errorMessageKeyAtom, 'microbot:invalid-inaccessible-or-broken-url');
      return;
    }

    if (state.crawl) {
      await set(submitAtom);
      return;
    }

    set(editorStateAtom, {
      mode: 'url',
      value: urlRegexResult[0],
      retrievalType: retrievalType || MemoryRetrievalType.Once,
    });
  });

  return advanceAtom;
}

import { useAtom } from 'jotai';
import { useParams } from 'react-router';
import { ScopeProvider } from 'bunshi/react';
import { useTranslation } from 'react-i18next';
import type { ListRenderItem } from 'react-native';
import React, { useCallback, useEffect, useMemo } from 'react';

import { type MicrobotMemoryInfoFragment } from '@advisor/api/generated/graphql';
import { Feature } from '@advisor/api/feature';
import {
  useMemorySets,
  MemorySetScope,
  AddMicrobotMemoryScope,
  ActiveMemorySetProvider,
} from '@advisor/microbots/api';
import { PermissionGate, Scope } from '@advisor/api/scope';
import {
  Header,
  Layout,
  FlatList,
  ErrorView,
  SearchInput,
} from '@advisor/design/components';
import {
  MemoryListItem,
  AddMemoryButton,
  MemoryListLoader,
  ProcessingUploadModal,
  ProcessingUploadsWidget,
  DraftMemorySetBanner,
} from '@advisor/microbots/components';
import suspenseHOC from '@advisor/utils/suspenseHOC';
import searchQueryAtom from '@advisor/microbots/atoms/searchQueryAtom';
import { useMicrobotMemories } from '@advisor/microbots/hooks';
import SideModal from '../SideModal';

const keyExtractor = (item: MicrobotMemoryInfoFragment) => item.id;

const MemorySetDetailsModal: React.FC = () => {
  const { t } = useTranslation('microbot');
  const { memorySetId } = useParams<{ memorySetId: string }>();
  const [searchQuery, setSearchQuery] = useAtom(searchQueryAtom);

  const { error, loading, hasMore, refetch, loadMore, memories, count } =
    useMicrobotMemories(memorySetId);

  const { memorySets } = useMemorySets();

  const onChange = (newValue: string) => {
    setSearchQuery(newValue);
  };

  const memorySet = useMemo(() => {
    if (!memorySetId) {
      return null;
    }

    return memorySets.memorySetMap[memorySetId];
  }, [memorySets, memorySetId]);

  useEffect(() => {
    setSearchQuery('');
  }, [setSearchQuery]);

  const renderListLoadingIndicator = useCallback(() => {
    if (!loading && !hasMore) {
      return null;
    }

    return <MemoryListLoader />;
  }, [loading, hasMore]);

  const renderMemory: ListRenderItem<MicrobotMemoryInfoFragment> = useCallback(
    ({ item }) => <MemoryListItem memory={item} />,
    [],
  );

  if (error || !memorySetId) {
    return (
      <div className="w-full">
        <Layout.Spacer.Vertical size="medium" />
        <ErrorView onTryAgain={() => refetch()} />
      </div>
    );
  }

  return (
    <ScopeProvider scope={AddMicrobotMemoryScope} value="preferences">
      <ScopeProvider scope={MemorySetScope} value={memorySetId}>
        <ActiveMemorySetProvider memorySetId={memorySetId}>
          <SideModal.Default
            scroll
            backButton
            padding={false}
            title={
              memorySet
                ? t('name-memory-set', { name: memorySet.title })
                : t('memory-set')
            }
          >
            <div className="flex flex-col flex-1 min-h-0 max-h-full overflow-x-hidden">
              <DraftMemorySetBanner memorySet={memorySet} />
              <PermissionGate scope={Scope.processingUploadsAccess}>
                {() => <ProcessingUploadsWidget />}
              </PermissionGate>
              <div className="px-6 pt-6">
                <SearchInput
                  className="py-2 ltr:pl-11 rtl:pr-11 placeholder:text-gray-01 placeholder:font-normal text-sm"
                  onChange={onChange}
                  value={searchQuery}
                  placeholder={t('search-in-alpha-bot-memory-bank')}
                />
              </div>
              <div className="flex flex-row justify-between items-center px-6">
                <Header.WithCount
                  label={t('saved-memories')}
                  count={count ?? 0}
                />
              </div>
              <FlatList
                className="overflow-y-auto overflow-x-visible w-full max-h-full"
                contentContainerClassName="gap-3 flex-col px-6 pb-6"
                data={memories}
                renderItem={renderMemory}
                keyExtractor={keyExtractor}
                ListFooterComponent={renderListLoadingIndicator}
                onEndReached={loadMore}
              />
            </div>

            <PermissionGate scope={Scope.feature(Feature.MemoryBank)}>
              {() => <AddMemoryButton.MemoryBank />}
            </PermissionGate>
            <PermissionGate scope={Scope.processingUploadsAccess}>
              {() => <ProcessingUploadModal />}
            </PermissionGate>
          </SideModal.Default>
        </ActiveMemorySetProvider>
      </ScopeProvider>
    </ScopeProvider>
  );
};

export default suspenseHOC(MemorySetDetailsModal);

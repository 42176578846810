import { atom } from 'jotai';

import { isUploaded } from '@advisor/design/components/FileAttachment';

import { EditorStateAtom } from './types';

export default function createCanAdvanceAtom(editorStateAtom: EditorStateAtom) {
  return atom((get) => {
    const state = get(editorStateAtom);

    if (state?.mode === 'text') {
      return state.value.length > 0;
    }

    if (state?.mode === 'file') {
      return isUploaded(state.value);
    }

    if (state?.mode === 'url') {
      return true;
    }

    return false;
  });
}

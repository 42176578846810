import cs from 'classnames';
import { useAtom } from 'jotai';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { loginAtom, LoginType, LoginState, FlowType } from '@advisor/api/auth';
import { useEmailForm, EmailFormState } from '@advisor/chat/hooks/useEmailForm';
import { Layout, DeprecatedButton } from '@advisor/design/components';
import {
  Note,
  Form,
  Title,
  Subtitle,
  TermsOfUse,
  ConfirmButton,
} from '@advisor/design/components/LoginComponents';
import { useInvitationDetails } from '@advisor/onboarding';
import EmailInput from './EmailInput';
import InviteDisclosure from './InviteDisclosure';

const EmailForm: React.FC = () => {
  const { t } = useTranslation('onboarding');
  const [login, setLogin] = useAtom(loginAtom);
  const invitationDetails = useInvitationDetails();
  const { state, onSubmit, ...inputProps } = useEmailForm();

  const onSignInWithPhone = () => {
    setLogin((prev) => ({
      state: LoginState.Initial,
      type: LoginType.PhoneNumber,
      flowType: prev.flowType,
    }));
  };

  return (
    <Form
      className={cs(login.flowType === FlowType.Delete && 'flex-none')}
      onSubmit={onSubmit}
    >
      <div>
        <Title className="!text-2xl">{t('enter-your-email')}</Title>
        <Subtitle className="font-normal">
          {login.flowType === FlowType.Delete
            ? t('verify-your-account-before-deleting')
            : t('we-will-send-you-a-verification-code')}
        </Subtitle>
        <Layout.Spacer.Vertical size="small01" />
        <EmailInput disabled={!!invitationDetails?.email} {...inputProps} />
        <ConfirmButton disabled={state !== EmailFormState.Valid}>
          {t('send-verification-code-lower')}
        </ConfirmButton>
        <Layout.Spacer.Vertical size="small01" />
        {((!invitationDetails?.email && !invitationDetails?.phoneNumber) ||
          invitationDetails?.phoneNumber) && (
          <DeprecatedButton.Text
            underline
            weight={700}
            onPress={onSignInWithPhone}
          >
            {login.flowType === FlowType.Delete
              ? t('delete-account-with-phone-number')
              : t('sign-in-with-phone-number')}
          </DeprecatedButton.Text>
        )}
      </div>
      <div>
        {invitationDetails?.email && <InviteDisclosure />}
        {login.flowType === FlowType.Delete ? (
          <>
            <Layout.Spacer.Vertical size="small01" />
            <Note>{t('delete-account-security-note')}</Note>
          </>
        ) : (
          <TermsOfUse />
        )}
      </div>
    </Form>
  );
};

export default memo(EmailForm);

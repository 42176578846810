import { atom, PrimitiveAtom } from 'jotai';

import { MilestoneInfoFragment } from '@advisor/api/generated/graphql';

export type MilestoneEditState = {
  milestone: MilestoneInfoFragment;
  datePickerShownAtom: PrimitiveAtom<boolean>;
};

export const milestoneEditStateAtom = (() => {
  const internalAtom = atom<MilestoneEditState | null>(null);

  return atom(
    (get) => get(internalAtom),
    (_get, set, milestone: MilestoneInfoFragment | null) => {
      if (milestone) {
        set(internalAtom, {
          milestone,
          datePickerShownAtom: atom(false),
        });
      } else {
        set(internalAtom, null);
      }
    },
  );
})();

export type MilestoneAddState = {
  datePickerShownAtom: PrimitiveAtom<boolean>;
};

export const milestoneAddStateAtom = (() => {
  const internalAtom = atom<MilestoneAddState | null>(null);

  return atom(
    (get) => get(internalAtom),
    (_get, set, enabled: boolean) => {
      if (enabled) {
        set(internalAtom, {
          datePickerShownAtom: atom(false),
        });
      } else {
        set(internalAtom, null);
      }
    },
  );
})();

import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Icon,
  Layout,
  DeprecatedButton,
  Checkbox,
} from '@advisor/design/components';
import type { CrawlMemoryContentProps } from './types';

const CrawlMemoryContentProps: React.FC<CrawlMemoryContentProps> = ({
  item,
}) => {
  const { t } = useTranslation('microbot');

  return (
    <div className="flex flex-col gap-4 py-4 border border-grey rounded-xl">
      <div className="flex items-center justify-center gap-3 py-4 px-8">
        <Icon name="LinkTriple" size={32} className="text-primary" />
        <a
          className="font-outfit font-medium text-sm leading-4-5 text-primary break-all"
          href={item.url}
          target="_blank"
          rel="noreferrer"
        >
          {item.url}
        </a>
      </div>
      <div className="h-[1px] bg-light-grey" />
      <div className="p-4">
        <DeprecatedButton.Bare className="flex text-dark-grey-02" disabled>
          <Checkbox checked disabled />
          <Layout.Spacer.Horizontal size="micro" />
          <span className="text-sm">{t('enable-full-website-scrape')}</span>
        </DeprecatedButton.Bare>
        <Layout.Spacer.Vertical size="micro02" />
        <span className="text-xs font-medium">
          {t('this-will-collect-data-from-all-pages-within-the-site')}
        </span>
      </div>
    </div>
  );
};

export default memo(CrawlMemoryContentProps);

import React, { memo } from 'react';

import { useIsFeatureEnabled, Feature } from '@advisor/api/feature';
import { SystemMessageType } from '@advisor/api/generated/graphql';
import { SystemMessageProps } from './types';
import templates from './templates';

const StudentJourneyTypes = [
  SystemMessageType.AllMilestonesCompletedInCategory,
  SystemMessageType.FirstInteraction,
  SystemMessageType.FirstMilestoneCompleted,
  SystemMessageType.MilestoneAdded,
  SystemMessageType.MilestoneDueDateReminder,
  SystemMessageType.MilestoneEdited,
  SystemMessageType.MilestoneMarkedCompleteByAdvisor,
  SystemMessageType.MilestoneMarkedCompleteByStudent,
  SystemMessageType.MilestoneMarkedIncompleteByAdvisor,
  SystemMessageType.MilestoneMarkedIncompleteByStudent,
  SystemMessageType.MilestoneOverdue,
  SystemMessageType.NewCategoryAdded,
  SystemMessageType.NewCommentAdded,
  SystemMessageType.CategoryEdited,
];

const FallbackTemplate: React.FC = () => {
  return null;
};

function getTemplate(type: SystemMessageType | string) {
  if (type in templates) {
    return templates[type as SystemMessageType];
  }

  return FallbackTemplate;
}

function SystemMessage({ message }: SystemMessageProps) {
  const Component = getTemplate(message.data.type);

  const isStudentJourneyEnabled = useIsFeatureEnabled(Feature.StudentJourney);

  if (
    !isStudentJourneyEnabled &&
    StudentJourneyTypes.includes(message.data.type)
  ) {
    return null;
  }

  if (!Component) {
    return null;
  }

  return <Component message={message} />;
}

export default memo(SystemMessage);

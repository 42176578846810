import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Modal,
  Layout,
  Loader,
  DeprecatedButton,
} from '@advisor/design/components';
import useProcessingUpload from '../useProcessingUpload';
import CrawlMemoryContent from './CrawlMemoryContent';
import BulkUploadContent from './BulkUploadContent';
import useModalTitle from './useModalTitle';

const ProcessingUploadModal: React.FC = () => {
  const {
    isLoading,
    failedEntries,
    isCancellable,
    processingItem,

    closeModal,
    cancelUpload,
  } = useProcessingUpload();

  const { t } = useTranslation(['microbot', 'common']);
  const title = useModalTitle(processingItem);

  if (isLoading) {
    return (
      <Modal.Base
        maxWidth={600}
        minHeight={626}
        onClose={closeModal}
        padding={{ x: 128, y: 60 }}
        visible={!!processingItem || isLoading}
      >
        <div className="flex justify-center items-center h-full">
          <Loader />
        </div>
      </Modal.Base>
    );
  }

  if (!processingItem) {
    return null;
  }

  return (
    <Modal.Base
      maxWidth={600}
      minHeight={626}
      onClose={closeModal}
      padding={{ x: 128, y: 60 }}
      visible={!!processingItem}
    >
      <div className="absolute top-4 ltr:right-4 rtl:left-4">
        <DeprecatedButton.Icon
          icon="Close"
          onPress={closeModal}
          variant="darkGrey01"
          size={32}
        />
      </div>
      {!!title && (
        <h4 className="font-sora font-semibold text-base tracking-tight text-center">
          {title}
        </h4>
      )}
      <Layout.Spacer.Vertical size="small01" />
      {processingItem.__typename === 'MicrobotBulkUpload' ? (
        <BulkUploadContent
          bulkUpload={processingItem}
          failedEntries={failedEntries}
        />
      ) : (
        <CrawlMemoryContent item={processingItem} />
      )}
      <Layout.Spacer.Vertical size={40} />
      {isCancellable ? (
        <DeprecatedButton.Large
          outline
          variant="negative"
          className="w-full"
          onPress={cancelUpload}
        >
          {t('microbot:cancel-upload')}
        </DeprecatedButton.Large>
      ) : (
        <DeprecatedButton.Large
          className="w-full"
          onPress={closeModal}
          variant="primary"
        >
          {t('common:okay')}
        </DeprecatedButton.Large>
      )}
    </Modal.Base>
  );
};

export default memo(ProcessingUploadModal);

import { useMemo } from 'react';

import type { MemorySetGroups } from '../../api/useMemorySets';
import { useActiveTab } from './atoms';

export default function usePickGroup(groups: MemorySetGroups) {
  const activeTab = useActiveTab();

  return useMemo(() => {
    switch (activeTab) {
      case 'published':
        return groups.activeSetIds;
      case 'draft':
        return groups.draftSetIds;
      case 'subscribed':
        return groups.subscribedSetIds;
      case 'available':
        return groups.availableSetIds;
      default:
        return [];
    }
  }, [activeTab, groups]);
}

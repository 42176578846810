import React from 'react';

import { useMe } from '@advisor/api/me';
import { Role } from '@advisor/api/user';
import { DeprecatedButton } from '@advisor/design/components';

import useMemorySetActionModal from './useMemorySetActionModal';

const AddMemorySetButton: React.FC = () => {
  const me = useMe();
  const { createNewMemorySet } = useMemorySetActionModal();

  if (!Role.isMemorySetAdmin(me)) {
    return null;
  }

  return <DeprecatedButton.Create onPress={createNewMemorySet} />;
};

export default AddMemorySetButton;

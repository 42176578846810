import z from 'zod';
import type { Immutable } from 'immer';

import { AdvisorRole } from '@advisor/api/generated/graphql';

export enum VideoCallStatus {
  Joining = 'Joining',
  Joined = 'Joined',
  Error = 'Error',
  Leaving = 'Leaving',
}

export type VideoCallState = Immutable<{
  chatRoomId: string;
  status: VideoCallStatus;
}>;

export type SessionMetadata = z.infer<typeof SessionMetadata>;
export const SessionMetadata = z.object({
  userId: z.string(),
  cameraAspectRatio: z.number().optional(),
});

export type TrackState = Immutable<{
  track: MediaStreamTrack | null;
}>;

export type CallSession = Immutable<{
  sessionId: string;
  metadata: SessionMetadata;
  isLocal: boolean;

  tracks: {
    audio: TrackState;
    video: TrackState;
    screenAudio: TrackState;
    screenVideo: TrackState;
  };
}>;

export type Sessions = { [sessionId: string]: CallSession };

export type PendingJoinState = 'NotConnected' | 'WaitingToJoin' | 'Joining';
export type JoinState = PendingJoinState | 'Joined';

/**
 * Shared interface between chat members and external invites
 */
export type CallParticipant = {
  /**
   * A way to uniquely identify this participant.
   * Can be neither the userId nor the sessionId, since
   * some participants have not joined yet, and some
   * can be multiple devices from the same account.
   *
   * Usually just `userId` is necessary, but duplicates are
   * resolved with `${id}${session.session_id}` keys. The keys
   * of participants cannot change once they're assigned to keep
   * the UI stable.
   */
  key: string;
  userId: string;
  avatarUrl?: string;
  name: string;
  /**
   * There can only be one primary session for a user,
   * so when a user has multiple sessions, all but one
   * are going to be secondary.
   */
  secondarySession?: boolean;
  role?: AdvisorRole;
  __typename:
    | 'Advisor'
    | 'FamilyMember'
    | 'ServiceProvider'
    | 'Microbot'
    | 'User';
} & (
  | {
      joinState: 'Joined';
      session: CallSession;
    }
  | {
      /**
       * NOTE: Right now, the only way I see we could determine
       * whether someone is 'Joining' rather than has already joined
       * is if the backend subscription has notified the frontend that
       * this user decided to join the room, but Daily.co has not yet
       * caught up.
       */
      joinState: PendingJoinState;
    }
);

export type ConnectedCallParticipant = Extract<
  CallParticipant,
  { joinState: 'Joined' }
>;

// NOTE: Turn into z.union(...) when more messages are necessary.
export const CustomCallEvent = z.object({
  type: z.literal('mute-before-recording'),
});

export type CustomCallEvent = z.infer<typeof CustomCallEvent>;

export type CustomCallEventTypeOrAll = CustomCallEvent['type'] | 'all';

export type SpecificCustomCallEvent<T extends CustomCallEventTypeOrAll> =
  T extends 'all' ? CustomCallEvent : Extract<CustomCallEvent, { type: T }>;

export enum EmitCustomEventResult {
  Sent = 'Sent',
  NotConnected = 'NotConnected',
}

export type CustomCallEventListener<T extends CustomCallEventTypeOrAll> = (
  msg: SpecificCustomCallEvent<T>,
  sender: CallSession,
) => void;
export type Subscription = { unsubscribe: () => void };

export enum PrepareVideoRoomForRecordingResult {
  Ready = 'Ready',
  NotReady = 'NotReady',
}

export enum ScreenShareStatus {
  Sharing = 'Sharing',
  Pending = 'Pending',
  NotSharing = 'NotSharing',
}

export interface VideoRoomAPI {
  join(
    url: string | null,
    token: string | null,
    metadata: SessionMetadata,
  ): void;
  leave(): Promise<void>;

  toggleAudio(enabled: boolean): void;
  toggleVideo(enabled: boolean): void;
  toggleSpeaker(override?: boolean): void;
  startScreenShare(): void;
  stopScreenShare(): void;
  cycleCamera(): Promise<void>;

  updateSessionMetadata(data: Partial<SessionMetadata>): Promise<void>;
  prepareVideoRoomForRecording(): Promise<PrepareVideoRoomForRecordingResult>;

  emitCustomEvent(msg: CustomCallEvent, to?: string): EmitCustomEventResult;
  onCustomEvent<T extends CustomCallEventTypeOrAll>(
    type: T,
    listener: CustomCallEventListener<T>,
  ): Subscription | null;
  onSessionLeft(listener: (session: CallSession) => void): Subscription | null;

  /**
   * A function with a stable reference, returning the latest
   * sessions for the call. Useful if we want to cyclicly check
   * the state of the call, or just not depend on the `sessions` value.
   */
  getSessions(): Sessions;

  sessions: Sessions;
  speakerEnabled: boolean;
  screenShareStatus: ScreenShareStatus;
  activeParticipantId: string | undefined;
}

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  BulkUploadProcessingStatus,
  MicrobotMemoryProcessingStatus,
} from '@advisor/api/generated/graphql';
import { ItemType } from '../types';

const bulkUploadStatusMap = {
  [BulkUploadProcessingStatus.Failed]: 'upload-failed',
  [BulkUploadProcessingStatus.InQueue]: 'upload-in-queue',
  [BulkUploadProcessingStatus.Cancelled]: 'upload-cancelled',
  [BulkUploadProcessingStatus.Completed]: 'upload-complete',
  [BulkUploadProcessingStatus.InProgress]: 'multiple-entries-uploading',
} as const;

const memoryStatusMap = {
  [MicrobotMemoryProcessingStatus.Failed]: 'upload-failed',
  [MicrobotMemoryProcessingStatus.InQueue]: 'upload-in-queue',
  [MicrobotMemoryProcessingStatus.Completed]: 'upload-complete',
  [MicrobotMemoryProcessingStatus.Cancelled]: 'upload-cancelled',
  [MicrobotMemoryProcessingStatus.InProgress]: 'upload-in-progress',
} as const;

export default function useModalTitle(processingItem?: ItemType | null) {
  const { t } = useTranslation('microbot');

  return useMemo(() => {
    if (!processingItem) {
      return null;
    }

    if (processingItem.__typename === 'MicrobotBulkUpload') {
      return t(bulkUploadStatusMap[processingItem.status]);
    }

    return t(memoryStatusMap[processingItem.status]);
  }, [t, processingItem]);
}

import React from 'react';

import { Layout, Skeleton } from '@advisor/design/components';

const MemorySetListLoader: React.FC = () => (
  <>
    <div className="border border-grey h-[190px] mx-6 p-6 my-6 rounded-md items-center">
      <Skeleton.Text fontSize={14} lineHeight={24} size={140} />
      <Layout.Spacer.Vertical size="atomic" />
      <Skeleton.Text fontSize={12} lineHeight={20} size={240} />
      <Layout.Spacer.Vertical size="atomic" />
      <Skeleton.Text fontSize={12} lineHeight={20} size={240} />
      <Layout.Spacer.Vertical size="atomic" />
      <Skeleton.Text fontSize={12} lineHeight={20} size={240} />
    </div>
    <div className="flex flex-row items-center justify-center">
      <Skeleton.Ping>
        <div className="bg-grey w-[120px] h-[24px] rounded-md" />
      </Skeleton.Ping>
      <Layout.Spacer.Horizontal size="small01" />
      <Skeleton.Ping>
        <div className="bg-grey w-[120px] h-[24px] rounded-md" />
      </Skeleton.Ping>
    </div>
    <Layout.Spacer.Vertical size="small01" />
    <LoaderItem />
    <Layout.Spacer.Vertical size="small01" />
    <LoaderItem />
    <Layout.Spacer.Vertical size="small01" />
    <LoaderItem />
  </>
);

export default MemorySetListLoader;

const LoaderItem: React.FC = () => (
  <div className="p-4 border border-grey rounded mx-6">
    <Skeleton.Text fontSize={14} lineHeight={20} size={140} />
    <Layout.Spacer.Vertical size="atomic" />
    <Skeleton.Text fontSize={12} lineHeight={16} size={80} />
  </div>
);

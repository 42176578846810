import React, { useMemo } from 'react';
import type { ListRenderItem } from 'react-native';

import { useMe } from '@advisor/api/me';
import { Role } from '@advisor/api/user';

import {
  Layout,
  FlatList,
  ErrorView,
  ContextMenu,
} from '@advisor/design/components';

import useMemorySets from '../../api/useMemorySets';

import useMemorySetOptions from './useMemorySetOptions';
import MemorySetListLoader from './MemorySetListLoader';
import MemorySetListItem from './MemorySetListItem';
import MemorySetOptions from './MemorySetOptions';
import MemorySetTabs from './MemorySetTabs';
import AgencyHeader from './AgencyHeader';
import usePickGroup from './usePickGroup';
import AdminHeader from './AdminHeader';

const keyExtractor = (item: string) => item;

const MemorySetList: React.FC = () => {
  const { isLoading, memorySets, totalCount, hasError, refetch } =
    useMemorySets();

  const {
    isOpen,
    selectedMemorySet,

    closeModal,
    editMemorySet,
    deleteMemorySet,
    toggleVisibility,
  } = useMemorySetOptions();

  const setIds = usePickGroup(memorySets);

  const renderItem: ListRenderItem<string> = ({ item }) => {
    const memorySet = memorySets.memorySetMap[item];

    return <MemorySetListItem memorySet={memorySet} />;
  };

  if (isLoading && totalCount === 0) {
    return <MemorySetListLoader />;
  }

  if (hasError) {
    return <ErrorView onTryAgain={refetch} />;
  }

  return (
    <>
      <FlatList
        data={setIds}
        onRefresh={refetch}
        refreshing={isLoading}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        ListHeaderComponent={MemorySetListHeader}
        ItemSeparatorComponent={() => <Layout.Spacer.Vertical size="micro02" />}
      />
      {!!selectedMemorySet && (
        <ContextMenu.StaticOwner visible={isOpen} onClose={closeModal}>
          <ContextMenu.Root triggerTestID="memorySetOptions">
            <MemorySetOptions
              status={selectedMemorySet?.status}
              editMemorySet={editMemorySet}
              deleteMemorySet={deleteMemorySet}
              toggleVisibility={toggleVisibility}
            />
          </ContextMenu.Root>
        </ContextMenu.StaticOwner>
      )}
    </>
  );
};

export default MemorySetList;

const MemorySetListHeader: React.FC = () => {
  const me = useMe();

  const headerComponent = useMemo(() => {
    if (Role.isMemorySetAdmin(me)) {
      return <AdminHeader />;
    }

    return <AgencyHeader />;
  }, [me]);

  return (
    <>
      {headerComponent}
      <Layout.Spacer.Vertical size="tiny" />
      <MemorySetTabs />
      <Layout.Spacer.Vertical size="tiny" />
    </>
  );
};

import { useAtom } from 'jotai';
import { useMemo } from 'react';

import {
  BulkUploadProcessingStatus,
  MicrobotMemoryProcessingStatus,
} from '@advisor/api/generated/graphqlTypes';
import { useEvent } from '@advisor/utils/hooks';

import useBulkUploadDetails from './useBulkUploadDetails';
import useCancelCrawlMemory from './useCancelCrawlMemory';
import useCancelBulkUpload from './useCancelBulkUpload';
import { openItemAtom } from './atoms';
import { OpenItemInfo } from './types';

const CancellableBulkUploadStatus = [
  BulkUploadProcessingStatus.InQueue,
  BulkUploadProcessingStatus.InProgress,
];

const CancellableCrawlMemoryStatus = [
  MicrobotMemoryProcessingStatus.InQueue,
  MicrobotMemoryProcessingStatus.InProgress,
];

function getBulkUploadID(openItem?: OpenItemInfo | null): string | null {
  if (!openItem) {
    return null;
  }

  return openItem.__typename === 'MicrobotBulkUpload' ? openItem.id : null;
}

export default function useProcessingUpload() {
  const [openItem, setOpenItem] = useAtom(openItemAtom);

  const bulkUploadQuery = useBulkUploadDetails(getBulkUploadID(openItem));
  const cancelBulkUpload = useCancelBulkUpload();
  const cancelCrawlMemory = useCancelCrawlMemory();

  const closeModal = useEvent(() => {
    setOpenItem(null);
  });

  const cancelUpload = useEvent(async () => {
    if (openItem?.__typename === 'MicrobotBulkUpload') {
      await cancelBulkUpload(openItem.id);
    } else if (openItem?.__typename === 'MicrobotCrawlMemory') {
      await cancelCrawlMemory(openItem.id);
    }
  });

  const { processingItem, isLoading } = useMemo(() => {
    if (!openItem) {
      return { processingItem: null, isLoading: false };
    }

    if (openItem.__typename === 'MicrobotCrawlMemory') {
      return { processingItem: openItem, isLoading: false };
    }

    if (openItem.__typename === 'MicrobotBulkUpload') {
      return {
        processingItem: bulkUploadQuery.bulkUpload,
        isLoading: bulkUploadQuery.isLoading,
      };
    }

    return { processingItem: null, isLoading: true };
  }, [bulkUploadQuery, openItem]);

  const isCancellable = useMemo(() => {
    if (processingItem?.__typename === 'MicrobotBulkUpload') {
      return CancellableBulkUploadStatus.includes(processingItem?.status ?? '');
    }

    if (processingItem?.__typename === 'MicrobotCrawlMemory') {
      return CancellableCrawlMemoryStatus.includes(
        processingItem?.status ?? '',
      );
    }

    return false;
  }, [processingItem]);

  return {
    isLoading,
    isCancellable,
    processingItem,
    failedEntries: bulkUploadQuery.failedEntries ?? [],

    closeModal,
    cancelUpload,
  };
}

import {
  BulkUploadProcessingStatus,
  MicrobotMemoryProcessingStatus,
  SimplifiedBulkUploadInfoFragment,
} from '@advisor/api/generated/graphqlTypes';
import { ItemType } from './types';

/* eslint-disable import/prefer-default-export */
export function getFistItem(items: ItemType[]): ItemType {
  const firstItem = items.find((item) => {
    if (item.__typename === 'MicrobotBulkUpload') {
      return item.status === BulkUploadProcessingStatus.InProgress;
    }

    if (item.__typename === 'MicrobotCrawlMemory') {
      return item.status === MicrobotMemoryProcessingStatus.InProgress;
    }

    return false;
  });

  if (!firstItem) {
    return items[0];
  }

  return firstItem;
}

export function getNextIndex(items: ItemType[], currentIndex: number): number {
  return (currentIndex + 1) % items.length;
}

export function getFinishedPercentage(
  bulkUpload: SimplifiedBulkUploadInfoFragment,
): number {
  return (bulkUpload.processed + bulkUpload.failed) / bulkUpload.total;
}

/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable import/prefer-default-export */
const z = require('zod');

const InstanceContext = z
  .object({
    // platform flags
    web: z.boolean(),
    native: z.boolean(),
    ios: z.boolean(),
    android: z.boolean(),
  })
  .partial();

/**
 * Coerces all nullish values to undefined.
 * @template {z.ZodSchema} T
 * @param {T} innerSchema
 */
function optionalJSON(innerSchema) {
  return innerSchema.nullish().transform((arg) => {
    if (arg === undefined || arg === null) {
      return undefined;
    }

    return arg;
  });
}

const PhoneNumber = z.string().regex(/^\+\d{11}$/g);

/**
 * Each branch can receive separate OTA updates. Defined in `eas.json`.
 */
const OTABranch = z.enum(['staging', 'production']);

/**
 * Each profile changes the app build process. Defined in `eas.json`.
 */
const EASProfile = z.enum(['internal', 'preview', 'production', 'test-e2e']);

const InstanceConfig = z.object({
  /** TODO: Remove this after the backend stops using app environments in favor of instances. */
  env: z.enum(['DEV', 'STAGING', 'PROD']),

  /** Uniquely identifies an instance */
  instanceKey: z.string(),
  domain: z.string(),
  signedCookiesDomain: z.string(),
  privateAssetsDomain: z.string(),
  publicAssetsDomain: z.string(),
  externalServicesDomain: z.string(),
  organizationName: z.string(),
  productName: z.string(),
  defaultAgencyName: z.string(),

  /**
   * Domain configuration used by mobile app deep-link configuration.
   * Used to link mobile app to particular domains. Can be left as empty
   * array if non-production environments are not used
   */
  additionalDomains: z.array(z.string()).default([]),

  auth0: z.object({
    domain: z.string(),
    clientID: z.string(),
    audience: z.string(),
  }),

  api: z.object({
    graphQLUrl: z.string(),
    s3Url: z.string(),
    imageCompressionUrl: z.string().optional(),
    agencySignedCookieUrl: z.string(),
    chatRoomSignedCookieUrl: z.string(),
    userSignedCookieUrl: z.string(),
  }),

  validations: z.array(
    z.enum(['verification-account', 'end-to-end-account', 'app-store-uploads']),
  ),

  /**
   * Static credentials that will be used by Apple and Google
   * reviewers to access the application during review process
   */
  verificationAccount: optionalJSON(
    z.object({
      phoneNumber: z.string(),
      email: z.string(),
    }),
  ),

  translation: z.object({
    googleSheetDocumentId: z.string(),
  }),

  bundledFeatures: z.object({
    greyLabeling: z.boolean(),
    taskOrganizer: z.boolean(),
    privateNotes: z.boolean(),
    videoRooms: z.boolean(),

    microbotAlphaBot: z.boolean(),
    microbotRecommendationBot: z.boolean(),
    microbotCalendarBot: z.boolean(),

    microbotCustomization: z.boolean(),
    memoryBank: z.boolean(),
  }),

  theme: z.object({
    colorPalette: z.object({
      primaryColor: z.string(),
      primaryDarkColor: z.string().optional(),
      primaryLightColor: z.string().optional(),
      primaryLight05Color: z.string().optional(),

      secondaryColor: z.string(),
      secondaryLightColor: z.string().optional(),
      secondaryDarkColor: z.string().optional(),
    }),

    platformLogo: z.string(),
    organizationLogo: z.string(),
  }),

  assets: z.record(z.string()),

  mobile: z.object({
    bundleIdentifier: z.string(),
    expoProjectId: z.string(),
    expoProjectSlug: z.string(),
    expoProjectOwner: z.string(),
    appleId: optionalJSON(z.string()),
    /** Which branches can OTA updates be released on */
    allowedBranches: z.array(OTABranch).default(['production']),
    /** Which profiles from eas.json apps can use while building */
    allowedProfiles: z
      .array(EASProfile)
      .default(['internal', 'production', 'test-e2e']),
    /**
     * See https://docs.expo.dev/versions/latest/sdk/document-picker/
     */
    iCloudContainerEnvironment: z
      .enum(['Development', 'Production'])
      .default('Production'),
    enableLoginDeeplink: z.boolean().default(false),

    scheme: z.array(z.string()),

    icon: z.string(),

    oneSignalAppId: z.string().optional(),

    adaptiveIcon: z.object({
      image: z.string(),
      backgroundColor: z.string(),
    }),

    splashScreen: z.object({
      image: z.string(),
      backgroundColor: z.string(),
    }),

    permissionRequests: z.object({
      camera: z.string(),
      microphone: z.string(),
      photoLibrary: z.string(),
    }),
  }),

  web: z.object({
    originUrl: z.string(),
    defaultTitle: z.string(),
    defaultDescription: z.string(),
    shortTitle: z.string(),
    favicon: z.string(),
    appleTouchIcon: z.string(),
    maskIcon: z.string(),
    logoWhite: z.string(),
    logoNotifications: z.string(),
    notFoundPageTitle: z.string(),
    invitationPageTitle: z.string(),
    termsPageTitle: z.string(),
    privacyPolicyPageTitle: z.string(),
    'pwa-192x192': z.string(),
    'pwa-512x512': z.string(),
    appleAppId: optionalJSON(z.string()),
    androidCertFingerprints: optionalJSON(z.array(z.string())),
    allowIndexing: z.boolean().default(true),
  }),

  sentryReporting: z.object({
    organization: z.string(),
    project: z.string(),
    /** Where frontend issues will be sent to (if not defined or empty, issues will be logged to the console) */
    frontendDsnUrl: optionalJSON(z.string()),
    /** Where backend issues will be sent to */
    backendDsnUrl: z.string(),
  }),

  google: z.object({
    googleServicesFile: z.string(),
    // TODO: Consider making this required
    analyticsId: optionalJSON(z.string()),
    api: z.object({
      webClientId: z.string(),
      iosClientId: z.string(),
    }),
  }),

  firebaseMessaging: z.object({
    apiKey: z.string(),
    authDomain: z.string(),
    projectId: z.string(),
    storageBucket: z.string(),
    messagingSenderId: z.string(),
    appId: z.string(),
    measurementId: z.string(),
    vapidKey: z.string(),
  }),

  videoRooms: z.object({
    hlsStreamingAssumeRoleArn: z.string(),
    hlsEndpointName: z.string(),
  }),

  awsConfig: z.object({
    acmCertificateArn: z.string(),
    zoneId: z.string(),
    /**
     * Identifies the AWS secret that stores all instance secret values.
     * Has to be created manually before deployment.
     */
    mainSecretName: z.string(),
    /** TODO: Migrate the backend to use 'mainSecretName' instead of 'mainSecretArn' to locate the secret */
    mainSecretArn: z.string(),
  }),

  twilio: z.object({
    /** The number we send OTP from */
    phoneNumber: PhoneNumber,
  }),

  pinecone: z.object({
    host: z.string(),
  }),

  microbots: z.object({
    alphaBotName: z.string(),
    recommendationBotName: z.string(),
    calendarBotName: z.string(),
  }),

  dataHarvesting: z.object({ bulkUploadSampleFileUrl: z.string() }).optional(),

  feedbacks: z.object({
    /** Emails that get sent feedbacks. */
    recipients: z.array(z.string().email()).nonempty(),
    /** Identifier of Slack channel where feedbacks will get automatically posted. */
    slackChannelId: z.string(),
  }),

  /**
   * TODO: Document the usage of these accounts
   */
  /** Information about the admin accounts that will be created upon instance initialization using initialize_instance.py */
  adminAccounts: z.array(
    z.object({
      name: z.string(),
      phoneNumber: optionalJSON(PhoneNumber),
      email: optionalJSON(z.string().email()),
      isOwner: z.boolean(),
      isApproving: z.boolean(),
    }),
  ),
});

exports.InstanceContext = InstanceContext;
exports.InstanceConfig = InstanceConfig;
exports.OTABranch = OTABranch;
exports.EASProfile = EASProfile;

import { useMemo } from 'react';

import {
  BulkUploadProcessingStatus,
  useMemoryBankBulkUploadQuery,
} from '@advisor/api/generated/graphql';

export default function useBulkUploadDetails(bulkUploadId?: string | null) {
  const { data, loading } = useMemoryBankBulkUploadQuery({
    variables: {
      id: bulkUploadId!,
    },
    skip: !bulkUploadId,
  });

  const bulkUpload = data?.memoryBankBulkUpload;

  const failedEntries = useMemo(
    () =>
      bulkUpload?.entries?.filter(
        (entry) => entry.status === BulkUploadProcessingStatus.Failed,
      ) ?? [],
    [bulkUpload],
  );

  return {
    isLoading: loading,
    bulkUpload,
    failedEntries,
  };
}

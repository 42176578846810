import { SystemMessageType } from '@advisor/api/generated/graphql';
import React from 'react';

import { SystemMessageProps } from '../types';
import AdvisorMessageInNewConversation from './AdvisorMessageInNewConversation';
import AdvisorReferralCodeAccessed from './AdvisorReferralCodeAccessed';
import AllMilestonesCompletedInCategory from './AllMilestonesCompletedInCategory';
import MessageWhenAnyUserLeavesTheRoom from './MessageWhenAnyUserLeavesTheRoom';
import CategoryEdited from './CategoryEdited';
import FirstInteraction from './FirstInteraction';
import FirstMilestoneCompleted from './FirstMilestoneCompleted';
import FirstServiceProviderMessage from './FirstServiceProviderMessage';
import FirstStudentMessageInNewConversation from './FirstStudentMessageInNewConversation';
import InvitationConfirmed from './InvitationConfirmed';
import InviteStudentToConversation from './InviteStudentToConversation';
import JoinStudentConversation from './JoinStudentConversation';
import MessageWhenVideoRecordingCompleted from './MessageWhenVideoRecordingCompleted';
import MessageWhenVideoRoomEnded from './MessageWhenVideoRoomEnded';
import MessageWhenVideoRoomInitiated from './MessageWhenVideoRoomInitiated';
import MessageWhenVideoRoomRecordingAdded from './MessageWhenVideoRoomRecordingAdded';
import MilestoneAdded from './MilestoneAdded';
import MilestoneDueDateReminder from './MilestoneDueDateReminder';
import MilestoneEdited from './MilestoneEdited';
import MilestoneMarkedCompleteByAdvisor from './MilestoneMarkedCompleteByAdvisor';
import MilestoneMarkedCompleteByStudent from './MilestoneMarkedCompleteByStudent';
import MilestoneMarkedIncompleteByAdvisor from './MilestoneMarkedIncompleteByAdvisor';
import MilestoneMarkedIncompleteByStudent from './MilestoneMarkedIncompleteByStudent';
import MilestoneOverdue from './MilestoneOverdue';
import NewCategoryAdded from './NewCategoryAdded';
import NewCommentAdded from './NewCommentAdded';
import NewMember from './NewMember';
import NewMemberMessageInStudentConversation from './NewMemberMessageInStudentConversation';
import OnboardingApprovalDenied from './OnboardingApprovalDenied';
import OnboardingAwaitingApproval from './OnboardingAwaitingApproval';
import OnboardingNewChatMember from './OnboardingNewChatMember';
import OneWeekMilestoneDueDateReminder from './OneWeekMilestoneDueDateReminder';
import StudentMessageWhenNewAdvisorJoinsTheRoom from './StudentMessageWhenNewAdvisorJoinsTheRoom';
import ThreeDaysMilestoneDueDateReminder from './ThreeDaysMilestoneDueDateReminder';
import TomorrowMilestoneDueDateReminder from './TomorrowMilestoneDueDateReminder';
import WheneverMilestoneDueDateReminder from './WheneverMilestoneDueDateReminder';

const templates: Record<SystemMessageType, React.FC<SystemMessageProps>> = {
  [SystemMessageType.AdvisorMessageInNewConversation]:
    AdvisorMessageInNewConversation,
  [SystemMessageType.AdvisorReferralCodeAccessed]: AdvisorReferralCodeAccessed,
  [SystemMessageType.AllMilestonesCompletedInCategory]:
    AllMilestonesCompletedInCategory,
  [SystemMessageType.MessageWhenAnyUserLeavesTheRoom]:
    MessageWhenAnyUserLeavesTheRoom,
  [SystemMessageType.CategoryEdited]: CategoryEdited,
  [SystemMessageType.FirstInteraction]: FirstInteraction,
  [SystemMessageType.FirstMilestoneCompleted]: FirstMilestoneCompleted,
  [SystemMessageType.FirstServiceProviderMessage]: FirstServiceProviderMessage,
  [SystemMessageType.FirstStudentMessageInNewConversation]:
    FirstStudentMessageInNewConversation,
  [SystemMessageType.InvitationConfirmed]: InvitationConfirmed,
  [SystemMessageType.InviteStudentToConversation]: InviteStudentToConversation,
  [SystemMessageType.MessageWhenVideoRecordingCompleted]:
    MessageWhenVideoRecordingCompleted,
  [SystemMessageType.MessageWhenVideoRoomEnded]: MessageWhenVideoRoomEnded,
  [SystemMessageType.MessageWhenVideoRoomInitiated]:
    MessageWhenVideoRoomInitiated,
  [SystemMessageType.MessageWhenVideoRoomRecordingAdded]:
    MessageWhenVideoRoomRecordingAdded,
  [SystemMessageType.MilestoneAdded]: MilestoneAdded,
  [SystemMessageType.MilestoneDueDateReminder]: MilestoneDueDateReminder,
  [SystemMessageType.MilestoneEdited]: MilestoneEdited,
  [SystemMessageType.MilestoneMarkedCompleteByAdvisor]:
    MilestoneMarkedCompleteByAdvisor,
  [SystemMessageType.MilestoneMarkedCompleteByStudent]:
    MilestoneMarkedCompleteByStudent,
  [SystemMessageType.MilestoneMarkedIncompleteByAdvisor]:
    MilestoneMarkedIncompleteByAdvisor,
  [SystemMessageType.MilestoneMarkedIncompleteByStudent]:
    MilestoneMarkedIncompleteByStudent,
  [SystemMessageType.MilestoneOverdue]: MilestoneOverdue,
  [SystemMessageType.NewCategoryAdded]: NewCategoryAdded,
  [SystemMessageType.NewCommentAdded]: NewCommentAdded,
  [SystemMessageType.NewMemberMessageInStudentConversation]:
    NewMemberMessageInStudentConversation,
  [SystemMessageType.OnboardingApprovalDenied]: OnboardingApprovalDenied,
  [SystemMessageType.OnboardingAwaitingApproval]: OnboardingAwaitingApproval,
  [SystemMessageType.OnboardingNewChatMember]: OnboardingNewChatMember,
  [SystemMessageType.OneWeekMilestoneDueDateReminder]:
    OneWeekMilestoneDueDateReminder,
  [SystemMessageType.UserJoinedConversation]: NewMember,
  [SystemMessageType.StudentMessageWhenNewAdvisorJoinsTheRoom]:
    StudentMessageWhenNewAdvisorJoinsTheRoom,
  [SystemMessageType.JoinStudentConversation]: JoinStudentConversation,
  [SystemMessageType.ThreeDaysMilestoneDueDateReminder]:
    ThreeDaysMilestoneDueDateReminder,
  [SystemMessageType.TomorrowMilestoneDueDateReminder]:
    TomorrowMilestoneDueDateReminder,
  [SystemMessageType.WheneverMilestoneDueDateReminder]:
    WheneverMilestoneDueDateReminder,
};

export default templates;

import React, { PropsWithChildren } from 'react';

import DailyAPIProvider from './DailyAPIProvider';

type VideoRoomAPIProviderProps = PropsWithChildren;

const VideoRoomAPIProvider: React.FC<VideoRoomAPIProviderProps> = (props) => {
  const { children } = props;

  return <DailyAPIProvider>{children}</DailyAPIProvider>;
};

export default VideoRoomAPIProvider;

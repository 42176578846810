import React from 'react';
import cs from 'classnames';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { useMe } from '@advisor/api/me';
import { Role } from '@advisor/api/user';

import { Layout } from '@advisor/design/components';

import { activeTabAtom, useActiveTab } from './atoms';
import { MemorySetTab } from './types';

const MemorySetTabs: React.FC = () => {
  const me = useMe();

  return (
    <div className="px-6 flex flex-row">
      {Role.isMemorySetAdmin(me) ? (
        <>
          <MemorySetTabButton type="published" />
          <Layout.Spacer.Horizontal size="micro02" />
          <MemorySetTabButton type="draft" />
        </>
      ) : (
        <>
          <MemorySetTabButton type="subscribed" />

          <Layout.Spacer.Horizontal size="micro02" />
          <MemorySetTabButton type="available" />
        </>
      )}
    </div>
  );
};

export default MemorySetTabs;

interface MemorySetTabButtonProps {
  type: MemorySetTab;
}

const MemorySetTabButton: React.FC<MemorySetTabButtonProps> = (props) => {
  const { type } = props;

  const setActiveTab = useSetAtom(activeTabAtom);
  // use the hook to include also the default value
  const activeTab = useActiveTab();

  const { t } = useTranslation('microbot');

  const title = {
    draft: t('draft'),
    subscribed: t('active'),
    available: t('available'),
    published: t('published'),
  }[type];

  const onPress = () => {
    setActiveTab(type);
  };

  const isActive = activeTab === type;

  return (
    <button
      type="button"
      onClick={onPress}
      className={cs(
        'flex-1 transition-all text-xs font-sora rounded-md px-4 py-2',
        isActive
          ? 'bg-grey font-bold text-dark-grey-03'
          : 'font-semibold text-dark-grey-025',
      )}
    >
      {title}
    </button>
  );
};

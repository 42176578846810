import dayjs from 'dayjs';
import { groupBy, map } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { NetworkStatus } from '@apollo/client';

import {
  VideoChatHistoryQuery,
  useVideoChatHistoryQuery,
} from '@advisor/api/generated/graphql';
import { VideoRoomHistoryBlock } from './types';

function groupVideoRoomsByDay(
  query: VideoChatHistoryQuery | undefined,
): VideoRoomHistoryBlock[] {
  const history = query ? [...query.videoChatHistory.edges] : [];

  const grouped = groupBy(history, ({ node }) =>
    dayjs(node.createdAt).startOf('day'),
  );

  return map(grouped, (items, date) => ({
    date,
    data: items.map(({ node }) => node),
  }));
}

export default function useVideoRoomHistory(chatRoomId: string) {
  const {
    data,
    error,
    loading: isLoading,
    fetchMore,
    refetch,
    networkStatus,
  } = useVideoChatHistoryQuery({
    fetchPolicy: 'cache-and-network',
    variables: { chatRoomId },
  });

  const { pageInfo } = data?.videoChatHistory ?? {};

  const history = useMemo(() => groupVideoRoomsByDay(data), [data]);

  const loadMore = useCallback(() => {
    if (
      !isLoading &&
      networkStatus !== NetworkStatus.fetchMore &&
      pageInfo?.hasNextPage &&
      pageInfo.endCursor
    ) {
      fetchMore({
        variables: {
          chatRoomId,
          after: pageInfo.endCursor,
        },
      });
    }
  }, [pageInfo, isLoading, networkStatus, fetchMore, chatRoomId]);

  return {
    error,
    history,
    refetch,
    loadMore,
    isLoading,
    hasMore: !!pageInfo?.hasPreviousPage,
  };
}

import cs from 'classnames';
import React, { memo } from 'react';

import Icon from '../Icon';
import { CheckboxProps } from './types';

const Checkbox: React.FC<CheckboxProps> = ({ checked, disabled }) => {
  const bgColor = (() => {
    if (!checked) {
      return 'bg-transparent';
    }

    if (disabled) {
      return 'bg-primary-light';
    }

    return 'bg-primary';
  })();

  return (
    <span
      className={cs(
        'block box-content w-5 h-5 border rounded shrink-0',
        disabled ? 'border-light-grey' : 'border-dark-grey-01',
        bgColor,
      )}
    >
      <Icon name="Check" className="w-5 h-5 scale-150 text-white" />
    </span>
  );
};

export default memo(Checkbox);

import React from 'react';
import { pipe } from 'remeda';

import { withPadding } from '../../hoc';
import type { BlockProps } from './types';

function Block({ children, ...props }: BlockProps) {
  return <div {...props}>{children}</div>;
}

export default pipe(Block, withPadding());

import type { FeatureFlags } from '../generated/graphqlTypes';

export type FeatureKey = Exclude<keyof FeatureFlags, '__typename' | 'userId'>;

export const Feature = {
  StudentJourney: 'studentJourney',
  AgencyInvite: 'agencyInvite',
  MicrobotCustomization: 'microbotCustomization',
  Microbots: 'microbots',
  MicrobotAstro: 'microbotAstro',
  MicrobotPam: 'microbotPam',
  MicrobotCarl: 'microbotCarl',
  MemoryBank: 'memoryBank',
  DataHarvestingUrl: 'dataHarvestingUrl',
  DataHarvestingFile: 'dataHarvestingFile',
  PrivateNotes: 'privateNotes',
  GreyLabeling: 'greyLabeling',
  CustomTemplates: 'customTemplates',
  VideoChat: 'videoChat',
  ApprovingAdvisors: 'approvingAdvisors',
  BulkUpload: 'bulkUpload',
  MemorySetAccess: 'memorySetAccess',
  DirectCrawling: 'directCrawling',
} as const satisfies { [key in FeatureKey as Capitalize<key>]: key };

import { atom } from 'jotai';

import { ModalStep } from '../types';
import { EditorStateAtom } from './types';

export default function createModalStepAtom(editorStateAtom: EditorStateAtom) {
  return atom((get) => {
    const state = get(editorStateAtom);

    if (state?.mode === 'text' || state?.mode === 'file') {
      return ModalStep.MemorySource;
    }

    if (state?.mode === 'url') {
      return ModalStep.ChooseRetrieval;
    }

    if (state?.mode === 'confirm') {
      return ModalStep.ConfirmUpload;
    }

    return null;
  });
}

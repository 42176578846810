import { useSetAtom } from 'jotai';
import { useCallback, useState } from 'react';

import { useInvitationDetails } from '@advisor/onboarding';
import { loginAtom, LoginState, LoginType, useAuth } from '@advisor/api/auth';
import isValidPhoneNumberAsync from '@advisor/utils/isValidPhoneNumberAsync';
import { CallCode } from '@advisor/utils/country/internationalDiallingCodes';

export enum PhoneFormState {
  Init = 'Init',
  Valid = 'Valid',
  Invalid = 'Invalid',
  Loading = 'Loading',
  Unavailable = 'Unavailable',
}

interface State {
  state: PhoneFormState;
  callCode: CallCode;
  phoneNumber: string;
}

type ProviderError =
  | undefined
  | {
      code?: string;
      description?: string;
    };

const initialState = (getCallCode: () => CallCode): State => ({
  state: PhoneFormState.Init,
  callCode: getCallCode(),
  phoneNumber: '',
});

const extractNumber = (phoneNumber: string, callCode: CallCode): string => {
  return phoneNumber
    .replace(callCode.dial_code, '')
    .replace(/\D/g, '')
    .replace(/^(.{15}).*/, '$1')
    .replace(/^(.{6})(.)/, '$1 $2')
    .replace(/^(.{3})(.)/, '$1 $2');
};

export interface PhoneInputProps {
  callCode: CallCode;
  phoneNumber: string;
  state: PhoneFormState;
  onPhoneChange: (phoneNumber: string) => void;
  onCodeChange: (code: CallCode) => void;
  disabled?: boolean;
}

export function usePhoneForm(getCallCode: () => CallCode) {
  const { authStart } = useAuth();
  const setLoginAtom = useSetAtom(loginAtom);

  const invitationDetails = useInvitationDetails();

  const [state, setState] = useState(
    invitationDetails?.phoneNumber
      ? {
          phoneNumber: extractNumber(
            invitationDetails.phoneNumber,
            getCallCode(),
          ),
          callCode: getCallCode(),
          state: PhoneFormState.Valid,
        }
      : initialState(getCallCode),
  );

  const onPhoneChange = useCallback((phoneNumber: string) => {
    const formatted = phoneNumber
      .replace(/\D/g, '')
      .replace(/^(.{15}).*/, '$1')
      .replace(/^(.{6})(.)/, '$1 $2')
      .replace(/^(.{3})(.)/, '$1 $2');

    setState(({ callCode }) => ({
      callCode,
      phoneNumber: formatted,
      state: formatted.length >= 6 ? PhoneFormState.Valid : PhoneFormState.Init,
    }));
  }, []);

  const onCodeChange = useCallback((callCode: CallCode) => {
    setState((prev) => ({
      ...prev,
      callCode,
    }));
  }, []);

  const onSubmit = useCallback(async () => {
    setState((prev) => ({
      ...prev,
      state: PhoneFormState.Loading,
    }));

    const phoneNumber = `${state.callCode.dial_code} ${state.phoneNumber}`;

    if (!(await isValidPhoneNumberAsync(phoneNumber))) {
      setState((prev) => ({
        ...prev,
        state: PhoneFormState.Invalid,
      }));
      return;
    }

    const authResult = await authStart(phoneNumber);

    if (!authResult.success) {
      const { error } = authResult;

      if ((error as ProviderError)?.code === 'sms_provider_error') {
        setState((prev) => ({
          ...prev,
          state: PhoneFormState.Unavailable,
        }));

        return;
      }

      setState((prev) => ({
        ...prev,
        state: PhoneFormState.Invalid,
      }));

      return;
    }

    setLoginAtom((prev) => ({
      ...prev,
      state: LoginState.Verification,
      type: LoginType.PhoneNumber,
      phoneNumber,
    }));
  }, [state, authStart, setLoginAtom]);

  return {
    ...state,
    onPhoneChange,
    onCodeChange,
    onSubmit,
  };
}

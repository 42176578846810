import { type SetStateAction, atom } from 'jotai';

import { myIdAtom } from '@advisor/api/me';
import { Lazy, actionAtom } from '@advisor/utils/atoms';
import { userMetadataAtoms } from '@advisor/api/userMetadata';
import { type InvitationData, InvitationMetadataLocation } from '../types';
import invitationStorageAtom from './invitationStorageAtom';

const userMetadataAtom = userMetadataAtoms(InvitationMetadataLocation);

export const invitationAtom = (() => {
  return atom(
    (get) => {
      return (
        get(invitationStorageAtom) ?? get(Lazy.valueOr(userMetadataAtom, null))
      );
    },
    async (get, set, update: SetStateAction<InvitationData | null>) => {
      set(invitationStorageAtom, update);

      const isLoggedIn = !!(await get(myIdAtom));

      if (isLoggedIn) {
        set(userMetadataAtom, get(invitationStorageAtom) ?? undefined);
      }
    },
  );
})();

export const transferInvitationToMetadataAtom = actionAtom(({ get, set }) => {
  set(userMetadataAtom, get(invitationStorageAtom) ?? undefined);
});

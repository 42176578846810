import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { BulkUploadEntryProps } from './types';

const BulkUploadEntry: React.FC<BulkUploadEntryProps> = (props) => {
  const { entry } = props;
  const { t } = useTranslation('microbot');

  return (
    <div className="flex items-center gap-10 px-4 py-3 font-outfit text-sm [&:not(:last-child)]:border-b border-light-grey">
      <span className="text-dark-grey-02 shrink-0">
        {t('row-row', { row: entry.row - 1 })}
      </span>
      <span className="text-dark-grey-025 leading-4-5 truncate">
        {entry.value}
      </span>
    </div>
  );
};

export default memo(BulkUploadEntry);

import z from 'zod';
import type { SetStateAction, WritableAtom } from 'jotai';

import { UserMetadataLocation } from '@advisor/api/userMetadata';

export type InvitationType = z.infer<typeof InvitationType>;
export const InvitationType = z.enum([
  'advisor',
  'agency',
  'conversation',
  'student',
  'legacy',
]);

export type InvitationDetails = z.infer<typeof InvitationDetails>;
export const InvitationDetails = z.object({
  name: z.string().optional().nullable(),
  email: z.string().optional().nullable(),
  phone: z.string().optional().nullable(),
  country: z.string().optional().nullable(),
});

export type InvitationData = z.infer<typeof InvitationData>;
export const InvitationData = z.object({
  inviteType: InvitationType,
  inviteId: z.string(),
});

export const InvitationMetadataLocation = new UserMetadataLocation(
  'invitation',
  InvitationData,
);

export type InvitationStorageAtom = WritableAtom<
  InvitationData | null,
  [SetStateAction<InvitationData | null>],
  void
>;

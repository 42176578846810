import { useAtom, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import {
  MemorySetInfoFragment,
  MemorySetStatus,
  useDeleteMemorySetMutation,
  useUpdateMemorySetMutation,
} from '@advisor/api/generated/graphql';
import { useEvent } from '@advisor/utils/hooks';
import { showToast } from '@advisor/design/components/Toast';
import { useShowModal } from '@advisor/design/components/ActionModal';
import { memorySetActionStateAtom } from '../MemorySetActionModal';
import { selectedMemorySetIdAtom } from './atoms';

export default function useMemorySetOptions(
  memorySet?: MemorySetInfoFragment | null,
) {
  const { t } = useTranslation(['microbot', 'common']);

  const [selectedMemorySet, setSelectedMemorySet] = useAtom(
    selectedMemorySetIdAtom,
  );

  const internalMemorySet = memorySet || selectedMemorySet;

  const setMemorySetActionState = useSetAtom(memorySetActionStateAtom);

  const showModal = useShowModal();
  const [updateMemorySet] = useUpdateMemorySetMutation();
  const [deleteMemorySetMutation] = useDeleteMemorySetMutation();

  const closeModal = useEvent(() => {
    setSelectedMemorySet(null);
  });

  const editMemorySet = useEvent(() => {
    if (!internalMemorySet) {
      return;
    }

    setMemorySetActionState({
      type: 'update',
      title: internalMemorySet.title,
      status: internalMemorySet.status,
      memorySetId: internalMemorySet.id,
      description: internalMemorySet.description,
      subscribedAgencies: internalMemorySet.subscribedAgencies,
    });

    setSelectedMemorySet(null);
  });

  const deleteMemorySet = useEvent(async () => {
    if (
      !internalMemorySet ||
      internalMemorySet.status === MemorySetStatus.Active
    ) {
      return;
    }

    const confirm = await showModal.confirm({
      variant: 'severe',
      title: t('microbot:are-you-sure-you-want-to-remove-selected-memory-set'),
      message: t(
        'microbot:this-will-permanently-delete-the-stored-information',
      ),
      confirmActionLabel: t('common:remove'),
      cancelActionLabel: t('common:cancel'),
    });

    if (!confirm) {
      setSelectedMemorySet(null);
      return;
    }

    await deleteMemorySetMutation({
      variables: {
        id: internalMemorySet.id,
      },
    });

    setSelectedMemorySet(null);

    showToast({
      variant: 'rose',
      namespace: 'microbot',
      iconName: 'CircleCheckFill',
      messageI18Key: 'selected-memory-set-was-successfully-removed',
    });
  });

  const toggleVisibility = useEvent(async () => {
    if (!internalMemorySet) {
      return;
    }

    const nextStatus =
      internalMemorySet.status === MemorySetStatus.Draft
        ? MemorySetStatus.Active
        : MemorySetStatus.Draft;

    if (nextStatus === MemorySetStatus.Draft) {
      const confirmed = await showModal.confirm({
        variant: 'severe',
        title: t(
          'microbot:are-you-sure-you-want-to-move-selected-memory-set-to-draft',
        ),
        message: t(
          'microbot:you-are-about-to-move-this-published-item-to-draft',
        ),
        confirmActionLabel: t('microbot:move-to-draft'),
        cancelActionLabel: t('common:cancel'),
      });

      if (!confirmed) {
        setSelectedMemorySet(null);
        return;
      }
    }

    await updateMemorySet({
      variables: {
        ...internalMemorySet,
        status: nextStatus,
      },
    });

    if (nextStatus === MemorySetStatus.Draft) {
      showToast({
        variant: 'blue',
        namespace: 'microbot',
        iconName: 'CircleCheckFill',
        messageI18Key: 'selected-memory-set-was-moved-to-draft',
      });
    } else {
      showToast({
        variant: 'blue',
        namespace: 'microbot',
        iconName: 'CircleCheckFill',
        messageI18Key: 'selected-memory-set-was-successfully-published',
      });
    }

    setSelectedMemorySet(null);
  });

  return {
    isOpen: !!internalMemorySet,
    selectedMemorySet: internalMemorySet,

    closeModal,
    editMemorySet,
    deleteMemorySet,
    toggleVisibility,
  };
}

import { atom } from 'jotai';
import { unwrap } from 'jotai/utils';

import { Feature, featureEnabledAtoms } from '@advisor/api/feature';

export default function createCanRequestCrawlingAtom() {
  const urlsEnabledAtom = featureEnabledAtoms(Feature.DataHarvestingUrl);
  const directCrawlingEnabledAtom = featureEnabledAtoms(Feature.DirectCrawling);

  return atom((get) => {
    const urlsEnabled = get(unwrap(urlsEnabledAtom));
    const directCrawlingEnabled = get(unwrap(directCrawlingEnabledAtom));

    return urlsEnabled && directCrawlingEnabled;
  });
}

import { atom } from 'jotai';
import { use } from 'bunshi';
import { unwrap } from 'jotai/utils';

import { warnExhaustive } from '@advisor/utils/typeAssertions';
import { Feature, featureEnabledAtoms } from '@advisor/api/feature';
import { isUploaded } from '@advisor/design/components/FileAttachment';

import {
  VariablesState,
  AddUrlMemoryState,
  AddTextMemoryState,
  ConfirmUploadState,
  AddFileMemoryState,
} from '../types';
import { MemorySetScope } from '../utils';
import { EditorStateAtom } from './types';

export default function createSubmitVariablesAtom(
  editorStateAtom: EditorStateAtom,
) {
  const filesEnabledAtom = featureEnabledAtoms(Feature.DataHarvestingFile);
  const bulkUploadsEnabledAtom = featureEnabledAtoms(Feature.BulkUpload);
  const memorySetId = use(MemorySetScope);

  return atom<VariablesState>((get) => {
    const state = get(editorStateAtom);

    const handleFile = (inState: AddFileMemoryState | ConfirmUploadState) => {
      if (!get(unwrap(filesEnabledAtom))) {
        return undefined;
      }

      if (!isUploaded(inState.value)) {
        return undefined;
      }

      return {
        type: 'single' as const,
        variables: {
          memorySetId,
          fileMicrobotMemoryInput: {
            fileId: inState.value.file.id,
          },
        },
      };
    };

    const handleBulk = (inState: ConfirmUploadState) => {
      if (!get(unwrap(bulkUploadsEnabledAtom))) {
        return undefined;
      }

      if (!isUploaded(inState.value)) {
        return undefined;
      }

      return {
        type: 'bulk' as const,
        variables: {
          memorySetId,
          fileId: inState.value.file.id,
        },
      };
    };

    const handleText = (inState: AddTextMemoryState) => {
      const trimmedValue = inState.value.trim();

      if (trimmedValue.length === 0) {
        return undefined;
      }

      if (inState.crawl) {
        return {
          type: 'crawl' as const,
          variables: {
            url: trimmedValue,
            memorySetId,
          },
        };
      }

      return {
        type: 'single' as const,
        variables: { value: trimmedValue, memorySetId },
      };
    };

    const handleUrl = (inState: AddUrlMemoryState) => {
      if (!inState.retrievalType) {
        return undefined;
      }

      return {
        type: 'single' as const,
        variables: {
          memorySetId,
          urlMicrobotMemoryInput: {
            retrievalType: inState.retrievalType,
            url: inState.value,
          },
        },
      };
    };

    switch (state?.mode) {
      case 'file':
        return handleFile(state);
      case 'text':
        return handleText(state);
      case 'url':
        return handleUrl(state);
      case 'confirm':
        if (state.uploadType === 'bulk') {
          return handleBulk(state);
        }

        return handleFile(state);
      case undefined:
        return undefined;
      default:
        warnExhaustive(state, 'addMemoryAtom/mutationVariablesForState');
        return undefined;
    }
  });
}

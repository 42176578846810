import { atom } from 'jotai';
import { molecule, use } from 'bunshi';

import { actionAtom } from '@advisor/utils/atoms';
import { Feature, featureEnabledAtoms } from '@advisor/api/feature';

import { AddMemoryState, ErrorMessageKey, EditMemoryState } from './types';
import { AddMicrobotMemoryScope, initialEditMemoryState } from './utils';
import {
  createAdvanceAtom,
  createModalStepAtom,
  createCrawlingAtoms,
  createCanAdvanceAtom,
  createOpenEditorAtom,
  createAttachmentAtoms,
  createTextContentAtom,
  createRetrievalTypeAtom,
  createOpenEditMemoryAtom,
  createCanRequestCrawlingAtom,
} from './moleculeAtoms';

const AddMicrobotMemoryMolecule = molecule(() => {
  if (use(AddMicrobotMemoryScope) === 'invalid') {
    throw new Error('Please provide an AddMicrobotMemoryScope upstream.');
  }

  /* Basic data harvesting permission atoms */
  const canRequestCrawlingAtom = createCanRequestCrawlingAtom();
  const bulkUploadsEnabledAtom = featureEnabledAtoms(Feature.BulkUpload);

  /* Internal State Atoms */
  const editorStateAtom = atom<AddMemoryState>(null);
  const modalStepAtom = createModalStepAtom(editorStateAtom);
  const editMemoryStateAtom = atom<EditMemoryState>(initialEditMemoryState);
  const errorMessageKeyAtom = atom<ErrorMessageKey>(null);

  const canAdvanceAtom = createCanAdvanceAtom(editorStateAtom);
  const retrievalTypeAtom = createRetrievalTypeAtom(editorStateAtom);

  const openEditorAtom = createOpenEditorAtom(
    editorStateAtom,
    editMemoryStateAtom,
    errorMessageKeyAtom,
  );

  const { attachmentAtom, removeFileAtom, canUploadFilesAtom } =
    createAttachmentAtoms(editorStateAtom, editMemoryStateAtom);

  const { crawlingEnabledAtom, toggleCrawlingAtom } =
    createCrawlingAtoms(editorStateAtom);

  const closeEditorAtom = actionAtom(({ set }) => {
    set(removeFileAtom); // not hanging on removing the file
    set(editMemoryStateAtom, initialEditMemoryState);
    set(editorStateAtom, null);
  });

  const openEditMemoryAtom = createOpenEditMemoryAtom(
    editorStateAtom,
    editMemoryStateAtom,
    errorMessageKeyAtom,
  );

  const textContentAtom = createTextContentAtom(
    editorStateAtom,
    errorMessageKeyAtom,
  );

  const advanceAtom = createAdvanceAtom(
    editorStateAtom,
    editMemoryStateAtom,
    errorMessageKeyAtom,
  );

  return {
    modalStepAtom,
    canAdvanceAtom,
    canUploadFilesAtom,
    canBulkUploadAtom: bulkUploadsEnabledAtom,
    canRequestCrawlingAtom,

    textContentAtom,
    retrievalTypeAtom,
    attachmentAtom,
    errorMessageKeyAtom,

    openEditorAtom,
    closeEditorAtom,
    openEditMemoryAtom,
    advanceAtom,

    crawlingEnabledAtom,
    toggleCrawlingAtom,

    editMemoryStateAtom: atom((get) => get(editMemoryStateAtom)), // read-only
  };
});

export default AddMicrobotMemoryMolecule;

import { actionAtom } from '@advisor/utils/atoms';

import { initialEditMemoryState } from '../utils';
import {
  EditorStateAtom,
  ErrorMessageKeyAtom,
  EditMemoryStateAtom,
} from './types';

export default function createOpenEditorAtom(
  editorStateAtom: EditorStateAtom,
  editMemoryStateAtom: EditMemoryStateAtom,
  errorMessageKeyAtom: ErrorMessageKeyAtom,
) {
  return actionAtom(function openEditorAtomSetter({ get, set }) {
    if (get(editorStateAtom) !== null) {
      return;
    }

    set(errorMessageKeyAtom, null);
    set(editMemoryStateAtom, initialEditMemoryState);
    set(editorStateAtom, { mode: 'text', value: '' });
  });
}

import { produce } from 'immer';
import { useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';
import { NetworkStatus } from '@apollo/client';

import {
  PageInfo,
  MemoryBankEventType,
  MicrobotMemoriesQuery,
  MemoryBankEventDocument,
  useMicrobotMemoriesQuery,
  MemoryBankEventInfoFragment,
  MicrobotMemoryEdgeInfoFragment,
} from '@advisor/api/generated/graphql';
import { useMyId } from '@advisor/api/me';
import useMoreSubscription from '@advisor/utils/hooks/useMoreSubscription';
import { useDebounced } from '@advisor/utils/hooks';
import searchQueryAtom from '../atoms/searchQueryAtom';

const useMicrobotMemories = (memorySetId?: string) => {
  const searchQuery = useAtomValue(searchQueryAtom);
  const debouncedSearchQuery = useDebounced(searchQuery, 200);
  const myId = useMyId();

  const {
    data,
    error,
    loading,
    refetch,
    fetchMore,
    networkStatus,
    subscribeToMore,
  } = useMicrobotMemoriesQuery({
    variables: { searchQuery: debouncedSearchQuery, memorySetId },
    fetchPolicy: 'cache-and-network',
  });

  const { pageInfo } = data?.microbotMemories ?? {};

  const microbotMemories = useMemo(
    () => (data?.microbotMemories.edges ?? []).map(({ node }) => node),
    [data?.microbotMemories.edges],
  );

  const loadMore = useCallback(() => {
    if (
      !loading &&
      networkStatus !== NetworkStatus.fetchMore &&
      pageInfo?.hasNextPage &&
      pageInfo.endCursor
    ) {
      fetchMore({
        variables: {
          after: pageInfo.endCursor,
          searchQuery: debouncedSearchQuery,
          memorySetId,
        },
      });
    }
  }, [
    loading,
    networkStatus,
    pageInfo,
    debouncedSearchQuery,
    fetchMore,
    memorySetId,
  ]);

  useMoreSubscription(
    MemoryBankEventDocument,
    subscribeToMore,
    (prev, { subscriptionData }) => {
      const { memoryBankEvent } = subscriptionData.data;

      if (!memoryBankEvent?.microbotMemoryEdge?.node?.id || !myId || !prev) {
        return prev;
      }

      return updateMemoryBank(prev, memoryBankEvent);
    },
  );

  return {
    error,
    loading: loading || searchQuery !== debouncedSearchQuery,
    refetch,
    loadMore,
    memories: microbotMemories,
    count: data?.microbotMemories.count,
    hasMore: !!pageInfo?.hasNextPage,
  };
};

export default useMicrobotMemories;

function updatePageInfo(
  pageInfo: PageInfo,
  edges: MicrobotMemoryEdgeInfoFragment[],
): PageInfo {
  if (edges.length === 0) {
    return {
      __typename: 'PageInfo',
      hasNextPage: false,
      hasPreviousPage: false,
      startCursor: null,
      endCursor: null,
    };
  }

  return {
    ...pageInfo,
    startCursor: edges[0].cursor,
    endCursor: edges[edges.length - 1].cursor,
  };
}

function updateMemoryBank(
  query: MicrobotMemoriesQuery,
  event: MemoryBankEventInfoFragment,
): MicrobotMemoriesQuery {
  return produce(query, (draft) => {
    if (event.eventType === MemoryBankEventType.AdvisorMemoriesDeleted) {
      draft.microbotMemories.edges = draft.microbotMemories.edges.filter(
        (edge) => edge.node.author.id !== event.advisorId,
      );

      draft.microbotMemories.count -= event.memoriesCount;
      draft.microbotMemories.pageInfo = updatePageInfo(
        draft.microbotMemories.pageInfo,
        draft.microbotMemories.edges,
      );
    }

    if (event.eventType === MemoryBankEventType.MemoryDeleted) {
      draft.microbotMemories.edges = draft.microbotMemories.edges.filter(
        (edge) => edge.node.id !== event.microbotMemoryEdge?.node?.id,
      );

      draft.microbotMemories.count -= 1;
      draft.microbotMemories.pageInfo = updatePageInfo(
        draft.microbotMemories.pageInfo,
        draft.microbotMemories.edges,
      );
    }

    if (event.eventType === MemoryBankEventType.MemoryUpdated) {
      draft.microbotMemories.edges = draft.microbotMemories.edges.map(
        (edge) => {
          if (edge.node.id === event.microbotMemoryEdge?.node?.id) {
            return event.microbotMemoryEdge;
          }

          return edge;
        },
      );
      draft.microbotMemories.pageInfo = updatePageInfo(
        draft.microbotMemories.pageInfo,
        draft.microbotMemories.edges,
      );
    }

    if (event.eventType === MemoryBankEventType.MemoryCreated) {
      const memoryExists = draft.microbotMemories.edges.some(
        (edge) => edge.node.id === event.microbotMemoryEdge?.node.id,
      );

      if (!event.microbotMemoryEdge || memoryExists) {
        return;
      }

      draft.microbotMemories.edges.unshift(event.microbotMemoryEdge);
      draft.microbotMemories.count += 1;
      draft.microbotMemories.pageInfo = updatePageInfo(
        draft.microbotMemories.pageInfo,
        draft.microbotMemories.edges,
      );
    }
  });
}

import { atom } from 'jotai';

import { actionAtom } from '@advisor/utils/atoms';
import { EditorStateAtom } from './types';

export default function createCrawlingAtoms(editorStateAtom: EditorStateAtom) {
  const crawlingEnabledAtom = atom((get) => {
    const editorState = get(editorStateAtom);

    if (editorState?.mode !== 'text') {
      return false;
    }

    return editorState.crawl ?? false;
  });

  const toggleCrawlingAtom = actionAtom(function toggleCrawlingAtomSetter({
    get,
    set,
  }) {
    const editorState = get(editorStateAtom);

    if (editorState?.mode !== 'text') {
      return;
    }

    set(editorStateAtom, {
      ...editorState,
      crawl: !editorState.crawl,
    });
  });

  return {
    crawlingEnabledAtom,
    toggleCrawlingAtom,
  };
}

import { useAtomValue } from 'jotai';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Layout, DeprecatedButton } from '@advisor/design/components';
import {
  Form,
  Title,
  Subtitle,
  AlertText,
  ConfirmButton,
} from '@advisor/design/components/LoginComponents';
import { FlowType, LoginType, loginAtom } from '@advisor/api/auth';
import { useInvitationDetails } from '@advisor/onboarding';
import CountDownTimer from './CountDownTimer';
import VerificationInput from './VerificationInput';
import useVerificationForm, {
  VERIFICATION_CODE_LENGTH,
} from './useVerificationForm';
import { VerificationFormState, VerificationFormProps } from './types';

function VerificationForm(props: VerificationFormProps) {
  const {
    verificationType,
    identity,
    onGoBack,
    onVerify,
    onResendOTP,
    onChangeVerificationType,
  } = props;
  const { state, onSubmitCode, ...inputProps } = useVerificationForm(onVerify);
  const login = useAtomValue(loginAtom);
  const { t } = useTranslation(['common', 'onboarding']);

  const invitationDetails = useInvitationDetails();

  const getAlertOrSpacer = (verificationState: VerificationFormState) => {
    if (verificationState === VerificationFormState.Failed) {
      return (
        <AlertText>{t('onboarding:wrong-credentials-try-again')}</AlertText>
      );
    }

    if (verificationState === VerificationFormState.IncorrectCharacters) {
      return (
        <AlertText>
          {t('onboarding:verification-code-should-consist-of-digits')}
        </AlertText>
      );
    }

    if (verificationState === VerificationFormState.IncorrectLength) {
      return (
        <AlertText>
          {t('onboarding:verification-code-too-short', {
            numDigits: VERIFICATION_CODE_LENGTH,
          })}
        </AlertText>
      );
    }

    if (login.flowType === FlowType.Delete) {
      return (
        <AlertText withIcon>
          {t('onboarding:delete-account-permanently-erase-data')}
        </AlertText>
      );
    }

    return <Layout.Spacer.Vertical size="small02" />;
  };

  return (
    <Form onSubmit={onSubmitCode}>
      <Title>{t('onboarding:enter-the-verification-code-sent-to')}</Title>
      <Subtitle>{identity}</Subtitle>
      {!invitationDetails?.email && !invitationDetails?.phoneNumber && (
        <DeprecatedButton.Text underline onPress={onGoBack}>
          {t(
            verificationType === LoginType.PhoneNumber
              ? 'common:change-phone-number'
              : 'common:change-email',
          )}
        </DeprecatedButton.Text>
      )}
      <Layout.Spacer.Vertical size="small02" />
      <VerificationInput {...inputProps} />
      <Layout.Spacer.Vertical size="small02" />
      <ConfirmButton disabled={state !== VerificationFormState.EnableSubmit}>
        {t('onboarding:verify')}
      </ConfirmButton>
      {getAlertOrSpacer(state)}
      <CountDownTimer
        onPress={onResendOTP}
        verificationType={verificationType}
        onChangeVerificationType={onChangeVerificationType}
      />
    </Form>
  );
}

export default memo(VerificationForm);

import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Transition } from '@headlessui/react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import Env from '@advisor/api/env';
import { useMe } from '@advisor/api/me';
import Sentry from '@advisor/utils/Sentry';
import { useEvent } from '@advisor/utils/hooks';
import { Layout, Loader } from '@advisor/design/components';
import {
  Form,
  Title,
  ConfirmButton,
  FormContainer,
} from '@advisor/design/components/LoginComponents';
import { useInvitation } from '@advisor/onboarding';
import { useIsAuthResolved } from '@advisor/api/auth/isAuthResolved';
import LoginLayout from 'src/components/layouts/LoginLayout';
import useQueryParams from 'src/hooks/useQueryParams';
import { InvitationPageParams } from './params';

const InvitationPage = () => {
  const queryParams = useQueryParams<{ 'skip-intro': boolean | undefined }>();

  const { inviteId, inviteType } = useParams<InvitationPageParams>();

  // TODO: Investigate if still needed, as `useMe` should suspend until auth is resolved.
  const isAuthResolved = useIsAuthResolved();
  const { setInvitation } = useInvitation();
  const { t } = useTranslation(['common', 'onboarding']);
  const navigate = useNavigate();
  const me = useMe();

  const isLoggedIn = !!me?.id;

  const onSubmit = useEvent(async () => {
    if (!inviteId || !inviteType) {
      Sentry.captureException('Unspecified invitation params');
      return;
    }

    await setInvitation({
      inviteId,
      inviteType,
    });

    navigate('/login');
  });

  useEffect(() => {
    if (!inviteId || !inviteType || !isAuthResolved) {
      return;
    }

    (async () => {
      await setInvitation({
        inviteId,
        inviteType,
      });

      if (isLoggedIn) {
        navigate('/chat');
      } else if (queryParams['skip-intro']) {
        navigate('/login');
      }
    })();
  }, [
    inviteId,
    inviteType,
    queryParams,
    navigate,
    isLoggedIn,
    isAuthResolved,
    setInvitation,
  ]);

  return (
    <LoginLayout>
      <FormContainer className="flex flex-col">
        <Helmet>
          <title>{Env.web.invitationPageTitle}</title>
          <meta
            name="description"
            content={t('onboarding:please-join-via-invitation-link')}
          />
        </Helmet>
        {!isAuthResolved && <Loader className="mx-auto" />}

        {/* Show form only when user is not logged in */}
        <Transition show={isAuthResolved} {...transitionProps}>
          <div className="flex flex-row gap-5 mb-6">
            <h2 className="text-lg md:text-base font-semibold font-sora uppercase">
              {t('onboarding:sign-in-join')}
            </h2>
          </div>
          <Form onSubmit={onSubmit}>
            <div>
              <Title>{t('common:hello-exclamation')}</Title>
              <p>
                <Trans
                  t={t}
                  i18nKey={
                    inviteType && inviteType === 'student'
                      ? 'onboarding:you-have-been-invited-to-platform-customer-invite'
                      : 'onboarding:you-have-been-invited-to-platform-host-invite'
                  }
                  components={{ bold: <b /> }}
                />
              </p>
              <Layout.Spacer.Vertical size="tiny" />
              <p>{t('common:do-you-want-to-proceed')}</p>
              <ConfirmButton>
                {t('onboarding:proceed-to-sign-in-join')}
              </ConfirmButton>
            </div>
          </Form>
        </Transition>
      </FormContainer>
    </LoginLayout>
  );
};

export default InvitationPage;

const transitionProps = {
  enter: 'transition-opacity ease-in duration-100 delay-100',
  enterFrom: 'opacity-0',
  enterTo: 'opacity-100',
  leave: 'transition-opacity ease-in duration-100 absolute',
  leaveFrom: 'opacity-100',
  leaveTo: 'opacity-0',
};

import { useAgenciesQuery } from '@advisor/api/generated/graphql';

export default function useSubscribersList() {
  const { data, loading, error, refetch } = useAgenciesQuery({
    fetchPolicy: 'cache-and-network',
  });

  const agencies = data?.agencies ?? [];

  return {
    isLoading: loading,
    hasError: !!error,
    agencies,

    refetch,
  };
}

import { kebabCase } from 'lodash-es';
import { useMemo, useState } from 'react';

import { useEvent } from '@advisor/utils/hooks';
import isPromise from '@advisor/utils/isPromise';
import { Theme, ColorPalette } from '../Theme';
import { Colors } from '../styles';
import type {
  Color,
  StaticColor,
  ButtonProps,
  ButtonCallback,
  ButtonChildren,
  ButtonRenderProps,
  ButtonPropsWithDefaults,
  IconButtonProps,
  IconButtonPropsWithDefaults,
} from './types';

/* eslint-disable import/prefer-default-export */

interface UseISLoadingOptions {
  onPress?: ButtonCallback;
  loading?: boolean;
}

export function useIsLoading(options: UseISLoadingOptions) {
  const [isLoading, setIsLoading] = useState(false);

  const onPress = useEvent(async () => {
    const returnValue = options.onPress?.();

    if (!isPromise(returnValue)) {
      return;
    }

    setIsLoading(true);

    try {
      await returnValue;
    } finally {
      setIsLoading(false);
    }
  });

  return {
    isLoading: isLoading || options.loading || false,
    onPress: options.onPress ? onPress : undefined,
  };
}

export function renderChildren(
  children: ButtonChildren,
  renderProps: ButtonRenderProps,
) {
  if (typeof children === 'function') {
    return children(renderProps);
  }

  return children;
}

export function useStableButtonProps(
  props: ButtonProps,
): ButtonPropsWithDefaults {
  const {
    type,
    size,
    label,
    color,
    testID,
    endIcon,
    variant,
    loading,
    rounded,
    children,
    disabled,
    startIcon,
  } = props;

  return useMemo(
    () => ({
      variant: variant ?? 'contained',
      rounded: rounded ?? 'default',
      disabled: disabled ?? false,
      loading: loading ?? false,
      color: color ?? 'primary',
      type: type ?? 'button',
      size: size ?? 'medium',
      startIcon,
      children,
      endIcon,
      testID,
      label,
    }),
    [
      type,
      size,
      label,
      color,
      testID,
      endIcon,
      variant,
      loading,
      rounded,
      children,
      disabled,
      startIcon,
    ],
  );
}

export function useStableIconButtonProps(
  props: IconButtonProps,
): IconButtonPropsWithDefaults {
  const {
    type,
    size,
    name,
    color,
    testID,
    onPress,
    variant,
    rounded,
    loading,
    disabled,
  } = props;

  return useMemo(
    () => ({
      rounded: rounded ?? 'none',
      disabled: disabled ?? false,
      loading: loading ?? false,
      variant: variant ?? 'bare',
      color: color ?? 'primary',
      type: type ?? 'button',
      size: size ?? 'medium',
      onPress,
      testID,
      name,
    }),
    [
      type,
      size,
      name,
      color,
      testID,
      onPress,
      variant,
      rounded,
      loading,
      disabled,
    ],
  );
}

export function getColorValue(theme: Theme, color: Color) {
  if (theme.colors[color as keyof ColorPalette]) {
    return theme.colors[color as keyof ColorPalette];
  }

  return Colors[color as StaticColor];
}

const whiteForegroundColors = [
  'cyan',
  'purple',
  'primary',
  'negative',
  'positive',
  'purpleDark',
  'primaryDark',
  'darkGrey01',
  'darkGrey02',
  'secondary',
  'secondaryDark',
];

const primaryForegroundColors = ['white'];

export function getContrastValue(theme: Theme, color: Color) {
  if (primaryForegroundColors.includes(color)) {
    return theme.colors.primary;
  }

  return whiteForegroundColors.includes(color)
    ? Colors.white
    : Colors.darkGrey03;
}

export function toTWColor(color: Color) {
  return kebabCase(color);
}

export function getTWContrast(color: Color) {
  if (primaryForegroundColors.includes(color)) {
    return 'text-primary font-semibold';
  }

  return whiteForegroundColors.includes(color)
    ? 'text-white'
    : 'text-dark-grey-03';
}

import cs from 'classnames';
import React, { memo, useMemo } from 'react';

import { warnExhaustive } from '@advisor/utils/typeAssertions';
import Icon, { SpinnerIcon } from '../components/Icon';
import Layout from '../components/Layout';
import {
  Size,
  Color,
  Variant,
  Rounded,
  ButtonProps,
  ButtonRenderProps,
} from './types';
import {
  toTWColor,
  useIsLoading,
  getTWContrast,
  renderChildren,
  useStableButtonProps,
} from './utils';

/* eslint-disable react/button-has-type */

const Button: React.FC<ButtonProps> = (props) => {
  const { isLoading, onPress } = useIsLoading(props);
  const {
    // Style and appearance
    size,
    type,
    color,
    variant,
    rounded,

    // Appendices
    endIcon,
    startIcon,

    // Base content
    children,
    label,

    // Miscellaneous
    testID,

    // State controls
    disabled,
  } = useStableButtonProps(props);

  const renderProps = useMemo<ButtonRenderProps>(
    () => ({
      pressed: false, // only used on mobile
      loading: isLoading,
      disabled: !!disabled,
      Label: 'span',
    }),
    [isLoading, disabled],
  );

  const className = useMemo(() => {
    return cs(
      'flex flex-row items-center relative font-outfit',
      disabled && 'opacity-40',
      withSizeClassNames(variant, size, rounded),
      withVariantClassNames(variant, color, disabled),
    );
  }, [variant, size, rounded, disabled, color]);

  const iconSize = useMemo(() => withIconSize(size), [size]);
  const opacityTransition = useMemo(
    () => withOpacityTransition(isLoading),
    [isLoading],
  );

  return (
    <button
      type={type}
      data-cy={testID}
      onClick={onPress}
      className={className}
      disabled={disabled || isLoading}
    >
      {children ? (
        renderChildren(children, renderProps)
      ) : (
        <>
          {isLoading && (
            <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center">
              <SpinnerIcon className={iconSize} />
            </div>
          )}
          {!!startIcon && (
            <>
              <Icon
                name={startIcon}
                className={cs(iconSize, opacityTransition)}
              />
              <Layout.Spacer.Horizontal size="atomic" />
            </>
          )}
          {!!label && <span className={opacityTransition}>{label}</span>}
          {!!endIcon && (
            <>
              <Layout.Spacer.Horizontal size="atomic" />
              <Icon
                name={endIcon}
                className={cs(iconSize, opacityTransition)}
              />
            </>
          )}
        </>
      )}
    </button>
  );
};

export default memo(Button);

function withSizeClassNames(
  variant: Variant,
  size: Size,
  rounded: Rounded,
): string {
  if (variant === 'link') {
    return {
      small: 'text-xs',
      medium: 'text-sm',
      large: 'text-base',
      flex: 'text-sm',
    }[size];
  }

  const cornerRadius = {
    small: 'md',
    medium: 'lg',
    large: 'lg',
    flex: 'lg',
  }[size];

  const roundedClass = {
    none: '',
    default: `rounded-${cornerRadius}`,
    full: 'rounded-full',
  }[rounded];

  return {
    small: `py-1 px-2 text-xs ${roundedClass}`,
    medium: `py-2 px-3 text-sm ${roundedClass}`,
    large: `py-3 px-4 text-base ${roundedClass}`,
    flex: `py-2 px-3 justify-center w-full text-sm ${roundedClass}`,
  }[size];
}

function withVariantClassNames(
  variant: Variant,
  color: Color,
  disabled: boolean,
): string {
  const colorName = toTWColor(color);
  const contrastName = getTWContrast(color);

  if (variant === 'contained') {
    return cs(
      `bg-${colorName} ${contrastName}`,
      !disabled &&
        'before:content-[""] before:absolute before:inset-0 before:bg-black before:opacity-0 before:rounded-lg before:transition-all hover:before:opacity-5',
    );
  }

  if (variant === 'outlined') {
    return cs(
      'border bg-white transition-all',
      `text-${colorName}`,
      `border-${colorName}`,
      !disabled && `hover:bg-${colorName} hover:${contrastName}`,
    );
  }

  if (variant === 'link') {
    return `underline text-${colorName}`;
  }

  if (variant === 'text') {
    return `text-${colorName}`;
  }

  warnExhaustive(variant, '@advisor/design/Button/Button.tsx');
  return '';
}

function withIconSize(size: Size): string {
  return {
    small: 'w-3.5 h-3.5',
    medium: 'w-5 h-5',
    large: 'w-7 h-7',
    flex: 'w-5 h-5',
  }[size];
}

function withOpacityTransition(isLoading: boolean): string {
  return cs('transition-all', isLoading && 'opacity-0');
}

/*
Tailwind clases that are dynamically produced by this component.
This comment is included in order to enforce tailwind parser to generate them.

  bg-primary
  bg-primary-light
  bg-primary-light-05
  bg-primary-dark
  bg-secondary
  bg-secondary-light
  bg-secondary-dark

  bg-positive
  bg-negative
  bg-dark-grey-01
  bg-dark-grey-02
  bg-cyan
  bg-purple
  bg-purple-dark

  text-primary
  text-primary-light
  text-primary-light-05
  text-primary-dark
  text-secondary
  text-secondary-light
  text-secondary-dark

  text-positive
  text-negative
  text-dark-grey-01
  text-dark-grey-02
  text-cyan
  text-purple
  text-purple-dark

  text-white
  text-dark-grey-03

  border-primary
  border-primary-light
  border-primary-light-05
  border-primary-dark
  border-secondary
  border-secondary-light
  border-secondary-dark

  border-positive
  border-negative
  border-dark-grey-01
  border-dark-grey-02
  border-cyan
  border-purple
  border-purple-dark

  hover:bg-primary
  hover:bg-primary-light
  hover:bg-primary-light-05
  hover:bg-primary-dark
  hover:bg-secondary
  hover:bg-secondary-light
  hover:bg-secondary-dark

  hover:bg-positive
  hover:bg-negative
  hover:bg-dark-grey-01
  hover:bg-dark-grey-02
  hover:bg-cyan
  hover:bg-purple
  hover:bg-purple-dark

  hover:text-white
  hover:text-dark-grey-03
 */

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Form,
  Modal,
  Layout,
  DeprecatedButton,
} from '@advisor/design/components';

import useMemorySetActionModal from './useMemorySetActionModal';

const MemorySetActionModal: React.FC = () => {
  const { isOpen, type, inputProps, closeModal, submit } =
    useMemorySetActionModal();
  const { t } = useTranslation(['microbot', 'common']);

  const title = useMemo(() => {
    if (type === 'create') {
      return t('microbot:new-memory-set');
    }

    if (type === 'update') {
      return t('microbot:edit-memory-set');
    }

    return '';
  }, [type, t]);

  return (
    <Modal.Base visible={isOpen} onClose={closeModal}>
      <Modal.Header
        title={title}
        subtitle={t('microbot:for-subscription')}
        onClose={closeModal}
      />
      <div className="w-[406px] py-8 px-6">
        <Form.Text
          placeholder={t('microbot:add-title')}
          label={t('microbot:memory-set-title')}
          required
          {...inputProps.title}
        />
        <Layout.Spacer.Vertical size="micro02" />
        <Form.Text
          placeholder={t('microbot:add-description')}
          label={t('microbot:memory-set-description')}
          {...inputProps.description}
        />
        <Layout.Spacer.Vertical size="tiny" />
        <p className="text-xs text-dark-grey-02">
          {t(
            'microbot:this-information-will-be-visible-to-subscribing-organisations',
          )}
        </p>
      </div>
      <Modal.Footer>
        <DeprecatedButton.Large
          onPress={submit}
          variant="primary"
          label={t('common:save')}
        />
      </Modal.Footer>
    </Modal.Base>
  );
};

export default MemorySetActionModal;

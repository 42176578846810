import { useAtom } from 'jotai';

import {
  MemorySetStatus,
  useCreateMemorySetMutation,
  useUpdateMemorySetMutation,
} from '@advisor/api/generated/graphql';
import { showToast } from '@advisor/design/components/Toast';

import { memorySetActionStateAtom } from './atom';

export default function useMemorySetActionModal() {
  const [memorySetActionState, setMemorySetActionState] = useAtom(
    memorySetActionStateAtom,
  );

  const [createMemorySet] = useCreateMemorySetMutation();
  const [updateMemorySet] = useUpdateMemorySetMutation();

  const createNewMemorySet = () => {
    setMemorySetActionState({
      type: 'create',
      title: '',
      description: '',
      status: MemorySetStatus.Draft,
    });
  };

  const closeModal = () => {
    setMemorySetActionState(null);
  };

  const onChangeTitle = (value: string) => {
    setMemorySetActionState((prev) => {
      if (!prev) {
        return null;
      }

      return {
        ...prev,
        title: value,
      };
    });
  };

  const onChangeDescription = (value: string) => {
    setMemorySetActionState((prev) => {
      if (!prev) {
        return null;
      }

      return {
        ...prev,
        description: value,
      };
    });
  };

  const submit = async () => {
    if (!memorySetActionState) {
      return;
    }

    if (memorySetActionState.type === 'create') {
      await createMemorySet({
        variables: {
          title: memorySetActionState.title,
          description: memorySetActionState.description,
          status: memorySetActionState.status,
        },
      });

      setMemorySetActionState(null);

      // Delay the toast to allow the modal to close first
      setTimeout(() => {
        showToast({
          iconName: 'CircleCheck',
          variant: 'blue',
          namespace: 'microbot',
          messageI18Key: 'new-memory-set-was-added',
        });
      }, 100);
    }

    if (memorySetActionState.type === 'update') {
      await updateMemorySet({
        variables: {
          id: memorySetActionState.memorySetId,
          title: memorySetActionState.title,
          description: memorySetActionState.description,
          status: memorySetActionState.status,
          subscribedAgencies: memorySetActionState.subscribedAgencies,
        },
      });

      setMemorySetActionState(null);

      // Delay the toast to allow the modal to close first
      setTimeout(() => {
        showToast({
          iconName: 'CircleCheck',
          variant: 'blue',
          namespace: 'microbot',
          messageI18Key: 'selected-memory-set-was-edited',
        });
      }, 100);
    }
  };

  return {
    isOpen: !!memorySetActionState,
    type: memorySetActionState?.type,

    inputProps: {
      title: {
        value: memorySetActionState?.title ?? '',
        onChange: onChangeTitle,
      },
      description: {
        value: memorySetActionState?.description ?? '',
        onChange: onChangeDescription,
      },
    },

    createNewMemorySet,
    closeModal,
    submit,
  };
}

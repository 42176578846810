import React, { memo } from 'react';

import { useTheme } from '../../Theme';
import { CircularProgressBarProps } from './types';

const CircularProgressBar: React.FC<CircularProgressBarProps> = (props) => {
  const {
    color,
    progress,
    children,
    size = 40,
    barWidth = 6,
    backgroundColor,
  } = props;
  const theme = useTheme();

  return (
    <div className="relative">
      <svg
        className="scale-105"
        width={size}
        height={size}
        viewBox="0 0 100 100"
      >
        <circle
          r={44}
          cx={50}
          cy={50}
          fill="transparent"
          strokeDasharray={296}
          strokeDashoffset={0}
          strokeWidth={barWidth ?? 6}
          stroke={backgroundColor ?? theme.colors.primaryLight}
        />
        <circle
          r={44}
          cx={50}
          cy={50}
          fill="transparent"
          strokeDasharray={296}
          strokeDashoffset={(1 - progress) * 296}
          strokeWidth={barWidth ?? 6}
          transform="rotate(-90 50 50)"
          strokeLinecap="round"
          stroke={color ?? theme.colors.primary}
        />
      </svg>
      <div className="absolute inset-0 flex justify-center items-center">
        {children ?? (
          <div
            className="font-outfit text-sm font-normal"
            style={{ color: color ?? theme.colors.primary }}
          >
            {`${Math.floor(progress * 100).toString(10)}%`}
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(CircularProgressBar);

/* eslint-disable import/prefer-default-export */
import {
  MicrobotUrlMemory,
  MicrobotTextMemory,
  MicrobotPersonality,
  MicrobotMemoryInfoFragment,
} from '@advisor/api/generated/graphql';
import { Feature } from '@advisor/api/feature';

export const PersonalityToFeatureKey = {
  [MicrobotPersonality.Carl]: Feature.MicrobotCarl,
  [MicrobotPersonality.Pam]: Feature.MicrobotPam,
  [MicrobotPersonality.Astro]: Feature.MicrobotAstro,
};

export function isBulkUploadSourced(
  memory: MicrobotMemoryInfoFragment,
): memory is MicrobotTextMemory | MicrobotUrlMemory {
  const isCorrectType =
    memory.__typename === 'MicrobotTextMemory' ||
    memory.__typename === 'MicrobotUrlMemory';

  return isCorrectType && !!memory.bulkUploadId;
}

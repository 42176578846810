import { atom } from 'jotai';

import { EditorStateAtom, ErrorMessageKeyAtom } from './types';

export default function createTextContentAtom(
  editorStateAtom: EditorStateAtom,
  errorMessageKeyAtom: ErrorMessageKeyAtom,
) {
  return atom(
    (get) => {
      const state = get(editorStateAtom);
      return state?.mode === 'text' ? state.value : '';
    },
    (get, set, value: string) => {
      if (get(editorStateAtom)?.mode !== 'text') {
        // Not in text input mode
        return;
      }

      // Clearing the error message
      set(errorMessageKeyAtom, null);

      set(editorStateAtom, {
        mode: 'text',
        value,
      });
    },
  );
}

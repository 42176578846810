import { clientAtom, refetchQueriesOrIgnore } from '@advisor/api/apollo';
import {
  MicrobotMemoriesDocument,
  CreateBulkUploadDocument,
  CreateCrawlMemoryDocument,
  CreateMicrobotMemoryDocument,
  UpdateMicrobotMemoryDocument,
  MemoryBankBulkUploadsDocument,
  MicrobotCrawlMemoryInfoFragment,
} from '@advisor/api/generated/graphqlTypes';

import Sentry from '@advisor/utils/Sentry';
import { soloActionAtoms } from '@advisor/utils/atoms';

import { showToast } from '@advisor/design/components/Toast';

import {
  updateBulkUploadsQuery,
  updateCrawlMemoriesQuery,
  updateMemoriesQuery,
} from '../utils';
import createSubmitVariablesAtom from './createSubmitVariablesAtom';
import { EditMemoryStateAtom, EditorStateAtom } from './types';

export default function createSubmitAtom(
  editorStateAtom: EditorStateAtom,
  editMemoryStateAtom: EditMemoryStateAtom,
) {
  const submitVariablesAtom = createSubmitVariablesAtom(editorStateAtom);

  const [, submitAtom] = soloActionAtoms(async ({ get, set }) => {
    const client = await get(clientAtom);
    const variablesState = get(submitVariablesAtom);
    const editMemoryState = get(editMemoryStateAtom);
    const { id: editingMemoryId } = editMemoryState;

    if (!variablesState) {
      return;
    }

    try {
      const { type, variables } = variablesState;

      if (type === 'bulk') {
        await client.mutate({
          mutation: CreateBulkUploadDocument,
          variables,
          update(cache, result) {
            if (result.data?.createBulkUpload) {
              updateBulkUploadsQuery(
                cache,
                result.data.createBulkUpload,
                variables.memorySetId,
              );
            }
          },
        });
      } else if (type === 'crawl') {
        await client.mutate({
          mutation: CreateCrawlMemoryDocument,
          variables,
          update(cache, result) {
            if (result.data?.createCrawlMemory?.node) {
              updateCrawlMemoriesQuery(
                cache,
                result.data.createCrawlMemory
                  .node as MicrobotCrawlMemoryInfoFragment,
                variables.memorySetId,
              );
            }
          },
        });
      } else if (editingMemoryId) {
        await client.mutate({
          mutation: UpdateMicrobotMemoryDocument,
          variables: {
            ...variables,
            microbotMemoryId: editingMemoryId,
          },
          update(cache, result) {
            if (result.data?.updateMicrobotMemory) {
              updateMemoriesQuery(
                cache,
                result.data.updateMicrobotMemory,
                variables.memorySetId,
              );
            }
          },
        });
      } else {
        await client.mutate({
          mutation: CreateMicrobotMemoryDocument,
          variables,
          update(cache, result) {
            if (result.data?.createMicrobotMemory) {
              updateMemoriesQuery(
                cache,
                result.data.createMicrobotMemory,
                variables.memorySetId,
              );
            }
          },
        });
      }

      const messageI18Key = (() => {
        if (type === 'bulk') {
          return null;
        }

        if (editingMemoryId) {
          return 'selected-memory-was-edited';
        }

        return 'new-memory-was-added-to-memory-bank';
      })();

      await refetchQueriesOrIgnore(client, [
        MicrobotMemoriesDocument,
        MemoryBankBulkUploadsDocument,
      ]);

      set(editorStateAtom, null);

      if (messageI18Key) {
        showToast({
          iconName: 'CircleCheck',
          variant: 'blue',
          namespace: 'microbot',
          messageI18Key,
        });
      }
    } catch (error) {
      Sentry.captureException(error);

      set(editorStateAtom, null);

      showToast({
        iconName: 'X',
        variant: 'rose',
        messageI18Key: 'oops-something-went-wrong',
      });
    }
  });

  return submitAtom;
}

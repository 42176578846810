import React, { useState } from 'react';
import cs from 'classnames';

import { isPromise } from '../DeprecatedButton/utils';
import { SegmentedGroupToggleButtonProps } from './types';
import SegmentedGroupButton from './SegmentedGroupButton';

const SegmentedGroupToggleButton: React.FC<SegmentedGroupToggleButtonProps> = (
  props,
) => {
  const { enabled, onToggle, ...buttonProps } = props;
  const [isLoading, setIsLoading] = useState(false);

  const onButtonPress = async () => {
    if (isLoading) {
      return;
    }

    const returnValue = onToggle(!enabled);

    if (isPromise(returnValue)) {
      setIsLoading(true);

      try {
        await returnValue;
      } finally {
        setIsLoading(false);
      }
    }
  };

  const toggleIcon = (
    <div
      className={cs(
        'w-[40px] rounded-full transition-all p-[2px]',
        enabled ? 'bg-primary' : 'bg-dark-grey-01',
        isLoading && 'opacity-10',
      )}
    >
      <div
        className={cs(
          `bg-white w-[18px] h-[18px] rounded-full transition-all`,
          enabled && 'translate-x-[18px]',
        )}
      />
    </div>
  );

  return (
    <SegmentedGroupButton
      {...buttonProps}
      onPress={onButtonPress}
      appendix={toggleIcon}
    />
  );
};

export default SegmentedGroupToggleButton;

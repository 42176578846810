import React from 'react';

import { Layout, Skeleton } from '@advisor/design/components';
import { Spacing } from '@advisor/design/styles';

const MemoryListLoader: React.FC = () => {
  return (
    <div className="flex flex-col gap-3">
      <div className="w-full rounded-xl border border-grey p-4">
        <Skeleton.Text fontSize={14} lineHeight={18} size={Spacing.large70} />
        <Layout.Spacer.Vertical size="quantum" />
        <Skeleton.Text fontSize={12} lineHeight={16} />
        <Layout.Spacer.Vertical size="atomic" />
        <Skeleton.Text fontSize={12} lineHeight={16} />
      </div>
      <div className="w-full rounded-xl border border-grey p-4">
        <Skeleton.Text fontSize={14} lineHeight={18} size={Spacing.large70} />
        <Layout.Spacer.Vertical size="quantum" />
        <Skeleton.Text fontSize={12} lineHeight={16} />
        <Layout.Spacer.Vertical size="atomic" />
        <Skeleton.Text fontSize={12} lineHeight={16} />
      </div>
      <div className="w-full rounded-xl border border-grey p-4">
        <Skeleton.Text fontSize={14} lineHeight={18} size={Spacing.large70} />
        <Layout.Spacer.Vertical size="quantum" />
        <Skeleton.Text fontSize={12} lineHeight={16} />
        <Layout.Spacer.Vertical size="atomic" />
        <Skeleton.Text fontSize={12} lineHeight={16} />
      </div>
    </div>
  );
};

export default MemoryListLoader;

import React, { createContext, PropsWithChildren, useContext } from 'react';

type ProviderProps = PropsWithChildren<{ memorySetId: string }>;

export const ActiveMemorySetContext = createContext<string | null>(null);

export const ActiveMemorySetProvider: React.FC<ProviderProps> = (props) => {
  const { memorySetId, children } = props;

  return (
    <ActiveMemorySetContext.Provider value={memorySetId}>
      {children}
    </ActiveMemorySetContext.Provider>
  );
};

export function useActiveMemorySetId() {
  return useContext(ActiveMemorySetContext);
}

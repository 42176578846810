import React from 'react';
import { useTranslation } from 'react-i18next';

import { Layout } from '@advisor/design/components';
import { useActiveTab } from './atoms';

const AgencyHeader: React.FC = () => {
  const activeTab = useActiveTab();

  return activeTab === 'subscribed' ? (
    <SubscribedTabHeader />
  ) : (
    <AvailableTabHeader />
  );
};

export default AgencyHeader;

const SubscribedTabHeader: React.FC = () => {
  const { t } = useTranslation('microbot');

  return (
    <div className="border border-grey mx-6 p-6 mt-6 flex flex-col items-center rounded-md">
      <h3>{t('subscription-memory-sets')}</h3>
      <Layout.Spacer.Vertical size="micro02" />
      <p className="font-outfit text-sm text-dark-grey-03">
        {t('alphabot-securely-stores-the-details-of-your-subscription')}
      </p>
    </div>
  );
};

const AvailableTabHeader: React.FC = () => {
  const { t } = useTranslation('microbot');

  return (
    <div className="p-6 bg-primary-light border border-light-grey">
      <p className="font-outfit text-sm font-semibold">
        {t('you-are-not-subscribed-to-these-memory-sets')}
      </p>
      <p className="font-outfit text-sm font-normal">
        {t('contact-a-global-study-representative-to-subscribe')}
      </p>
    </div>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import { DeprecatedButton, Form, Modal } from '@advisor/design/components';
import { EditTabProps, ModalTab } from './types';
import SwipeTransition from './SwipeTransition';

function EditMilestoneTab({
  inputProps,
  activeTab,
  canDelete,
  onClose,
  onSubmit,
  onOpenDatePicker,
  onOpenDeleteConsent,
}: EditTabProps) {
  const { t } = useTranslation(['common', 'task-organiser']);

  return (
    <SwipeTransition direction="left" show={activeTab === ModalTab.Root}>
      <div>
        <Modal.Header
          title={t('task-organiser:edit-task')}
          subtitle={t('task-organiser:for-customer-profile')}
          onClose={onClose}
        />
        <div className="py-4">
          <div className="px-8 py-2 flex flex-col">
            <Form.Text
              placeholder={t('task-organiser:add-title')}
              label={t('common:title')}
              {...inputProps.name}
            />
          </div>
          <div className="px-8 py-2 flex flex-col">
            <Form.TextArea
              placeholder={t('task-organiser:add-description')}
              label={t('common:description')}
              height={190}
              {...inputProps.description}
            />
          </div>
          <div className="px-8 py-2 flex flex-col">
            <Form.DateInput
              placeholder="DD/MM/YYYY"
              label={t('common:due-date')}
              onPickerPress={onOpenDatePicker}
              {...inputProps.dueDate}
            />
          </div>
        </div>
        <div className="border border-grey px-8 py-6 rounded bg-white flex flex-row gap-5">
          <DeprecatedButton.Outline
            variant="negative"
            className="flex-1"
            label={t('task-organiser:delete-task')}
            onPress={onOpenDeleteConsent}
            disabled={!canDelete}
          />
          <DeprecatedButton.Large
            label={t('common:save')}
            variant="primary"
            className="flex-1"
            onPress={onSubmit}
          />
        </div>
      </div>
    </SwipeTransition>
  );
}

export default EditMilestoneTab;

import { Route, Routes } from 'react-router';

import HomePage from './routes/HomePage';
import Callback from './routes/Callback';
import ChatPage from './routes/ChatPage';
import LoginPage from './routes/LoginPage';
import InvitationPage from './routes/InvitationPage';
import NotFound404Page from './routes/NotFound404Page';
import EditProfilePage from './routes/EditProfilePage';
import JoinAdvisorPage from './routes/JoinAdvisorPage';
import CalendarCallback from './routes/CalendarCallback';
import MicrobotInfoPage from './routes/MicrobotInfoPage';
import CreateProfilePage from './routes/CreateProfilePage';
import DeleteAccountPage from './routes/DeleteAccountPage';
import VideoCallPage from './routes/VideoCallPage/VideoCallPage';
import CommunityPage from './routes/CommunityPage/CommunityPage';
import { PrivacyPolicyPage, TermsPage } from './routes/PrivacyAndTerms';
import DeleteAccountSuccessPage from './routes/DeleteAccountSuccessPage';

import RequireLoggedIn from './components/atoms/RequireLoggedIn';
import MemoryBankModal from './components/organisms/modals/MemoryBankModal';
import ChatMembersModal from './components/organisms/modals/ChatMembersModal';
import StudentInfoModal from './components/organisms/modals/StudentInfoModal';
import AdvisorInfoModal from './components/organisms/modals/AdvisorInfoModal';
import PreferencesModal from './components/organisms/modals/PreferencesModal';
import MessageInfoModal from './components/organisms/modals/MessageInfoModal';
import StudentJourneyModal from './components/organisms/modals/StudentJourneyModal';
import StudentProfileModal from './components/organisms/modals/StudentProfileModal';
import EditStudentProfileModal from './components/organisms/modals/EditStudentProfileModal';
import JourneyCategoryModal from './components/organisms/modals/JourneyCategoryModal';
import JourneyMilestoneModal from './components/organisms/modals/JourneyMilestoneModal';
import VideoRoomHistoryModal from './components/organisms/modals/VideoRoomHistoryModal';
import VideoRoomDetailsModal from './components/organisms/modals/VideoRoomDetailsModal';
import ConfigureMicrobotModal from './components/organisms/modals/ConfigureMicrobotModal';
import ChangeLanguageModal from './components/organisms/modals/PreferencesModal/ChangeLanguageModal';
import PrivateNotesModal from './components/organisms/modals/PrivateNotesModal/PrivateNotesModal';
import EditNoteModal from './components/organisms/modals/PrivateNotesModal/EditNoteModal';
import {
  MemorySetsModal,
  MemorySetDetailsModal,
  MemorySetsSubscriberModal,
  MemorySetsSubscribersModal,
} from './components/organisms/modals/MemorySets';

import useAppUpdate from './hooks/useAppUpdate';

function MainRoutes() {
  useAppUpdate();

  return (
    <>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/delete-account" element={<DeleteAccountPage />} />
        <Route
          path="/delete-account-success"
          element={<DeleteAccountSuccessPage />}
        />
        <Route path="/callback" element={<Callback />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/terms" element={<TermsPage />} />
        <Route
          path="/community/microbot/carl/configure/calendar"
          element={<CalendarCallback />}
        />
        <Route
          path="/create-profile"
          element={
            <RequireLoggedIn redirectTo="/login">
              <CreateProfilePage />
            </RequireLoggedIn>
          }
        />
        <Route
          path="/chat"
          element={
            <RequireLoggedIn redirectTo="/login">
              <ChatPage.Page />
            </RequireLoggedIn>
          }
        >
          <Route path=":chatId" element={<ChatPage.SideModal />}>
            <Route path="user/:userId" element={<StudentInfoModal />} />
            <Route path="advisor/:advisorId" element={<AdvisorInfoModal />} />
            <Route path="preferences" element={<PreferencesModal />} />
            <Route path="seen-at/:sentAt" element={<MessageInfoModal />} />
            <Route path="preferences/notes" element={<PrivateNotesModal />} />
            <Route
              path="preferences/notes/:notePath"
              element={<EditNoteModal />}
            />
            <Route
              path="preferences/video-room-history"
              element={<VideoRoomHistoryModal />}
            >
              <Route path=":count" element={<VideoRoomDetailsModal />} />
            </Route>
            <Route
              path="preferences/language"
              element={<ChangeLanguageModal />}
            />
            <Route path="preferences/journey" element={<StudentJourneyModal />}>
              <Route path="student-profile" element={<StudentProfileModal />}>
                <Route path="edit" element={<EditStudentProfileModal />} />
              </Route>
              <Route path=":categoryId" element={<JourneyCategoryModal />}>
                <Route
                  path=":milestoneId"
                  element={<JourneyMilestoneModal />}
                />
              </Route>
            </Route>
            <Route
              path="preferences/memory-bank"
              element={<MemoryBankModal />}
            />
            <Route
              path="preferences/memory-sets/subscribers/:agencyName"
              element={<MemorySetsSubscriberModal />}
            />
            <Route
              path="preferences/memory-sets/subscribers"
              element={<MemorySetsSubscribersModal />}
            />
            <Route
              path="preferences/memory-sets/:memorySetId"
              element={<MemorySetDetailsModal />}
            />
            <Route
              path="preferences/memory-sets"
              element={<MemorySetsModal />}
            />
            <Route path="preferences/members" element={<ChatMembersModal />} />
          </Route>
        </Route>
        <Route
          path="/video-call/:chatId"
          element={
            <RequireLoggedIn redirectTo="/login">
              <VideoCallPage />
            </RequireLoggedIn>
          }
        />
        <Route
          path="/edit-profile"
          element={
            <RequireLoggedIn redirectTo="/login">
              <EditProfilePage />
            </RequireLoggedIn>
          }
        />
        <Route path="/join/:advisorId" element={<JoinAdvisorPage />} />
        <Route
          path="/invite/:inviteType/:inviteId"
          element={<InvitationPage />}
        />
        <Route
          path="/community"
          element={
            <RequireLoggedIn redirectTo="/login">
              <CommunityPage />
            </RequireLoggedIn>
          }
        />
        <Route
          path="/community/microbot/:microbotName"
          element={
            <RequireLoggedIn redirectTo="/login">
              <MicrobotInfoPage />
            </RequireLoggedIn>
          }
        >
          <Route path="configure" element={<ConfigureMicrobotModal />} />
        </Route>
        <Route path="*" element={<NotFound404Page />} />
      </Routes>
    </>
  );
}

export default MainRoutes;
